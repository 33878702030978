import React, { ReactNode } from 'react';
import styles from './InputLabel.module.css';

interface InputLabelInterface extends React.PropsWithChildren {
  label?: string | ReactNode;
  className?: string;
}

function InputLabel({ children, label, className }: InputLabelInterface) {
  return (
    <div className={[styles.label, className].join(' ')}>
      <span className={styles.title}>{label}</span>
      {children}
    </div>
  );
}

export default InputLabel;
