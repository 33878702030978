import styles from './EmptyFormField.module.css';

interface EmptyFormFieldProps extends React.PropsWithChildren, React.AllHTMLAttributes<HTMLDivElement> {

}

function EmptyFormField({ children, ...restProps }: EmptyFormFieldProps) {
  return (
    <div className={styles.flexItem} {...restProps}>
      {children}
    </div>
  );
}

export default EmptyFormField;
