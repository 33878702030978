import { SubscriptionView } from '@/integration-api/server-rest-lundapadelApi';

type Actions = 'update';

type Payload = {
  [key in keyof SubscriptionView] : SubscriptionView[key];
}

export function createPageReducer<T extends SubscriptionView>(state: T, action: {type: Actions, payload?: Payload}): T {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        ...action?.payload,
      }
    }
    default: return {...state}
  }
}
