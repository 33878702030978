import React, { MouseEvent, useEffect, useState } from 'react';
import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import courtIcon from '@/static/images/icons/court-icon.svg';
import houseCityIcon from '@/static/images/icons/house-city-icon-white.svg';
import medalIcon from '@/static/images/icons/medal-icon-white.svg';
import arrowRightIcon from '@/static/images/arrow-right.svg';
import styles from './ProfileViewPage.module.css';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import HLine from '@/components/HLine/HLine';
import InnerPadding from '@/components/InnerPadding/InnerPadding';
import PlayerStat from './components/PlayerStat/PlayerStat';
import HLWrapper from '@/components/HLWrapper/HLWrapper';
import bellIcon from '@/static/images/bell-icon.svg';
import feedbackBtn from '@/static/images/icons/feedback.svg';
import callIcon from '@/static/images/icons/call-icon.svg';
import { useAppSelector } from '@/hooks/hooks';
import { RootState } from '@/models/store';
import { feedbackReplyExist, pastGameList } from '../service';
import Notifications from '../../home/components/Notifications/Notifications';
import { GameElementInfo, PlayerProfileStatisticsView, PlayerProfileView, Role } from '@/integration-api/server-rest-lundapadelApi';
import tempImg from '@/pages/application/profile/profileEdit/temp.png'
import LevelDefine from './components/LevelDefine/LevelDefine';
import telegramLogo from '@/static/images/icons/telegram-colour-icon.svg';
import arrow from '@/static//images/back-icon.svg'
import { createImgSrc, playerHasRole } from '@/utils/utils';
import { FetchErrorMessage } from '@/types/types';
import verifiedIcon from '@/static/images/icons/verified-icon.svg'
import ballIcon from '@/static/images/icons/notification-ball-icon.svg';
import UserPhotoBackground from '@/components/UserPhotoBackground/UserPhotoBackground';
import PlayerRating from './components/PlayerRating/PlayerRating';
import settingIcon from '@/static/images/icons/settings-icon.svg';
import EmptyMatchListPlaceholder from '@/components/EmptyMatchListPlaceholder/EmptyMatchListPlaceholder';
import DBManager from '@/utils/DBManager/DBManager';
import CoachWhistle from '@/static/images/icons/CoachWhistle';
import TournamentAwardCard from './components/TournamentAwardCard/TournamentAwardCard';
import { existNotification } from '../../home/components/Notifications/service';
import GameMatchCard from '@/components/GameMatchCard/GameMatchCard';
import GameMinitournamentCard from '@/components/GameMinitournamentCard/GameMinitournamentCard';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

function ProfileViewPage() {
  const { id } = useParams<{ id: string }>();
  const stat = useAppSelector((state: RootState) => state.profile.stat);
  const [profile] = useOutletContext<Array<PlayerProfileView | null>>()
  const [statData, setStatData] = useState<PlayerProfileStatisticsView>();
  const currentUser = useAppSelector((state: RootState) => state.auth.currentProfile);
  const navigate = useNavigate();

  const [hiddenNotification, setHiddenNotification] = useState<boolean>(true);
  const [matchesList, setMatchesList] = useState<Array<GameElementInfo>>([]);
  const [showVerifiedInfo, setShowVerifiedInfo] = useState(false);
  const [notificationsExist, setNotificationsExist] = useState(false);
  const [feedbackExist, setFeedbackExist] = useState(false);

  const isUserProfile = currentUser?.identity?.uid === profile?.identity?.uid;

  useEffect(() => {
    if (navigator.onLine) {
      try {
        stat && matchesList && DBManager.create('profile', { ...profile, stat, matchesList }, profile?.identity?.uid!);
        setStatData(stat);
        setMatchesList(matchesList);
      } catch (e) {
        if (e instanceof Error) console.log(e.message);
      }
      stat && setStatData(stat)
    } else {
      (!matchesList.length || !statData) && DBManager.read('profile', id === 'me' ? (currentUser?.identity?.uid) as string : id as string).then((profileInfo: any) => {
        setStatData(profileInfo.stat)
        setMatchesList(profileInfo.matchesList || [])
      }).catch((err) => console.log(err)
      )
    }
  }, [matchesList, stat]);

  const handleClickTelegramBtn = () => {
    navigator.clipboard.writeText(profile?.telegramUsername!)
      .then(() => PopupMessage.open('Скопировано имя в телеграм.', 'success'))
      .catch(() => PopupMessage.open('Что-то пошло не так.'));
  }

  const handleBackBtnClick = () => {
    navigate(-1);
  }

  const changeNotifVisible = (condition: boolean) => {
    setHiddenNotification(condition);
    if(condition) {
      existNotification()
        .then(({ result }) => setNotificationsExist(!!result?.exist))
        .catch((err) => {
          if (err instanceof Promise) {
            err.then(err => {
              const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
              PopupMessage.open(userErrorMessage ?? errorMessage);
            });
          }
        });
    }
  };

  useEffect(() => {
    if (navigator.onLine){
      if (profile && id) {
        Promise.all([
          pastGameList({ playerUid: profile.identity?.uid ?? id, offset: 0, limit: 100 }),
          existNotification(),
          feedbackReplyExist(),
        ])
          .then(([{ result }, { result: result2 }, { result: result3 } ]) => {
            setMatchesList(result?.infos ?? []);
            setNotificationsExist(!!result2?.exist);
            setFeedbackExist(!!result3?.exist);
          })
          .catch((err) => {
            if (err instanceof Promise) {
              err.then(err => {
                const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
                PopupMessage.open(userErrorMessage ?? errorMessage);
              });
            }
          })
      }
    }
  }, [isUserProfile, profile, currentUser, id]);

  const closePopup = (e: MouseEvent) => {
    if ((e.target as HTMLElement).id !== 'verified') {
      setShowVerifiedInfo(false)
    }
  }

  return (
    <BasePageWrapper showNavBar>
      <div onClick={closePopup}>
        {hiddenNotification ? (
          <>
            <UserPhotoBackground bgSrc={profile?.profileImageUid ? createImgSrc.playerProfile(profile.identity?.uid, profile.profileImageUid) : tempImg} />
            <article className={styles.profilePhotoContainer}>
              {isUserProfile ?
                <div className={styles.actionIcons}>
                  <Link to="edit">
                    <img src={settingIcon} alt="Настройки" />
                  </Link>
                  <Link to='feedback' className={styles['feedback-wrapper']}>
                    <img src={feedbackBtn} alt="Feedback" />
                    {feedbackExist ? <img src={ballIcon} alt="иконка мячика" className={styles['feedback-exist']} /> : null}
                  </Link>
                  <div onClick={() => changeNotifVisible(false)}>
                    <div className={styles['notifIcon-wrapper']}>
                      {notificationsExist ? <img src={ballIcon} alt="иконка мячика" className={styles['notification-exist']} /> : null}
                      <img src={bellIcon} alt="иконка колокольчика" />
                    </div>
                  </div>
                </div>
                : (
                  <>
                    <img className={styles.backBtn} src={arrow} alt="Иконка назад" onClick={handleBackBtnClick} />
                    {/* Наличие номера телефона подразумевает то, что данный игрок имеет общий матч с текущим пользователем, но приоритетный вид связи - телеграм */}
                    {profile?.phone && (
                      <a href={profile?.telegramUsername ? `https://t.me/${profile?.telegramUsername}` : `tel:+7${profile?.phone}`}>
                          <img src={callIcon} alt="call" className={styles.call} />
                      </a>
                    )}
                  </>
                )
              }

              <div className={styles.profileStat}>
                {profile?.telegramUsername && (
                  <div className={styles.profileStatItem_telegram} onClick={handleClickTelegramBtn}>
                    <img src={telegramLogo} alt="Телеграм" />
                    @{profile?.telegramUsername}
                  </div>
                )}
                <Link to={isUserProfile ? `/my-games?tab=completed` : `/profile/${profile?.identity?.uid}/games`} className={styles.profileStatItem}>
                  <span className={styles.profileStatValue}>{statData?.tournamentCount ?? 0}</span>
                  <span className={styles.profileStatDescription}>Турниры</span>
                </Link>
                <Link to={isUserProfile ? `/my-games?tab=completed` : `/profile/${profile?.identity?.uid}/games`} className={styles.profileStatItem}>
                  <span className={styles.profileStatValue}>{statData?.gamesCount ?? 0}</span>
                  <span className={styles.profileStatDescription}>Игры</span>
                </Link>
                <Link to='partners' className={styles.profileStatItem}>
                  <span className={styles.profileStatValue}>{statData?.partnersCount ?? 0}</span>
                  <span className={styles.profileStatDescription}>Напарники</span>
                </Link>
              </div>

              <div className={styles.profileName}>
                <span className={styles.profileNameValue}>{profile?.displayName?.substring(0, 25)}</span>
              </div>
            </article>

            <div className={styles.about}>
              <div className={styles.aboutHeader}>
                <span className={styles.aboutHeaderTitle}>Рейтинг:</span>
                <span className={styles.aboutHeaderRating}>{profile?.displayRating}</span>
                {!playerHasRole(profile!, Role.COACH) && profile?.gradeVerified ?
                  <span className={styles['popup-wrapper']}>
                    <img
                      id='verified'
                      src={verifiedIcon}
                      alt='verified'
                      onClick={() => setShowVerifiedInfo(state => !state)} />
                    {showVerifiedInfo &&
                      <div className={styles['popup']}>
                        Уровень игрока подтвержден тренером
                      </div>
                    }
                  </span> : null
                }
              </div>
              <PlayerRating />
              <div className={styles.itemWrapper}>
                <div className={styles.item}>
                  <img src={medalIcon} alt="Медаль" />
                  {profile?.displayGrade ?? '-'}
                  <LevelDefine />
                </div>

                <div className={styles.item}>
                  <img src={houseCityIcon} alt="Дом" />
                  {profile?.city ? profile?.city : '-'}
                </div>

                <div className={styles.item}>
                  <img src={courtIcon} alt="Сторона" />
                  {(profile?.rightSide || profile?.leftSide) ?
                    (profile?.rightSide && profile?.leftSide ? ('Оба') :
                      `${profile?.rightSide ? 'Правый' : ''}${profile?.leftSide ? 'Левый' : ''}`) : '-'
                  }
                </div>

                {profile?.roles?.includes(Role.COACH) && <div className={styles.item}>
                  <CoachWhistle className={styles.coachIcon} />
                  Тренер
                </div>}
              </div>
            </div>

            {stat?.tournaments?.length ?
              <>
                <HLine calssName={styles['hline']}/>
                <div className={styles.tournamentsContainer}>
                  <InnerPadding>
                    <div className={styles.matchListHeader}>
                      <h2 className={styles.matchListHeaderTitle}>Награды</h2>
                    </div>
                  </InnerPadding>
                  <div className={styles.tournamentList}>
                    {stat.tournaments.map((tournament, i) => (
                      <TournamentAwardCard
                        key={i}
                        tournament={tournament}
                      />
                    ))}
                  </div>
                </div>
              </> : null
            }
            <HLine calssName={styles['hline']}/>
            <div className={styles.matchListContainer}>
              <InnerPadding>
                <div className={styles.matchListHeader}>
                  <h2 className={styles.matchListHeaderTitle}>Результаты</h2>
                  {isUserProfile ? <Link to='/my-games?tab=completed' className={styles.allResults}>
                    К играм
                    <img src={arrowRightIcon} alt="Стрелка вправо" />
                  </Link> : null}
                </div>
              </InnerPadding>
              {matchesList.length ?
                <div className={styles.matchList}>
                  {matchesList.map((match, i) => {
                    if(match.tournamentKind) {
                      return (
                        <Link to={`/game/${match.uid}`} key={i}>
                          <GameMinitournamentCard minitournament={match} />
                        </Link>
                      )
                    } else {
                      return (
                        <Link to={`/game/${match.uid}`} key={i}>
                          <GameMatchCard
                            match={match}
                            profileUid={profile?.identity?.uid ?? ''}
                          />
                        </Link>
                      )
                    }
                  })}
                </div> :
                <EmptyMatchListPlaceholder
                  action='newGame'
                  className={styles['empty-matches']}
                  title='Здесь будет отображаться список ваших прошедших игр'
                />
              }
            </div>

            <HLWrapper>
              <PlayerStat playerStat={statData} />
            </HLWrapper>
          </>
        ) : (
          <Notifications onCloseNotifications={() => changeNotifVisible(true)} />
        )}
      </div>
    </BasePageWrapper>
  );
}

export default React.memo(ProfileViewPage);
