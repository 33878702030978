/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseMetricView,
    BaseMetricViewFromJSON,
    BaseMetricViewFromJSONTyped,
    BaseMetricViewToJSON,
} from './BaseMetricView';
import {
    GameSortParam,
    GameSortParamFromJSON,
    GameSortParamFromJSONTyped,
    GameSortParamToJSON,
} from './GameSortParam';
import {
    Grade,
    GradeFromJSON,
    GradeFromJSONTyped,
    GradeToJSON,
} from './Grade';
import {
    MatchSortParam,
    MatchSortParamFromJSON,
    MatchSortParamFromJSONTyped,
    MatchSortParamToJSON,
} from './MatchSortParam';

/**
 * 
 * @export
 * @interface UIGameSettingsView
 */
export interface UIGameSettingsView {
    /**
     * 
     * @type {boolean}
     * @memberof UIGameSettingsView
     */
    onlyMyInvite?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIGameSettingsView
     */
    onlyMyPartners?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIGameSettingsView
     */
    onlyMyRating?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIGameSettingsView
     */
    showAll?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UIGameSettingsView
     */
    onlyTournament?: boolean;
    /**
     * 
     * @type {MatchSortParam}
     * @memberof UIGameSettingsView
     */
    sortBy?: MatchSortParam;
    /**
     * 
     * @type {GameSortParam}
     * @memberof UIGameSettingsView
     */
    gameSortBy?: GameSortParam;
    /**
     * 
     * @type {boolean}
     * @memberof UIGameSettingsView
     */
    onlyFavoriteClubs?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UIGameSettingsView
     */
    city?: string;
    /**
     * 
     * @type {Grade}
     * @memberof UIGameSettingsView
     */
    minGrade?: Grade;
    /**
     * 
     * @type {Grade}
     * @memberof UIGameSettingsView
     */
    maxGrade?: Grade;
    /**
     * 
     * @type {BaseMetricView}
     * @memberof UIGameSettingsView
     */
    metrics?: BaseMetricView;
}

export function UIGameSettingsViewFromJSON(json: any): UIGameSettingsView {
    return UIGameSettingsViewFromJSONTyped(json, false);
}

export function UIGameSettingsViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): UIGameSettingsView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'onlyMyInvite': !exists(json, 'onlyMyInvite') ? undefined : json['onlyMyInvite'],
        'onlyMyPartners': !exists(json, 'onlyMyPartners') ? undefined : json['onlyMyPartners'],
        'onlyMyRating': !exists(json, 'onlyMyRating') ? undefined : json['onlyMyRating'],
        'showAll': !exists(json, 'showAll') ? undefined : json['showAll'],
        'onlyTournament': !exists(json, 'onlyTournament') ? undefined : json['onlyTournament'],
        'sortBy': !exists(json, 'sortBy') ? undefined : MatchSortParamFromJSON(json['sortBy']),
        'gameSortBy': !exists(json, 'gameSortBy') ? undefined : GameSortParamFromJSON(json['gameSortBy']),
        'onlyFavoriteClubs': !exists(json, 'onlyFavoriteClubs') ? undefined : json['onlyFavoriteClubs'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'minGrade': !exists(json, 'minGrade') ? undefined : GradeFromJSON(json['minGrade']),
        'maxGrade': !exists(json, 'maxGrade') ? undefined : GradeFromJSON(json['maxGrade']),
        'metrics': !exists(json, 'metrics') ? undefined : BaseMetricViewFromJSON(json['metrics']),
    };
}

export function UIGameSettingsViewToJSON(value?: UIGameSettingsView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'onlyMyInvite': value.onlyMyInvite,
        'onlyMyPartners': value.onlyMyPartners,
        'onlyMyRating': value.onlyMyRating,
        'showAll': value.showAll,
        'onlyTournament': value.onlyTournament,
        'sortBy': MatchSortParamToJSON(value.sortBy),
        'gameSortBy': GameSortParamToJSON(value.gameSortBy),
        'onlyFavoriteClubs': value.onlyFavoriteClubs,
        'city': value.city,
        'minGrade': GradeToJSON(value.minGrade),
        'maxGrade': GradeToJSON(value.maxGrade),
        'metrics': BaseMetricViewToJSON(value.metrics),
    };
}

