import { AllHTMLAttributes } from 'react';

type ArrowIcon = {
  stroke?: string;
  direction?: 'up' | 'rigth' | 'down' | 'left';
  style?: AllHTMLAttributes<SVGAElement>['style'];
}

const ArrowIcon = ({ stroke, direction = 'down', style }: ArrowIcon) => {

  let deg = 0;
  switch (direction) {
    case 'up':
      deg = 180;
      break;
    case 'rigth':
      deg = 270;
      break;
    case 'down':
      deg = 0;
      break;
    case 'left':
      deg = 90;
  }

  return ( 
    <svg style={{transform: `rotate(${deg}deg)`, ...style}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 10L12 15L17 10" stroke={stroke ?? "#C9C9C9"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
 
export default ArrowIcon;
