import goldMedal from '@/static/images/icons/tournament-result-medal-gold.svg';
import silverMedal from '@/static/images/icons/tournament-result-medal-silver.svg';
import bronzeMedal from '@/static/images/icons/tournament-result-medal-bronze.svg';
import { ReactNode } from 'react';
import styles from './TournamentTableItem.module.css';
import { Share } from '@capacitor/share';
import { TournamentView } from '@/integration-api/server-rest-lundapadelApi';
import { Popup } from '@mui/base/Unstable_Popup/Popup';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

export const renderPlacement = (placement: number): ReactNode => {
  switch(placement) {
    case 1: 
      return <img src={goldMedal} alt='золото' className={styles['medal']}/>
    case 2:
      return <img src={silverMedal} alt='серебро' className={styles['medal']}/>
    case 3:
      return <img src={bronzeMedal} alt='бронза'  className={styles['medal']}/>
    default: 
      return <span className={styles['placement']}>{placement}</span>
  }
}

export const shareTournament = async (tournament: TournamentView) => {
  const {value} = await Share.canShare()
  if (value) {
    await Share.share({
      title: `Присоединяйся к турниру ${tournament.title}`,
      url: `https://app.lundapadel.ru/share/tournament/${tournament.identity?.uid}`
    });
  } else {
    if (navigator.share !== undefined) {
      const shareData: ShareData = {
        title: `Присоединяйся к турниру ${tournament.title}`,
        url: `${window.location.origin}/share${window.location.pathname}`,
      };
      await navigator.share(shareData);
    } else {
      PopupMessage.open('Функционал не поддерживается')
    }
  }
}
