import { ChatInfo } from "@/integration-api/server-rest-lundapadelApi";
import styles from "./ChatButton.module.css";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import ChatIcon from "@/static/images/icons/ChatIcon";
import BallSmallIcon from "@/static/images/icons/BallSmallIcon";
import { uidSearchName } from "@/pages/application/game/GameChatPage/GameChatPage";

type ChatButtonProps = {
  enitity: 'game' | 'tournament';
  chats: Array<ChatInfo>;
  id: string;
}

const ChatButton = ({ chats, id, enitity }: ChatButtonProps) => {
  const navigate = useNavigate();
  
  let areChatsActive = false, privateChatUid: string | undefined, publicChatUid: string | undefined, unreadMessages = false;

  chats.forEach(chat => {
    if(chat.active) {
      areChatsActive = true;
    }
    chat.opened ? publicChatUid = chat.chatUid : privateChatUid = chat.chatUid; 
    if(chat.unreadMessages) {
      unreadMessages = true;
    }
  });

  return areChatsActive ? 
    ( 
      <div className={styles['button']} onClick={() => navigate(`/${enitity}/${id}/chat?${uidSearchName}=${privateChatUid || publicChatUid}`)}>
        <div>
          <ChatIcon/>
        </div>
        {unreadMessages ?                     
          <div className={styles['button-ball']}>
            <BallSmallIcon/>
          </div> : null
        }
      </div> 
    ) : null;
}
 
export default memo(ChatButton);
