import RestClient from '@/integration/RestClient';

export async function fetchSubscription() {
  try {
    const { result } = await RestClient.profileApi.loadListSubscription();
    return result;
  } catch (err) {
    throw err;
  }
}

export function deleteSubscription(uid: string) {
  return RestClient.profileApi.removeSubscription({
    removeSubscriptionRequest: {
      parameters: {uid}
    }
  });
}
