type ShareIconProps = {
  fill?: string;
}

const ShareIcon = ({ fill }: ShareIconProps) => {
  return (  
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.5 9.75032V20.2503C5.5 20.3883 5.612 20.5003 5.75 20.5003H18.25C18.3163 20.5003 18.3799 20.474 18.4268 20.4271C18.4737 20.3802 18.5 20.3166 18.5 20.2503V9.75032C18.5 9.68401 18.4737 9.62042 18.4268 9.57354C18.3799 9.52666 18.3163 9.50032 18.25 9.50032H15.75C15.5511 9.50032 15.3603 9.4213 15.2197 9.28065C15.079 9.13999 15 8.94923 15 8.75032C15 8.5514 15.079 8.36064 15.2197 8.21999C15.3603 8.07933 15.5511 8.00032 15.75 8.00032H18.25C19.216 8.00032 20 8.78432 20 9.75032V20.2503C20 20.7144 19.8156 21.1596 19.4874 21.4878C19.1592 21.8159 18.7141 22.0003 18.25 22.0003H5.75C5.28587 22.0003 4.84075 21.8159 4.51256 21.4878C4.18437 21.1596 4 20.7144 4 20.2503V9.75032C4 8.78432 4.784 8.00032 5.75 8.00032H8.25C8.44891 8.00032 8.63968 8.07933 8.78033 8.21999C8.92098 8.36064 9 8.5514 9 8.75032C9 8.94923 8.92098 9.13999 8.78033 9.28065C8.63968 9.4213 8.44891 9.50032 8.25 9.50032H5.75C5.6837 9.50032 5.62011 9.52666 5.57322 9.57354C5.52634 9.62042 5.5 9.68401 5.5 9.75032ZM12.53 1.22032L15.78 4.47032C15.8787 4.56199 15.9509 4.67859 15.9889 4.80784C16.0269 4.93709 16.0294 5.0742 15.996 5.20472C15.9626 5.33525 15.8947 5.45435 15.7993 5.54949C15.7039 5.64463 15.5846 5.71228 15.454 5.74532C15.3236 5.77873 15.1867 5.7764 15.0575 5.73857C14.9283 5.70073 14.8117 5.6288 14.72 5.53032L12.75 3.56032V14.2503C12.75 14.4492 12.671 14.64 12.5303 14.7806C12.3897 14.9213 12.1989 15.0003 12 15.0003C11.8011 15.0003 11.6103 14.9213 11.4697 14.7806C11.329 14.64 11.25 14.4492 11.25 14.2503V3.56032L9.28 5.53032C9.13774 5.66273 8.94969 5.73483 8.75537 5.73147C8.56105 5.72812 8.3756 5.64956 8.238 5.51232C8.10076 5.37471 8.0222 5.18926 8.01884 4.99495C8.01549 4.80063 8.08759 4.61258 8.22 4.47032L11.47 1.22032C11.6106 1.07987 11.8012 1.00098 12 1.00098C12.1988 1.00098 12.3894 1.07987 12.53 1.22032Z" fill={fill ?? "white"}/>
    </svg>
  );
}
 
export default ShareIcon;
