/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthRequest,
    AuthRequestFromJSON,
    AuthRequestToJSON,
    AuthResponse,
    AuthResponseFromJSON,
    AuthResponseToJSON,
    ChangePhoneNumberRequest,
    ChangePhoneNumberRequestFromJSON,
    ChangePhoneNumberRequestToJSON,
    ChangePhoneNumberResponse,
    ChangePhoneNumberResponseFromJSON,
    ChangePhoneNumberResponseToJSON,
    CurrentPlayerLoadResponse,
    CurrentPlayerLoadResponseFromJSON,
    CurrentPlayerLoadResponseToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    GetUIGameSettingsResponse,
    GetUIGameSettingsResponseFromJSON,
    GetUIGameSettingsResponseToJSON,
    GetUIRatingSettingsResponse,
    GetUIRatingSettingsResponseFromJSON,
    GetUIRatingSettingsResponseToJSON,
    LoadCaptchaRequest,
    LoadCaptchaRequestFromJSON,
    LoadCaptchaRequestToJSON,
    LoadCaptchaResponse,
    LoadCaptchaResponseFromJSON,
    LoadCaptchaResponseToJSON,
    LoadListSubscriptionResponse,
    LoadListSubscriptionResponseFromJSON,
    LoadListSubscriptionResponseToJSON,
    LoadProfileStatisticRequest,
    LoadProfileStatisticRequestFromJSON,
    LoadProfileStatisticRequestToJSON,
    LoadProfileStatisticResponse,
    LoadProfileStatisticResponseFromJSON,
    LoadProfileStatisticResponseToJSON,
    LoadSubscriptionResponse,
    LoadSubscriptionResponseFromJSON,
    LoadSubscriptionResponseToJSON,
    LoadTourGameBySubscriptionResponse,
    LoadTourGameBySubscriptionResponseFromJSON,
    LoadTourGameBySubscriptionResponseToJSON,
    PartnerDeleteRequest,
    PartnerDeleteRequestFromJSON,
    PartnerDeleteRequestToJSON,
    PartnerLoadListResponse,
    PartnerLoadListResponseFromJSON,
    PartnerLoadListResponseToJSON,
    PlayerImageRemoveRequest,
    PlayerImageRemoveRequestFromJSON,
    PlayerImageRemoveRequestToJSON,
    PlayerImageRemoveResponse,
    PlayerImageRemoveResponseFromJSON,
    PlayerImageRemoveResponseToJSON,
    PlayerImageSaveRequest,
    PlayerImageSaveRequestFromJSON,
    PlayerImageSaveRequestToJSON,
    PlayerImageSaveResponse,
    PlayerImageSaveResponseFromJSON,
    PlayerImageSaveResponseToJSON,
    PlayerLoadListRequest,
    PlayerLoadListRequestFromJSON,
    PlayerLoadListRequestToJSON,
    PlayerLoadListResponse,
    PlayerLoadListResponseFromJSON,
    PlayerLoadListResponseToJSON,
    PlayerLoadRequest,
    PlayerLoadRequestFromJSON,
    PlayerLoadRequestToJSON,
    PlayerLoadResponse,
    PlayerLoadResponseFromJSON,
    PlayerLoadResponseToJSON,
    PlayerSaveRequest,
    PlayerSaveRequestFromJSON,
    PlayerSaveRequestToJSON,
    PlayerSaveResponse,
    PlayerSaveResponseFromJSON,
    PlayerSaveResponseToJSON,
    ProfileQuestionnaireLoadResponse,
    ProfileQuestionnaireLoadResponseFromJSON,
    ProfileQuestionnaireLoadResponseToJSON,
    ProfileQuestionnaireSaveRequest,
    ProfileQuestionnaireSaveRequestFromJSON,
    ProfileQuestionnaireSaveRequestToJSON,
    ProfileQuestionnaireSaveResponse,
    ProfileQuestionnaireSaveResponseFromJSON,
    ProfileQuestionnaireSaveResponseToJSON,
    RecommendedPlayerRequest,
    RecommendedPlayerRequestFromJSON,
    RecommendedPlayerRequestToJSON,
    RecommendedPlayerResponse,
    RecommendedPlayerResponseFromJSON,
    RecommendedPlayerResponseToJSON,
    RegisterRequest,
    RegisterRequestFromJSON,
    RegisterRequestToJSON,
    RegisterResponse,
    RegisterResponseFromJSON,
    RegisterResponseToJSON,
    RemoveSubscriptionRequest,
    RemoveSubscriptionRequestFromJSON,
    RemoveSubscriptionRequestToJSON,
    SaveSubscriptionRequest,
    SaveSubscriptionRequestFromJSON,
    SaveSubscriptionRequestToJSON,
    SaveSubscriptionResponse,
    SaveSubscriptionResponseFromJSON,
    SaveSubscriptionResponseToJSON,
    SaveUIGameSettingsRequest,
    SaveUIGameSettingsRequestFromJSON,
    SaveUIGameSettingsRequestToJSON,
    SaveUIRatingSettingsRequest,
    SaveUIRatingSettingsRequestFromJSON,
    SaveUIRatingSettingsRequestToJSON,
    SendCodeRequest,
    SendCodeRequestFromJSON,
    SendCodeRequestToJSON,
    SendCodeResponse,
    SendCodeResponseFromJSON,
    SendCodeResponseToJSON,
    SetFavoriteClubsRequest,
    SetFavoriteClubsRequestFromJSON,
    SetFavoriteClubsRequestToJSON,
    SetFavoriteClubsResponse,
    SetFavoriteClubsResponseFromJSON,
    SetFavoriteClubsResponseToJSON,
    TeamSearchRequest,
    TeamSearchRequestFromJSON,
    TeamSearchRequestToJSON,
    TeamSearchResponse,
    TeamSearchResponseFromJSON,
    TeamSearchResponseToJSON,
} from '../models';

export interface LundapadelProfileApiAuthOperationRequest {
    authRequest: AuthRequest;
}

export interface LundapadelProfileApiChangePhoneNumberOperationRequest {
    changePhoneNumberRequest: ChangePhoneNumberRequest;
}

export interface LundapadelProfileApiLoadCaptchaOperationRequest {
    loadCaptchaRequest: LoadCaptchaRequest;
}

export interface LundapadelProfileApiLoadProfileStatisticOperationRequest {
    loadProfileStatisticRequest: LoadProfileStatisticRequest;
}

export interface LundapadelProfileApiLoadSubscriptionRequest {
    uid: string;
}

export interface LundapadelProfileApiLoadTourGameBySubscriptionRequest {
    subscriptionUid: string;
}

export interface LundapadelProfileApiPartnerDeleteOperationRequest {
    partnerDeleteRequest: PartnerDeleteRequest;
}

export interface LundapadelProfileApiPartnerLoadListRequest {
    profileUid: string;
}

export interface LundapadelProfileApiPlayerImageRemoveOperationRequest {
    playerImageRemoveRequest: PlayerImageRemoveRequest;
}

export interface LundapadelProfileApiPlayerImageSaveOperationRequest {
    playerImageSaveRequest: PlayerImageSaveRequest;
}

export interface LundapadelProfileApiPlayerLoadOperationRequest {
    playerLoadRequest: PlayerLoadRequest;
}

export interface LundapadelProfileApiPlayerLoadListOperationRequest {
    playerLoadListRequest: PlayerLoadListRequest;
}

export interface LundapadelProfileApiPlayerSaveOperationRequest {
    playerSaveRequest: PlayerSaveRequest;
}

export interface LundapadelProfileApiProfileQuestionnaireLoadRequest {
    registrationToken: string;
    phone: string;
    countryCode: string;
}

export interface LundapadelProfileApiProfileQuestionnaireSaveOperationRequest {
    profileQuestionnaireSaveRequest: ProfileQuestionnaireSaveRequest;
}

export interface LundapadelProfileApiRecommendedPlayerOperationRequest {
    recommendedPlayerRequest: RecommendedPlayerRequest;
}

export interface LundapadelProfileApiRegisterOperationRequest {
    registerRequest: RegisterRequest;
}

export interface LundapadelProfileApiRemoveSubscriptionOperationRequest {
    removeSubscriptionRequest: RemoveSubscriptionRequest;
}

export interface LundapadelProfileApiSaveSubscriptionOperationRequest {
    saveSubscriptionRequest: SaveSubscriptionRequest;
}

export interface LundapadelProfileApiSaveUIGameSettingsOperationRequest {
    saveUIGameSettingsRequest: SaveUIGameSettingsRequest;
}

export interface LundapadelProfileApiSaveUIRatingSettingsOperationRequest {
    saveUIRatingSettingsRequest: SaveUIRatingSettingsRequest;
}

export interface LundapadelProfileApiSendCodeOperationRequest {
    sendCodeRequest: SendCodeRequest;
}

export interface LundapadelProfileApiSetFavoriteClubsOperationRequest {
    setFavoriteClubsRequest: SetFavoriteClubsRequest;
}

export interface LundapadelProfileApiTeamSearchOperationRequest {
    teamSearchRequest: TeamSearchRequest;
}

/**
 * 
 */
export class LundapadelProfileApi extends runtime.BaseAPI {

    /**
     * Authenticate user
     */
    async authRaw(requestParameters: LundapadelProfileApiAuthOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthResponse>> {
        if (requestParameters.authRequest === null || requestParameters.authRequest === undefined) {
            throw new runtime.RequiredError('authRequest','Required parameter requestParameters.authRequest was null or undefined when calling auth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/auth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthRequestToJSON(requestParameters.authRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthResponseFromJSON(jsonValue));
    }

    /**
     * Authenticate user
     */
    async auth(requestParameters: LundapadelProfileApiAuthOperationRequest, initOverrides?: RequestInit): Promise<AuthResponse> {
        const response = await this.authRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Change player profile phone number
     */
    async changePhoneNumberRaw(requestParameters: LundapadelProfileApiChangePhoneNumberOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChangePhoneNumberResponse>> {
        if (requestParameters.changePhoneNumberRequest === null || requestParameters.changePhoneNumberRequest === undefined) {
            throw new runtime.RequiredError('changePhoneNumberRequest','Required parameter requestParameters.changePhoneNumberRequest was null or undefined when calling changePhoneNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/change-phone-number`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePhoneNumberRequestToJSON(requestParameters.changePhoneNumberRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChangePhoneNumberResponseFromJSON(jsonValue));
    }

    /**
     * Change player profile phone number
     */
    async changePhoneNumber(requestParameters: LundapadelProfileApiChangePhoneNumberOperationRequest, initOverrides?: RequestInit): Promise<ChangePhoneNumberResponse> {
        const response = await this.changePhoneNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load current player profile
     */
    async currentPlayerLoadRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<CurrentPlayerLoadResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/current`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CurrentPlayerLoadResponseFromJSON(jsonValue));
    }

    /**
     * Load current player profile
     */
    async currentPlayerLoad(initOverrides?: RequestInit): Promise<CurrentPlayerLoadResponse> {
        const response = await this.currentPlayerLoadRaw(initOverrides);
        return await response.value();
    }

    /**
     * Set favorite clubs to player profile
     */
    async getUIGameSettingsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetUIGameSettingsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/ui-game-settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUIGameSettingsResponseFromJSON(jsonValue));
    }

    /**
     * Set favorite clubs to player profile
     */
    async getUIGameSettings(initOverrides?: RequestInit): Promise<GetUIGameSettingsResponse> {
        const response = await this.getUIGameSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Load profile rating filter settings
     */
    async getUIRatingSettingsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetUIRatingSettingsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/ui-rating-settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUIRatingSettingsResponseFromJSON(jsonValue));
    }

    /**
     * Load profile rating filter settings
     */
    async getUIRatingSettings(initOverrides?: RequestInit): Promise<GetUIRatingSettingsResponse> {
        const response = await this.getUIRatingSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Load captcha by phone number
     */
    async loadCaptchaRaw(requestParameters: LundapadelProfileApiLoadCaptchaOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadCaptchaResponse>> {
        if (requestParameters.loadCaptchaRequest === null || requestParameters.loadCaptchaRequest === undefined) {
            throw new runtime.RequiredError('loadCaptchaRequest','Required parameter requestParameters.loadCaptchaRequest was null or undefined when calling loadCaptcha.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/captcha`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LoadCaptchaRequestToJSON(requestParameters.loadCaptchaRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadCaptchaResponseFromJSON(jsonValue));
    }

    /**
     * Load captcha by phone number
     */
    async loadCaptcha(requestParameters: LundapadelProfileApiLoadCaptchaOperationRequest, initOverrides?: RequestInit): Promise<LoadCaptchaResponse> {
        const response = await this.loadCaptchaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load list subscriptions
     */
    async loadListSubscriptionRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadListSubscriptionResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/subscription-load-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadListSubscriptionResponseFromJSON(jsonValue));
    }

    /**
     * Load list subscriptions
     */
    async loadListSubscription(initOverrides?: RequestInit): Promise<LoadListSubscriptionResponse> {
        const response = await this.loadListSubscriptionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Load player statistic
     */
    async loadProfileStatisticRaw(requestParameters: LundapadelProfileApiLoadProfileStatisticOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadProfileStatisticResponse>> {
        if (requestParameters.loadProfileStatisticRequest === null || requestParameters.loadProfileStatisticRequest === undefined) {
            throw new runtime.RequiredError('loadProfileStatisticRequest','Required parameter requestParameters.loadProfileStatisticRequest was null or undefined when calling loadProfileStatistic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/load-statistic`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoadProfileStatisticRequestToJSON(requestParameters.loadProfileStatisticRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadProfileStatisticResponseFromJSON(jsonValue));
    }

    /**
     * Load player statistic
     */
    async loadProfileStatistic(requestParameters: LundapadelProfileApiLoadProfileStatisticOperationRequest, initOverrides?: RequestInit): Promise<LoadProfileStatisticResponse> {
        const response = await this.loadProfileStatisticRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load subscription
     */
    async loadSubscriptionRaw(requestParameters: LundapadelProfileApiLoadSubscriptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadSubscriptionResponse>> {
        if (requestParameters.uid === null || requestParameters.uid === undefined) {
            throw new runtime.RequiredError('uid','Required parameter requestParameters.uid was null or undefined when calling loadSubscription.');
        }

        const queryParameters: any = {};

        if (requestParameters.uid !== undefined) {
            queryParameters['uid'] = requestParameters.uid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/subscription-load`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadSubscriptionResponseFromJSON(jsonValue));
    }

    /**
     * Load subscription
     */
    async loadSubscription(requestParameters: LundapadelProfileApiLoadSubscriptionRequest, initOverrides?: RequestInit): Promise<LoadSubscriptionResponse> {
        const response = await this.loadSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load TourGame by subscriptions
     */
    async loadTourGameBySubscriptionRaw(requestParameters: LundapadelProfileApiLoadTourGameBySubscriptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadTourGameBySubscriptionResponse>> {
        if (requestParameters.subscriptionUid === null || requestParameters.subscriptionUid === undefined) {
            throw new runtime.RequiredError('subscriptionUid','Required parameter requestParameters.subscriptionUid was null or undefined when calling loadTourGameBySubscription.');
        }

        const queryParameters: any = {};

        if (requestParameters.subscriptionUid !== undefined) {
            queryParameters['subscriptionUid'] = requestParameters.subscriptionUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tourgame/subscription-load-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadTourGameBySubscriptionResponseFromJSON(jsonValue));
    }

    /**
     * Load TourGame by subscriptions
     */
    async loadTourGameBySubscription(requestParameters: LundapadelProfileApiLoadTourGameBySubscriptionRequest, initOverrides?: RequestInit): Promise<LoadTourGameBySubscriptionResponse> {
        const response = await this.loadTourGameBySubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Logout
     */
    async logoutRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Logout
     */
    async logout(initOverrides?: RequestInit): Promise<void> {
        await this.logoutRaw(initOverrides);
    }

    /**
     * Delete partner from player profile
     */
    async partnerDeleteRaw(requestParameters: LundapadelProfileApiPartnerDeleteOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.partnerDeleteRequest === null || requestParameters.partnerDeleteRequest === undefined) {
            throw new runtime.RequiredError('partnerDeleteRequest','Required parameter requestParameters.partnerDeleteRequest was null or undefined when calling partnerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/partners`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: PartnerDeleteRequestToJSON(requestParameters.partnerDeleteRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete partner from player profile
     */
    async partnerDelete(requestParameters: LundapadelProfileApiPartnerDeleteOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.partnerDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Load list of partners
     */
    async partnerLoadListRaw(requestParameters: LundapadelProfileApiPartnerLoadListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PartnerLoadListResponse>> {
        if (requestParameters.profileUid === null || requestParameters.profileUid === undefined) {
            throw new runtime.RequiredError('profileUid','Required parameter requestParameters.profileUid was null or undefined when calling partnerLoadList.');
        }

        const queryParameters: any = {};

        if (requestParameters.profileUid !== undefined) {
            queryParameters['profileUid'] = requestParameters.profileUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/partners`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PartnerLoadListResponseFromJSON(jsonValue));
    }

    /**
     * Load list of partners
     */
    async partnerLoadList(requestParameters: LundapadelProfileApiPartnerLoadListRequest, initOverrides?: RequestInit): Promise<PartnerLoadListResponse> {
        const response = await this.partnerLoadListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove player image
     */
    async playerImageRemoveRaw(requestParameters: LundapadelProfileApiPlayerImageRemoveOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PlayerImageRemoveResponse>> {
        if (requestParameters.playerImageRemoveRequest === null || requestParameters.playerImageRemoveRequest === undefined) {
            throw new runtime.RequiredError('playerImageRemoveRequest','Required parameter requestParameters.playerImageRemoveRequest was null or undefined when calling playerImageRemove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/remove-image`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PlayerImageRemoveRequestToJSON(requestParameters.playerImageRemoveRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlayerImageRemoveResponseFromJSON(jsonValue));
    }

    /**
     * Remove player image
     */
    async playerImageRemove(requestParameters: LundapadelProfileApiPlayerImageRemoveOperationRequest, initOverrides?: RequestInit): Promise<PlayerImageRemoveResponse> {
        const response = await this.playerImageRemoveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Save player image
     */
    async playerImageSaveRaw(requestParameters: LundapadelProfileApiPlayerImageSaveOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PlayerImageSaveResponse>> {
        if (requestParameters.playerImageSaveRequest === null || requestParameters.playerImageSaveRequest === undefined) {
            throw new runtime.RequiredError('playerImageSaveRequest','Required parameter requestParameters.playerImageSaveRequest was null or undefined when calling playerImageSave.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/save-image`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlayerImageSaveRequestToJSON(requestParameters.playerImageSaveRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlayerImageSaveResponseFromJSON(jsonValue));
    }

    /**
     * Save player image
     */
    async playerImageSave(requestParameters: LundapadelProfileApiPlayerImageSaveOperationRequest, initOverrides?: RequestInit): Promise<PlayerImageSaveResponse> {
        const response = await this.playerImageSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load player
     */
    async playerLoadRaw(requestParameters: LundapadelProfileApiPlayerLoadOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PlayerLoadResponse>> {
        if (requestParameters.playerLoadRequest === null || requestParameters.playerLoadRequest === undefined) {
            throw new runtime.RequiredError('playerLoadRequest','Required parameter requestParameters.playerLoadRequest was null or undefined when calling playerLoad.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/load`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlayerLoadRequestToJSON(requestParameters.playerLoadRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlayerLoadResponseFromJSON(jsonValue));
    }

    /**
     * Load player
     */
    async playerLoad(requestParameters: LundapadelProfileApiPlayerLoadOperationRequest, initOverrides?: RequestInit): Promise<PlayerLoadResponse> {
        const response = await this.playerLoadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load list of players
     */
    async playerLoadListRaw(requestParameters: LundapadelProfileApiPlayerLoadListOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PlayerLoadListResponse>> {
        if (requestParameters.playerLoadListRequest === null || requestParameters.playerLoadListRequest === undefined) {
            throw new runtime.RequiredError('playerLoadListRequest','Required parameter requestParameters.playerLoadListRequest was null or undefined when calling playerLoadList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/load-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlayerLoadListRequestToJSON(requestParameters.playerLoadListRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlayerLoadListResponseFromJSON(jsonValue));
    }

    /**
     * Load list of players
     */
    async playerLoadList(requestParameters: LundapadelProfileApiPlayerLoadListOperationRequest, initOverrides?: RequestInit): Promise<PlayerLoadListResponse> {
        const response = await this.playerLoadListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Save player
     */
    async playerSaveRaw(requestParameters: LundapadelProfileApiPlayerSaveOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PlayerSaveResponse>> {
        if (requestParameters.playerSaveRequest === null || requestParameters.playerSaveRequest === undefined) {
            throw new runtime.RequiredError('playerSaveRequest','Required parameter requestParameters.playerSaveRequest was null or undefined when calling playerSave.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlayerSaveRequestToJSON(requestParameters.playerSaveRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlayerSaveResponseFromJSON(jsonValue));
    }

    /**
     * Save player
     */
    async playerSave(requestParameters: LundapadelProfileApiPlayerSaveOperationRequest, initOverrides?: RequestInit): Promise<PlayerSaveResponse> {
        const response = await this.playerSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete account player profile
     */
    async profileDeleteRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete account player profile
     */
    async profileDelete(initOverrides?: RequestInit): Promise<void> {
        await this.profileDeleteRaw(initOverrides);
    }

    /**
     * Load profile questionnaire
     */
    async profileQuestionnaireLoadRaw(requestParameters: LundapadelProfileApiProfileQuestionnaireLoadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfileQuestionnaireLoadResponse>> {
        if (requestParameters.registrationToken === null || requestParameters.registrationToken === undefined) {
            throw new runtime.RequiredError('registrationToken','Required parameter requestParameters.registrationToken was null or undefined when calling profileQuestionnaireLoad.');
        }

        if (requestParameters.phone === null || requestParameters.phone === undefined) {
            throw new runtime.RequiredError('phone','Required parameter requestParameters.phone was null or undefined when calling profileQuestionnaireLoad.');
        }

        if (requestParameters.countryCode === null || requestParameters.countryCode === undefined) {
            throw new runtime.RequiredError('countryCode','Required parameter requestParameters.countryCode was null or undefined when calling profileQuestionnaireLoad.');
        }

        const queryParameters: any = {};

        if (requestParameters.registrationToken !== undefined) {
            queryParameters['registrationToken'] = requestParameters.registrationToken;
        }

        if (requestParameters.phone !== undefined) {
            queryParameters['phone'] = requestParameters.phone;
        }

        if (requestParameters.countryCode !== undefined) {
            queryParameters['countryCode'] = requestParameters.countryCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/player/questionnaires`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileQuestionnaireLoadResponseFromJSON(jsonValue));
    }

    /**
     * Load profile questionnaire
     */
    async profileQuestionnaireLoad(requestParameters: LundapadelProfileApiProfileQuestionnaireLoadRequest, initOverrides?: RequestInit): Promise<ProfileQuestionnaireLoadResponse> {
        const response = await this.profileQuestionnaireLoadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Save profile questionnaire
     */
    async profileQuestionnaireSaveRaw(requestParameters: LundapadelProfileApiProfileQuestionnaireSaveOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfileQuestionnaireSaveResponse>> {
        if (requestParameters.profileQuestionnaireSaveRequest === null || requestParameters.profileQuestionnaireSaveRequest === undefined) {
            throw new runtime.RequiredError('profileQuestionnaireSaveRequest','Required parameter requestParameters.profileQuestionnaireSaveRequest was null or undefined when calling profileQuestionnaireSave.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/questionnaires`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProfileQuestionnaireSaveRequestToJSON(requestParameters.profileQuestionnaireSaveRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileQuestionnaireSaveResponseFromJSON(jsonValue));
    }

    /**
     * Save profile questionnaire
     */
    async profileQuestionnaireSave(requestParameters: LundapadelProfileApiProfileQuestionnaireSaveOperationRequest, initOverrides?: RequestInit): Promise<ProfileQuestionnaireSaveResponse> {
        const response = await this.profileQuestionnaireSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recommended player
     */
    async recommendedPlayerRaw(requestParameters: LundapadelProfileApiRecommendedPlayerOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecommendedPlayerResponse>> {
        if (requestParameters.recommendedPlayerRequest === null || requestParameters.recommendedPlayerRequest === undefined) {
            throw new runtime.RequiredError('recommendedPlayerRequest','Required parameter requestParameters.recommendedPlayerRequest was null or undefined when calling recommendedPlayer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/recommended`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecommendedPlayerRequestToJSON(requestParameters.recommendedPlayerRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecommendedPlayerResponseFromJSON(jsonValue));
    }

    /**
     * Recommended player
     */
    async recommendedPlayer(requestParameters: LundapadelProfileApiRecommendedPlayerOperationRequest, initOverrides?: RequestInit): Promise<RecommendedPlayerResponse> {
        const response = await this.recommendedPlayerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Register player
     */
    async registerRaw(requestParameters: LundapadelProfileApiRegisterOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RegisterResponse>> {
        if (requestParameters.registerRequest === null || requestParameters.registerRequest === undefined) {
            throw new runtime.RequiredError('registerRequest','Required parameter requestParameters.registerRequest was null or undefined when calling register.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterRequestToJSON(requestParameters.registerRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterResponseFromJSON(jsonValue));
    }

    /**
     * Register player
     */
    async register(requestParameters: LundapadelProfileApiRegisterOperationRequest, initOverrides?: RequestInit): Promise<RegisterResponse> {
        const response = await this.registerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove subscriptions
     */
    async removeSubscriptionRaw(requestParameters: LundapadelProfileApiRemoveSubscriptionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.removeSubscriptionRequest === null || requestParameters.removeSubscriptionRequest === undefined) {
            throw new runtime.RequiredError('removeSubscriptionRequest','Required parameter requestParameters.removeSubscriptionRequest was null or undefined when calling removeSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/subscription-remove`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveSubscriptionRequestToJSON(requestParameters.removeSubscriptionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove subscriptions
     */
    async removeSubscription(requestParameters: LundapadelProfileApiRemoveSubscriptionOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.removeSubscriptionRaw(requestParameters, initOverrides);
    }

    /**
     * Save subscription
     */
    async saveSubscriptionRaw(requestParameters: LundapadelProfileApiSaveSubscriptionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SaveSubscriptionResponse>> {
        if (requestParameters.saveSubscriptionRequest === null || requestParameters.saveSubscriptionRequest === undefined) {
            throw new runtime.RequiredError('saveSubscriptionRequest','Required parameter requestParameters.saveSubscriptionRequest was null or undefined when calling saveSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/subscription-save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaveSubscriptionRequestToJSON(requestParameters.saveSubscriptionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SaveSubscriptionResponseFromJSON(jsonValue));
    }

    /**
     * Save subscription
     */
    async saveSubscription(requestParameters: LundapadelProfileApiSaveSubscriptionOperationRequest, initOverrides?: RequestInit): Promise<SaveSubscriptionResponse> {
        const response = await this.saveSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set favorite clubs to player profile
     */
    async saveUIGameSettingsRaw(requestParameters: LundapadelProfileApiSaveUIGameSettingsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.saveUIGameSettingsRequest === null || requestParameters.saveUIGameSettingsRequest === undefined) {
            throw new runtime.RequiredError('saveUIGameSettingsRequest','Required parameter requestParameters.saveUIGameSettingsRequest was null or undefined when calling saveUIGameSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/ui-game-settings-save`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SaveUIGameSettingsRequestToJSON(requestParameters.saveUIGameSettingsRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set favorite clubs to player profile
     */
    async saveUIGameSettings(requestParameters: LundapadelProfileApiSaveUIGameSettingsOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.saveUIGameSettingsRaw(requestParameters, initOverrides);
    }

    /**
     * Save profile rating filter settings
     */
    async saveUIRatingSettingsRaw(requestParameters: LundapadelProfileApiSaveUIRatingSettingsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.saveUIRatingSettingsRequest === null || requestParameters.saveUIRatingSettingsRequest === undefined) {
            throw new runtime.RequiredError('saveUIRatingSettingsRequest','Required parameter requestParameters.saveUIRatingSettingsRequest was null or undefined when calling saveUIRatingSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/ui-rating-settings-save`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SaveUIRatingSettingsRequestToJSON(requestParameters.saveUIRatingSettingsRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Save profile rating filter settings
     */
    async saveUIRatingSettings(requestParameters: LundapadelProfileApiSaveUIRatingSettingsOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.saveUIRatingSettingsRaw(requestParameters, initOverrides);
    }

    /**
     * Send code to phone number
     */
    async sendCodeRaw(requestParameters: LundapadelProfileApiSendCodeOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SendCodeResponse>> {
        if (requestParameters.sendCodeRequest === null || requestParameters.sendCodeRequest === undefined) {
            throw new runtime.RequiredError('sendCodeRequest','Required parameter requestParameters.sendCodeRequest was null or undefined when calling sendCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/send-code`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendCodeRequestToJSON(requestParameters.sendCodeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SendCodeResponseFromJSON(jsonValue));
    }

    /**
     * Send code to phone number
     */
    async sendCode(requestParameters: LundapadelProfileApiSendCodeOperationRequest, initOverrides?: RequestInit): Promise<SendCodeResponse> {
        const response = await this.sendCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set favorite clubs to player profile
     */
    async setFavoriteClubsRaw(requestParameters: LundapadelProfileApiSetFavoriteClubsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SetFavoriteClubsResponse>> {
        if (requestParameters.setFavoriteClubsRequest === null || requestParameters.setFavoriteClubsRequest === undefined) {
            throw new runtime.RequiredError('setFavoriteClubsRequest','Required parameter requestParameters.setFavoriteClubsRequest was null or undefined when calling setFavoriteClubs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/set-favorite-clubs`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetFavoriteClubsRequestToJSON(requestParameters.setFavoriteClubsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetFavoriteClubsResponseFromJSON(jsonValue));
    }

    /**
     * Set favorite clubs to player profile
     */
    async setFavoriteClubs(requestParameters: LundapadelProfileApiSetFavoriteClubsOperationRequest, initOverrides?: RequestInit): Promise<SetFavoriteClubsResponse> {
        const response = await this.setFavoriteClubsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Team search
     */
    async teamSearchRaw(requestParameters: LundapadelProfileApiTeamSearchOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamSearchResponse>> {
        if (requestParameters.teamSearchRequest === null || requestParameters.teamSearchRequest === undefined) {
            throw new runtime.RequiredError('teamSearchRequest','Required parameter requestParameters.teamSearchRequest was null or undefined when calling teamSearch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player/team-search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TeamSearchRequestToJSON(requestParameters.teamSearchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamSearchResponseFromJSON(jsonValue));
    }

    /**
     * Team search
     */
    async teamSearch(requestParameters: LundapadelProfileApiTeamSearchOperationRequest, initOverrides?: RequestInit): Promise<TeamSearchResponse> {
        const response = await this.teamSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
