/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseMetricView,
    BaseMetricViewFromJSON,
    BaseMetricViewFromJSONTyped,
    BaseMetricViewToJSON,
} from './BaseMetricView';
import {
    CreateInviteButtonMetricView,
    CreateInviteButtonMetricViewFromJSON,
    CreateInviteButtonMetricViewFromJSONTyped,
    CreateInviteButtonMetricViewToJSON,
} from './CreateInviteButtonMetricView';
import {
    CreateInviteMetricViewAllOf,
    CreateInviteMetricViewAllOfFromJSON,
    CreateInviteMetricViewAllOfFromJSONTyped,
    CreateInviteMetricViewAllOfToJSON,
} from './CreateInviteMetricViewAllOf';
import {
    DeviceSystem,
    DeviceSystemFromJSON,
    DeviceSystemFromJSONTyped,
    DeviceSystemToJSON,
} from './DeviceSystem';

/**
 * 
 * @export
 * @interface CreateInviteMetricView
 */
export interface CreateInviteMetricView {
    /**
     * 
     * @type {DeviceSystem}
     * @memberof CreateInviteMetricView
     */
    system?: DeviceSystem;
    /**
     * 
     * @type {CreateInviteButtonMetricView}
     * @memberof CreateInviteMetricView
     */
    button?: CreateInviteButtonMetricView;
}

export function CreateInviteMetricViewFromJSON(json: any): CreateInviteMetricView {
    return CreateInviteMetricViewFromJSONTyped(json, false);
}

export function CreateInviteMetricViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateInviteMetricView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'system': !exists(json, 'system') ? undefined : DeviceSystemFromJSON(json['system']),
        'button': !exists(json, 'button') ? undefined : CreateInviteButtonMetricViewFromJSON(json['button']),
    };
}

export function CreateInviteMetricViewToJSON(value?: CreateInviteMetricView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'system': DeviceSystemToJSON(value.system),
        'button': CreateInviteButtonMetricViewToJSON(value.button),
    };
}

