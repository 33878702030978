import Headling from '@/components/Headling/Headling';
import styles from './SubscriptionsSection.module.css';
import { Link } from 'react-router-dom';
import SeeMore from '@/components/SeeMore/SeeMore';
import { useEffect, useState } from 'react';
import { SubscriptionLoadListResult } from '@/integration-api/server-rest-lundapadelApi';
import { fetchSubscription } from './service';
import ActiveSubscriptionCard from '@/components/SubscriptionCard/components/ActiveSubscriptionCard/ActiveSubscriptionCard';
import ArrowIcon from '@/static/images/icons/ArrowIcon';

const SubscriptionsSection = () => {
  const [ subscriptions, setSubscriptions ] = useState<SubscriptionLoadListResult['active']>([]);
  
  useEffect(() => {
    fetchSubscription()
      .then(({ result }) => {setSubscriptions(result?.active)})
      .catch((e) => console.log(e))
  }, []);

  return (  
    <section className={styles['subscriptions']}>
      <div className={styles['headling']}>
        <div className={styles['info']}>
          <Headling appearence="big-normal" hasExpansion>Мои подписки на игры</Headling>
        </div>
        <Link to={'/subscriptions'}>
            {!!subscriptions?.length && <SeeMore>Все</SeeMore>}
        </Link>
      </div>
      {subscriptions?.length ? (
        <div className={styles['subscription-wrapper']}>
          {subscriptions.map((subscription) => (
            <Link to={`/subscriptions/${subscription.uid}`} key={subscription.uid}>
              <ActiveSubscriptionCard cardClassName={styles.subscriptionCard} subscription={subscription} />
            </Link>
          ))}
        </div>
      ) : (
        <div className={styles['empty-content-wrapper']}>
          Получайте уведомления на игры, которые вам интересны
          <br />
          <Link to="/subscriptions/create" className={styles.createLink}>Создать подписку <ArrowIcon stroke="#C3D82E" direction='rigth' /></Link>
        </div>
      )}
    </section>
  );
}
 
export default SubscriptionsSection;
