import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import { SubscriptionsPageProps } from './subscriptionsPage.interfaces';
import Header from '@/components/Header/Header';
import { useEffect, useState } from 'react';
import { deleteSubscription, fetchSubscription } from './service';
import { SubscriptionLoadListResult } from '@/integration-api/server-rest-lundapadelApi';
import ActiveSubscriptionCard from '@/components/SubscriptionCard/components/ActiveSubscriptionCard/ActiveSubscriptionCard';
import ExpiredSubscriptionCard from '@/components/SubscriptionCard/components/ExpiredSubscriptionCard/ExpiredSubscriptionCard';
import styles from './subscriptionsPage.module.css';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Button from '@/components/Button/Button';
import { Link, useNavigate } from 'react-router-dom';
import DeletableSubscriptionCard from '@/components/SubscriptionCard/components/DeletableSubscriptionCard/DeletableSubscriptionCard';
import FullscreenLoader from '@/components/FullscreenLoader/FullscreenLoader';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';
import CrossIcon from '@/static/images/icons/CrossIcon';
import { ErrorUtils } from '@/utils/utils';

const SubscriptionsPage: React.FC<SubscriptionsPageProps> = () => {
  const navigate = useNavigate();
 
  const [ subscriptions, setSubscriptions ] = useState<SubscriptionLoadListResult>({});
  const [isLoading, setIsLoading] = useState(false);

  const getSubsctiptions = () => {
    setIsLoading(true);

    return fetchSubscription()
      .then((subscriptions) => {
        if (subscriptions) setSubscriptions(subscriptions);
      })
      .catch((err) => {
        if(err instanceof Promise) {
          ErrorUtils.handleErrorMessage(err);
        }
      })
      .finally(() => setIsLoading(false));
  }

  const handleDeleteCard = (uid: string) => {
    deleteSubscription(uid)
      .then(() => {
        PopupMessage.open('Подписка удалена', 'success');
        getSubsctiptions();
      })
      .catch(err => {
        if(err instanceof Promise) {
          ErrorUtils.handleErrorMessage(err);
        }
      })
  }

  useEffect(() => {
    getSubsctiptions();
  }, []);

  return (
    <BasePageWrapper className={styles.wrap} contentWrapperClassName={styles.wrapContent}>
      <Header handleClick={() => navigate('/')}>
        Мои подписки на игры
      </Header>

      <div className={styles['blocks-wrapper']}>
        {!!subscriptions.active?.length && (
          <div className={styles.subscriptionList}>
            <p className={styles.activeSubscriptionTitle}>Активные</p>
            {subscriptions.active?.map((subscription) => (
              <DeletableSubscriptionCard onDelete={() => handleDeleteCard(subscription.uid!)} key={subscription.uid}>
                <Link to={subscription.uid!}>
                  <ActiveSubscriptionCard subscription={subscription}/>
                </Link>
              </DeletableSubscriptionCard>
            ))}
          </div>
        )}

        {!!subscriptions.inactive?.length && (
          <div className={styles.subscriptionList}>
            <p className={styles.expiredSubscriptionTitle}>Истекли</p>
            {subscriptions.inactive?.map((subscription) => (
              <DeletableSubscriptionCard onDelete={() => handleDeleteCard(subscription.uid!)} key={subscription.uid}>
                <Link to={`${subscription.uid!}/edit`}>
                  <ExpiredSubscriptionCard subscription={subscription}/>
                </Link>
              </DeletableSubscriptionCard>
            ))}
          </div>
        )}

        {(!subscriptions?.active?.length && !subscriptions?.inactive?.length) && <p className={styles.empty}>У вас нет подписок, создайте новую</p>}
      </div>
      <OversideWrapper className={styles.btnWrap}>
        <Link to="create">
          <Button>
            <div className={styles['button']}>
              <span><CrossIcon fill='var(--white)'/></span>
              <span>Создать подписку</span>
            </div>
          </Button>
        </Link>
      </OversideWrapper>

      <FullscreenLoader loading={isLoading} />
    </BasePageWrapper>
  );
}
 
export default SubscriptionsPage;
