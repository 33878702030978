import { FC, useEffect, useState } from 'react';
import NewsCard from '../NewsCard/NewsCard';
import { NewsSectionProps } from './NewSection.interfaces';
import styles from './NewSection.module.css';
import {
  Color,
  NewsItemInfo,
  NewsItemLoadListResponse,
} from '@/integration-api/server-rest-lundapadelApi';
import RestClient from '@/integration/RestClient';
import { FetchErrorMessage } from '@/types/types';
import useDocumentVisibility from '@/hooks/useDocumentVisibility';
import DBManager from '@/utils/DBManager/DBManager';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

const NewsSection: FC<NewsSectionProps> = (): JSX.Element => {
  const [newsList, setNewsList] = useState<Array<NewsItemInfo>>([]);
  useDocumentVisibility(loadNews);

  async function loadNews() {
    try {
      const { result }: NewsItemLoadListResponse =
        await RestClient.mainApi.newsItemLoadList({
          newsItemLoadListRequest: {
            parameters: {
              sortDesc: true,
            },
          },
        });
      if (result) {
        setNewsList(result.infos || []);
        DBManager.create('news', result.infos, 'newsList')
      }
    } catch (err: unknown) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    }
  };

  useEffect(() => {
    if(navigator.onLine){
      loadNews();
    } else{
      DBManager.read('news', 'newsList').then(newsList => {
        setNewsList(newsList)
      }).catch((err) => console.log(err))
    }
  }, []);

  return (
    <section className={styles['news']}>
      <div className={styles['card-wrapper']}>
        {newsList.map((item, index) => (
          <NewsCard
            key={item.uid}
            news={item}
            color={item.color || Color.GREEN}
            title={item.title}
            index={index}
          />
        ))}
      </div>
    </section>
  );
};

export default NewsSection;
