/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseMetricView,
    BaseMetricViewFromJSON,
    BaseMetricViewFromJSONTyped,
    BaseMetricViewToJSON,
} from './BaseMetricView';
import {
    PlayerProfileInfo,
    PlayerProfileInfoFromJSON,
    PlayerProfileInfoFromJSONTyped,
    PlayerProfileInfoToJSON,
} from './PlayerProfileInfo';

/**
 * 
 * @export
 * @interface CreateLineupParam
 */
export interface CreateLineupParam {
    /**
     * 
     * @type {string}
     * @memberof CreateLineupParam
     */
    gameUid?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLineupParam
     */
    lockToken?: string;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof CreateLineupParam
     */
    player1?: PlayerProfileInfo;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof CreateLineupParam
     */
    player2?: PlayerProfileInfo;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof CreateLineupParam
     */
    player3?: PlayerProfileInfo;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof CreateLineupParam
     */
    player4?: PlayerProfileInfo;
    /**
     * 
     * @type {BaseMetricView}
     * @memberof CreateLineupParam
     */
    metrics?: BaseMetricView;
}

export function CreateLineupParamFromJSON(json: any): CreateLineupParam {
    return CreateLineupParamFromJSONTyped(json, false);
}

export function CreateLineupParamFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLineupParam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gameUid': !exists(json, 'gameUid') ? undefined : json['gameUid'],
        'lockToken': !exists(json, 'lockToken') ? undefined : json['lockToken'],
        'player1': !exists(json, 'player1') ? undefined : PlayerProfileInfoFromJSON(json['player1']),
        'player2': !exists(json, 'player2') ? undefined : PlayerProfileInfoFromJSON(json['player2']),
        'player3': !exists(json, 'player3') ? undefined : PlayerProfileInfoFromJSON(json['player3']),
        'player4': !exists(json, 'player4') ? undefined : PlayerProfileInfoFromJSON(json['player4']),
        'metrics': !exists(json, 'metrics') ? undefined : BaseMetricViewFromJSON(json['metrics']),
    };
}

export function CreateLineupParamToJSON(value?: CreateLineupParam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gameUid': value.gameUid,
        'lockToken': value.lockToken,
        'player1': PlayerProfileInfoToJSON(value.player1),
        'player2': PlayerProfileInfoToJSON(value.player2),
        'player3': PlayerProfileInfoToJSON(value.player3),
        'player4': PlayerProfileInfoToJSON(value.player4),
        'metrics': BaseMetricViewToJSON(value.metrics),
    };
}

