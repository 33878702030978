import styles from './SelectedFormField.module.css';

interface SelectedFormFieldProps {
  title?: string;
  content?: string | React.ReactElement | Array<React.ReactElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

function SelectedFormField({ title, content, onClick }: SelectedFormFieldProps) {
  return (
    <div className={styles.flexItem} onClick={onClick}>
      <span className={styles.title}>{title}</span>
      <span className={styles.dates}>{content}</span>
    </div>
  );
}

export default SelectedFormField;
