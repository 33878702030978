/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdvertisementMetricRequest,
    AdvertisementMetricRequestFromJSON,
    AdvertisementMetricRequestToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ShareGameMetricRequest,
    ShareGameMetricRequestFromJSON,
    ShareGameMetricRequestToJSON,
    ShareProfileMetricRequest,
    ShareProfileMetricRequestFromJSON,
    ShareProfileMetricRequestToJSON,
    ShareQrMetricRequest,
    ShareQrMetricRequestFromJSON,
    ShareQrMetricRequestToJSON,
} from '../models';

export interface LundapadelMetricApiAdvertisementMetricOperationRequest {
    advertisementMetricRequest: AdvertisementMetricRequest;
}

export interface LundapadelMetricApiShareGameMetricOperationRequest {
    shareGameMetricRequest: ShareGameMetricRequest;
}

export interface LundapadelMetricApiShareProfileMetricOperationRequest {
    shareProfileMetricRequest: ShareProfileMetricRequest;
}

export interface LundapadelMetricApiShareQrMetricOperationRequest {
    shareQrMetricRequest: ShareQrMetricRequest;
}

/**
 * 
 */
export class LundapadelMetricApi extends runtime.BaseAPI {

    /**
     * Save advertisement metric
     */
    async advertisementMetricRaw(requestParameters: LundapadelMetricApiAdvertisementMetricOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.advertisementMetricRequest === null || requestParameters.advertisementMetricRequest === undefined) {
            throw new runtime.RequiredError('advertisementMetricRequest','Required parameter requestParameters.advertisementMetricRequest was null or undefined when calling advertisementMetric.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/metric/advertisement`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdvertisementMetricRequestToJSON(requestParameters.advertisementMetricRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Save advertisement metric
     */
    async advertisementMetric(requestParameters: LundapadelMetricApiAdvertisementMetricOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.advertisementMetricRaw(requestParameters, initOverrides);
    }

    /**
     * Save share game metric
     */
    async shareGameMetricRaw(requestParameters: LundapadelMetricApiShareGameMetricOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shareGameMetricRequest === null || requestParameters.shareGameMetricRequest === undefined) {
            throw new runtime.RequiredError('shareGameMetricRequest','Required parameter requestParameters.shareGameMetricRequest was null or undefined when calling shareGameMetric.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/metric/share-game`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShareGameMetricRequestToJSON(requestParameters.shareGameMetricRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Save share game metric
     */
    async shareGameMetric(requestParameters: LundapadelMetricApiShareGameMetricOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.shareGameMetricRaw(requestParameters, initOverrides);
    }

    /**
     * Save share profile metric
     */
    async shareProfileMetricRaw(requestParameters: LundapadelMetricApiShareProfileMetricOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shareProfileMetricRequest === null || requestParameters.shareProfileMetricRequest === undefined) {
            throw new runtime.RequiredError('shareProfileMetricRequest','Required parameter requestParameters.shareProfileMetricRequest was null or undefined when calling shareProfileMetric.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/metric/share-profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShareProfileMetricRequestToJSON(requestParameters.shareProfileMetricRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Save share profile metric
     */
    async shareProfileMetric(requestParameters: LundapadelMetricApiShareProfileMetricOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.shareProfileMetricRaw(requestParameters, initOverrides);
    }

    /**
     * Save share qr metric
     */
    async shareQrMetricRaw(requestParameters: LundapadelMetricApiShareQrMetricOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shareQrMetricRequest === null || requestParameters.shareQrMetricRequest === undefined) {
            throw new runtime.RequiredError('shareQrMetricRequest','Required parameter requestParameters.shareQrMetricRequest was null or undefined when calling shareQrMetric.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/metric/share-qr`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShareQrMetricRequestToJSON(requestParameters.shareQrMetricRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Save share qr metric
     */
    async shareQrMetric(requestParameters: LundapadelMetricApiShareQrMetricOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.shareQrMetricRaw(requestParameters, initOverrides);
    }

}
