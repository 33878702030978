import { DayOfWeek } from '@/integration-api/server-rest-lundapadelApi';

export const daysLabesShort = {
	[DayOfWeek.MONDAY]: 'ПН',
	[DayOfWeek.TUESDAY]: 'ВТ',
	[DayOfWeek.WEDNESDAY]: 'СР',
	[DayOfWeek.THURSDAY]: 'ЧТ',
	[DayOfWeek.FRIDAY]: 'ПТ',
	[DayOfWeek.SATURDAY]: 'СБ',
	[DayOfWeek.SUNDAY]: 'ВС',
} as const;

export const daysLabes = {
  [DayOfWeek.MONDAY]: 'Понедельник',
	[DayOfWeek.TUESDAY]: 'Вторник',
	[DayOfWeek.WEDNESDAY]: 'Среда',
	[DayOfWeek.THURSDAY]: 'Четверг',
	[DayOfWeek.FRIDAY]: 'Пятница',
	[DayOfWeek.SATURDAY]: 'Суббота',
	[DayOfWeek.SUNDAY]: 'Воскресенье',
} as const;

export const daySorter = {
  [DayOfWeek.MONDAY]: 0,
  [DayOfWeek.TUESDAY]: 1,
	[DayOfWeek.WEDNESDAY]: 2,
	[DayOfWeek.THURSDAY]: 3,
	[DayOfWeek.FRIDAY]: 4,
	[DayOfWeek.SATURDAY]: 5,
	[DayOfWeek.SUNDAY]: 6,
} as const;

export const daysEnum = Object.keys(daysLabes) as Array<DayOfWeek>;
