/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdvertisementMetricClickView,
    AdvertisementMetricClickViewFromJSON,
    AdvertisementMetricClickViewFromJSONTyped,
    AdvertisementMetricClickViewToJSON,
} from './AdvertisementMetricClickView';
import {
    AdvertisementMetricViewAllOf,
    AdvertisementMetricViewAllOfFromJSON,
    AdvertisementMetricViewAllOfFromJSONTyped,
    AdvertisementMetricViewAllOfToJSON,
} from './AdvertisementMetricViewAllOf';
import {
    BaseMetricView,
    BaseMetricViewFromJSON,
    BaseMetricViewFromJSONTyped,
    BaseMetricViewToJSON,
} from './BaseMetricView';
import {
    DeviceSystem,
    DeviceSystemFromJSON,
    DeviceSystemFromJSONTyped,
    DeviceSystemToJSON,
} from './DeviceSystem';
import {
    MetricScreenView,
    MetricScreenViewFromJSON,
    MetricScreenViewFromJSONTyped,
    MetricScreenViewToJSON,
} from './MetricScreenView';

/**
 * 
 * @export
 * @interface AdvertisementMetricView
 */
export interface AdvertisementMetricView {
    /**
     * 
     * @type {DeviceSystem}
     * @memberof AdvertisementMetricView
     */
    system?: DeviceSystem;
    /**
     * 
     * @type {MetricScreenView}
     * @memberof AdvertisementMetricView
     */
    screen?: MetricScreenView;
    /**
     * 
     * @type {AdvertisementMetricClickView}
     * @memberof AdvertisementMetricView
     */
    click?: AdvertisementMetricClickView;
}

export function AdvertisementMetricViewFromJSON(json: any): AdvertisementMetricView {
    return AdvertisementMetricViewFromJSONTyped(json, false);
}

export function AdvertisementMetricViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdvertisementMetricView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'system': !exists(json, 'system') ? undefined : DeviceSystemFromJSON(json['system']),
        'screen': !exists(json, 'screen') ? undefined : MetricScreenViewFromJSON(json['screen']),
        'click': !exists(json, 'click') ? undefined : AdvertisementMetricClickViewFromJSON(json['click']),
    };
}

export function AdvertisementMetricViewToJSON(value?: AdvertisementMetricView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'system': DeviceSystemToJSON(value.system),
        'screen': MetricScreenViewToJSON(value.screen),
        'click': AdvertisementMetricClickViewToJSON(value.click),
    };
}

