/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalDate,
    LocalDateFromJSON,
    LocalDateFromJSONTyped,
    LocalDateToJSON,
} from './LocalDate';

/**
 * 
 * @export
 * @interface SubscriptionInfo
 */
export interface SubscriptionInfo {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionInfo
     */
    uid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionInfo
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionInfo
     */
    title?: string;
    /**
     * 
     * @type {LocalDate}
     * @memberof SubscriptionInfo
     */
    startDate?: LocalDate;
    /**
     * 
     * @type {LocalDate}
     * @memberof SubscriptionInfo
     */
    endDate?: LocalDate;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionInfo
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionInfo
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionInfo
     */
    displayMinGrade?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionInfo
     */
    displayMaxGrade?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionInfo
     */
    countTourGame?: number;
}

export function SubscriptionInfoFromJSON(json: any): SubscriptionInfo {
    return SubscriptionInfoFromJSONTyped(json, false);
}

export function SubscriptionInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'startDate': !exists(json, 'startDate') ? undefined : LocalDateFromJSON(json['startDate']),
        'endDate': !exists(json, 'endDate') ? undefined : LocalDateFromJSON(json['endDate']),
        'startTime': !exists(json, 'startTime') ? undefined : json['startTime'],
        'endTime': !exists(json, 'endTime') ? undefined : json['endTime'],
        'displayMinGrade': !exists(json, 'displayMinGrade') ? undefined : json['displayMinGrade'],
        'displayMaxGrade': !exists(json, 'displayMaxGrade') ? undefined : json['displayMaxGrade'],
        'countTourGame': !exists(json, 'countTourGame') ? undefined : json['countTourGame'],
    };
}

export function SubscriptionInfoToJSON(value?: SubscriptionInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uid': value.uid,
        'active': value.active,
        'title': value.title,
        'startDate': LocalDateToJSON(value.startDate),
        'endDate': LocalDateToJSON(value.endDate),
        'startTime': value.startTime,
        'endTime': value.endTime,
        'displayMinGrade': value.displayMinGrade,
        'displayMaxGrade': value.displayMaxGrade,
        'countTourGame': value.countTourGame,
    };
}

