/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CreateInviteButtonMetricView {
    CIRCLE_BUTTON = 'CIRCLE_BUTTON',
    SQUARE_BUTTON = 'SQUARE_BUTTON'
}

export function CreateInviteButtonMetricViewFromJSON(json: any): CreateInviteButtonMetricView {
    return CreateInviteButtonMetricViewFromJSONTyped(json, false);
}

export function CreateInviteButtonMetricViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateInviteButtonMetricView {
    return json as CreateInviteButtonMetricView;
}

export function CreateInviteButtonMetricViewToJSON(value?: CreateInviteButtonMetricView | null): any {
    return value as any;
}

