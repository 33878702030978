import { Link, useOutletContext } from 'react-router-dom';
import { IGamePageContext } from '../../GamePage.interfaces';
import { createImgSrc } from '@/utils/utils';
import UserAvatar from '@/components/UserAvatar/UserAvatar';
import { PlayerProfileInfo } from '@/integration-api/server-rest-lundapadelApi';
import { useAppSelector } from '@/hooks/hooks';
import { isYourself } from '@/components/ProfileCard/utils';
import styles from './ChatUserList.module.css';

const ChatUserList = () => {
  const currentProfile = useAppSelector(({auth}) => auth.currentProfile);
  const { game } = useOutletContext<IGamePageContext>();

  const userList = new Array<PlayerProfileInfo | null>(game.playersLimit ?? 4).fill(null).map((_, index) => game.members?.[index]?.player);

  return (
    <div className={styles.userListWrap}>
      {userList.map((player, index) => {
        if (player) {
          const isMyAvatar = isYourself(currentProfile.identity?.uid, player?.uid);

          const avatarElement = (
            <UserAvatar
              key={player.uid}
              avatarUid={createImgSrc.playerProfile(player?.uid, player?.avatarUid)}
              nameInitials={player?.nameInitials}
              className={isMyAvatar ? styles['yourself-border'] : ''}
            />
          );

          return isMyAvatar ? avatarElement : <Link key={player.uid} to={`/profile/${player.uid}`}>{avatarElement}</Link>;
        } else {
          return <UserAvatar key={index} isInvitable />
        }
      })}
    </div>
  );
}
 
export default ChatUserList;
