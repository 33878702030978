import { BaseMetricView } from "@/integration-api/server-rest-lundapadelApi";
import RestClient from "@/integration/RestClient";

export function shareProfileMetric(parameters: BaseMetricView) {
  return RestClient.metricApi.shareProfileMetric({
    shareProfileMetricRequest: {
      parameters
    }
  })
}
