import styles from './PrivateTournamentDetails.module.css';
import Headling from "@/components/Headling/Headling";
import InnerPadding from '@/components/InnerPadding/InnerPadding';
import ToggleSwitch from "@/components/ToggleSwitch/ToggleSwitch";
import { PlayerProfileInfo } from '@/integration-api/server-rest-lundapadelApi';
import GamePlayerCarousel from '@/pages/application/game/components/GamePlayerCarousel/GamePlayerCarousel';
import CrossIcon from "@/static/images/icons/CrossIcon";
import { ChangeEvent } from 'react';

type PrivateTournamentDetailsProps = {
  privateTournament: boolean;
  invitations?: Array<PlayerProfileInfo>;
  setPrivateTournament: (event: ChangeEvent) => void;
  openInvitePage: VoidFunction;
  fullTournament: boolean;
}

const PrivateTournamentDetails = ({ privateTournament, invitations, fullTournament, setPrivateTournament, openInvitePage }: PrivateTournamentDetailsProps) => {
  return (  
    <InnerPadding className={styles['wrapper']}>
      <Headling appearence="big-normal">Детали турнира</Headling>
      <div className={styles['switcher']}>
        <span className={styles['switcher-text']}>Приватный турнир</span>
        <ToggleSwitch
          id="privateTournament"
          name="privateTournament"
          checked={privateTournament}
          onChange={setPrivateTournament}
        />
      </div>
      {privateTournament && !fullTournament ?
        <>        
          <div className={styles['invites']} onClick={openInvitePage}>
            <div>
              <CrossIcon fill='var(--green-yellow)'/>
            </div>
            <span>Пригласить игроков</span>
          </div> 
          {invitations ?
            <GamePlayerCarousel
              type='invintations'
              players={invitations}
              createForm
            /> : null
          }
        </> : null
      }
    </InnerPadding>
  );
}
 
export default PrivateTournamentDetails;
