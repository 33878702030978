import { SubscriptionCardProps } from '../../SubscriptionCard.interfaces';
import SubscriptionCard from '../../SubscriptionCard';
import styles from './ActiveSubscriptionCard.module.css';

interface ActiveSubscriptionCardProps extends SubscriptionCardProps {

}

const ActiveSubscriptionCard: React.FC<ActiveSubscriptionCardProps> = (props) => {
  const { subscription, cardClassName } = props;
  
  return (
    <SubscriptionCard subscription={subscription} cardClassName={[styles.active, cardClassName].join(' ')} />
  );
}
 
export default ActiveSubscriptionCard;
