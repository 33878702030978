import { FC } from "react";
import { UserAvatarProps } from "./UserAvatar.interfaces";
import styles from './UserAvatar.module.css';
import addGamer from '@/static/images/icons/tennis-racket-background-green.svg'
import transparentIcon from '@/static/images/icons/transparent-user-avatar.svg';
import UserAvatarBadge from './components/UserAvatarBadge';
import racoonGIF from '@/static/images/raccoon-dance.gif';
import { funnyAvatarHostname } from './utils';

const UserAvatar: FC<UserAvatarProps> = (props): JSX.Element => {
const { avatarUid, isInvitable, nameInitials, className = '', badge, isPlaceholder, ...restProps } = props

  return (
    <div {...restProps} className={styles.wrap}>
      {avatarUid && !avatarUid?.includes('=undefined') ? (
        <div className={`${className} ${styles['avatar']}`}>
          <img src={avatarUid} alt="ava" />
        </div>
      ) : nameInitials ?
          <div className={`${className} ${styles['avatar']} ${styles['initials']}`}>
            {/* {funnyAvatarHostname.includes(window.location.hostname) ? <img src={racoonGIF} alt="ava" /> : <span>{nameInitials}</span>} */}
            <span>{nameInitials}</span>
          </div>
        : isInvitable ?
          <div className={`${className} ${styles['avatar']}`}>
            <img src={transparentIcon} alt="invitable" />
          </div>
        : isPlaceholder ? 
          <div className={`${className} ${styles['avatar']}`}/>
        : <div className={`${className} ${styles['avatar']}`}>
            <img src={addGamer} alt="addUser" />
          </div>
      }
      {badge && (
        badge instanceof Array
          ? badge?.map((badge, index) => <UserAvatarBadge element={badge.element} position={badge.position} key={index} />)
          : <UserAvatarBadge element={badge.element} position={badge.position}/>
      )}
    </div>
  );
}

export default UserAvatar;
