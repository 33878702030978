import { NotificationMetricView } from "@/integration-api/server-rest-lundapadelApi";
import RestClient from "@/integration/RestClient"

export const newsItemLoad = (uid: string, metrics: NotificationMetricView) => {
  return RestClient.mainApi.newsItemLoad({
    newsItemLoadRequest: {
      parameters: {
        uid, 
        metrics
      }
    }
  });
}
