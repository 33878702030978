import styles from './ListRestBage.module.css';

type ListRestBageProps = {
  count: number
}

const ListRestBage = ({ count }: ListRestBageProps) => {
  return (  
    <div className={styles['count']}>
      +{count}
    </div>
  );
}
 
export default ListRestBage;
