import { useCallback, useMemo, useState } from 'react';
import styles from './GameListCard.module.css';
import { CalculatedGameStatus, GameInviteInfo, InvitationStatus, PlayerProfileInfo, Role } from '@/integration-api/server-rest-lundapadelApi';
import ClockIcon from '@/static/images/icons/ClockIcon';
import formatter from '@/utils/utils';
import { calculateEndTime } from '@/pages/application/create/components/MatchResultSettings/utils';
import { TIME_FORMAT_HH_MM } from '@/utils/constants';
import LocationIcon from '@/static/images/icons/LocationIcon';
import LockIcon from '@/static/images/icons/LockIcon';
import CommnentIcon from '@/static/images/icons/CommentIcon';
import ProfileCard from '../ProfileCard/ProfileCard';
import RatingBage from '../Badge/RatingBage/RatingBage';
import PlaceholderCard from '../PlaceholderCard/PlaceholderCard';
import CoachWhistleFilled from '@/static/images/icons/CoachWhistleFilled';
import { useNavigate } from 'react-router-dom';
import GameCountBage from '../Badge/GameCountBage/GameCountBage';
import { useAppSelector } from '@/hooks/hooks';
import RequestToJoinSent from '@/static/images/icons/RequestToJoinSent';
import GameJoiningCard from '@/components/GameJoiningCard/GameJoiningCard';
import { acceptInviteForGame, gameLoad } from '@/pages/application/game/service';
import { FetchErrorMessage } from '@/types/types';
import { GameListCardProps } from './GameListCard.interfaces';
import PrivateGameIcon from '@/static/images/icons/PrivateGameIcon';
import { canUserInvite } from '@/pages/application/game/utils';
import { fillPrivateEye, translateGameTournamentKind } from './utils';
import GameJoiningMultipleList from '../GameJoiningMultipleList/GameJoiningMultipleList';
import BallIcon from '@/static/images/icons/BallIcon';
import { PopupMessage } from '../PopupMessage/PopupMessage';

const GameListCard = ({ game, subtype, linkForbidden, className, ...props }: GameListCardProps) => {
  const current = useAppSelector(state => state.auth.currentProfile);
  const navigate = useNavigate();
  const [players, setPlayers] = useState<Array<PlayerProfileInfo>>((game.players?.length! === game.playersLimit!) ? game.players?.slice(0, 4) ?? [] : game.players?.slice(0, 3) ?? []);
  const [adequateInvintaions, setAdequateInvintations] = useState<Array<GameInviteInfo>>(game.invitations?.filter(inv => inv.sender?.uid === inv.player?.uid && inv.invitationStatus === InvitationStatus.SENT) ?? []);
  
  const addAppSubmitted = useMemo(() => {
    return !!game.invitations?.find(player => (player.sender?.uid === current.identity?.uid) && (player.player?.uid === current.identity?.uid));
  }, [game.invitations, current.identity?.uid]); 

  const acceptHandler = useCallback(async (playerUid: string) => {
    try {
      await acceptInviteForGame({ accepted: true, gameUid: game.uid, playerUid });
      const { result } = await gameLoad({ uid: game.uid });
      if(result) {
        if(result.members?.length === result.playersLimit) {
          setAdequateInvintations([]);
          setPlayers(result.members?.reduce<Array<PlayerProfileInfo>>((acc, current, i) => i < 4 ? acc.concat(current.player ?? {}) : acc, []) ?? []);
        } else if(result.members?.length! < 4) {
          setPlayers(result.members?.map(member => member.player ?? {}) ?? []);
          setAdequateInvintations(result.invitations?.filter(inv => inv.sender?.uid === inv.player?.uid) ?? []);
        } else {
          setAdequateInvintations(result.invitations?.filter(inv => inv.sender?.uid === inv.player?.uid) ?? []);
        }
      }
    } catch(err) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    }
  }, [adequateInvintaions, players]);

  const declineHandler = useCallback(async (playerUid: string) => {
    try {
      await acceptInviteForGame({ accepted: false, gameUid: game.uid, playerUid });
      const { result } = await gameLoad({ uid: game.uid });
      if(result) {
        if(result.members?.length === result.playersLimit) {
          setAdequateInvintations([]);
          setPlayers(result.members?.reduce<Array<PlayerProfileInfo>>((acc, current, i) => i < 4 ? acc.concat(current.player ?? {}) : acc, []) ?? []);
        } else if(result.members?.length! < 4) {
          setPlayers(result.members?.map(member => member.player ?? {}) ?? []);
          setAdequateInvintations(result.invitations?.filter(inv => inv.sender?.uid === inv.player?.uid) ?? []);
        } else {
          setAdequateInvintations(result.invitations?.filter(inv => inv.sender?.uid === inv.player?.uid) ?? []);
        }
      }
    } catch(err) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    }
  }, [adequateInvintaions]);

  const renderPlayers = () => {
    const playersArray: Array<JSX.Element> = [];
    for(let i = 0; i < 4; i++) {
      if(players?.[i]) {
        playersArray.push((
          <ProfileCard
            key={i}
            player={players[i]}
            orange={subtype === 'orange'}
            fullName
            noPreferedSide={!!subtype}
            bage={[
              {
                element: i == 3 && game.playersLimit! - 4 > 0 ? <></> : <RatingBage rating={players[i].displayRating ?? '1.0'}/>,
                position: 'top-right'
              },
              {
                element: players[i].roles?.includes(Role.COACH) ? <CoachWhistleFilled /> : <></>,
                position: 'top-left'
              },
              {
                element: i == 3 && game.playersLimit! - 4 > 0 ? <GameCountBage playersLimit={game.playersLimit!}/> : <></>,
                position: 'middle-center' 
              }
            ]}
          />
        ))
      } else {
        playersArray.push((
          <PlaceholderCard
            key={i}
            type={(!subtype || subtype === 'orange') && canUserInvite(game, current.identity?.uid!) && (game.gameStatus === CalculatedGameStatus.PLANNED || (game.gameStatus === CalculatedGameStatus.STARTED && game.players?.length! < 4 )) ? 'invite' : 'enter'}
            orange={subtype === 'orange'}
            bage={{
              element: i === 3 && game.playersLimit! - 4 > 0 ? <GameCountBage playersLimit={game.playersLimit!}/> : <></>,
              position: 'middle-center' 
            }}
          />
        ))
      }
    }
    return playersArray;
  }

  const renderJoining = () => {
    return adequateInvintaions?.length ? (adequateInvintaions.length <= 2 ? (
      <div className={styles['joining']}>
        {adequateInvintaions?.map(inv => (
          <GameJoiningCard
            key={inv.player?.uid}
            player={inv.player ?? {}}
            acceptHandler={uid => acceptHandler(uid ?? '')}
            declineHandler={uid => declineHandler(uid ?? '')}
          />))
        }
      </div> 
    ) : (
      <GameJoiningMultipleList
        pool={adequateInvintaions.map(inv => inv.player ?? {})}
        id={game.uid}
      />
    )) : null;
  }

  const navigateHandler = () => {
    if(linkForbidden) {
      return;
    }
    navigate(`/game/${game.uid}` );
  }

  return (  
    <div {...props} className={`${className ?? ''} ${styles['card-wrapper']} ${subtype ? styles[subtype] : ''}`.trim()}>
      {addAppSubmitted ? 
        <div className={styles['request-sent']}>
          <RequestToJoinSent/> 
        </div>
      : null}
      {game.privateTourGame && subtype !== 'green' ?
        <div className={styles['private-game']}>
          <PrivateGameIcon fill={fillPrivateEye(subtype)}/>
        </div> : null
      }
      <div className={styles['card']} onClick={navigateHandler}>
        <div className={styles['card-field']}>
          <div className={styles['icon']}>
            <ClockIcon/>
          </div>
          <span>
            {formatter.formatDateToDayDateMonth(game.plannedDate)} | {formatter.formatDate(game.plannedDate!, TIME_FORMAT_HH_MM)} 
            {calculateEndTime(formatter.formatDate(game.plannedDate!, TIME_FORMAT_HH_MM), game.duration!)}
          </span>
        </div>
        {!subtype || subtype === 'orange' || subtype === 'blue' ?
          <div className={styles['rating']}>
            {game.courtBooked ? 
              <div className={styles['rating-item']}>
                <LockIcon/>
              </div> : null
            }
            <div className={styles['rating-item']}>
              {game.displayMinGrade === game.displayMaxGrade ?
                <span>{game.displayMinGrade}</span> :
                <span>{game.displayMinGrade}...{game.displayMaxGrade}</span>
              }
            </div>
          </div> :
          <div className={styles['time']}>
            {formatter.relativeDate(game.plannedDate ?? '-')}
          </div>
        }
        <div className={styles['card-field']}>
          <div className={styles['icon']}>
            <LocationIcon />
          </div>
          {!subtype || subtype === 'orange' || subtype === 'blue' ?
            <span>{game.club?.caption}</span> :
            <div className={styles['club-extended']}>
              <span>{game.club?.caption}</span>
              <span className={styles['status']}>
                {subtype === 'green' ? 'Счет выставлен' : subtype === 'gray' ? 'Счет не выставлен' : 'Отменен'}
              </span>
            </div>
          }
        </div>
        {game.gameTournamentKind ?
          <div className={styles['card-field']}>
            <div className={styles['icon']}>
              <BallIcon />
            </div>
            <span>{translateGameTournamentKind(game.gameTournamentKind)}</span>
          </div> : null
        }
        {game.description ?
          <div className={styles['card-field']}>
            <div>
              <CommnentIcon />
            </div>
            <div className={styles['comment']}>{game.description}</div>
          </div>
          : null
        }
        <div className={styles['players']}>
          {renderPlayers()}
        </div>
      </div>
      {current.identity?.uid === game.owner?.uid ? 
        <>
          {renderJoining()} 
        </>: null
      }
    </div>
  );
}
 
export default GameListCard;
