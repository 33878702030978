/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubscriptionInfo,
    SubscriptionInfoFromJSON,
    SubscriptionInfoFromJSONTyped,
    SubscriptionInfoToJSON,
} from './SubscriptionInfo';

/**
 * 
 * @export
 * @interface SubscriptionLoadListResult
 */
export interface SubscriptionLoadListResult {
    /**
     * 
     * @type {Array<SubscriptionInfo>}
     * @memberof SubscriptionLoadListResult
     */
    active?: Array<SubscriptionInfo>;
    /**
     * 
     * @type {Array<SubscriptionInfo>}
     * @memberof SubscriptionLoadListResult
     */
    inactive?: Array<SubscriptionInfo>;
}

export function SubscriptionLoadListResultFromJSON(json: any): SubscriptionLoadListResult {
    return SubscriptionLoadListResultFromJSONTyped(json, false);
}

export function SubscriptionLoadListResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionLoadListResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : ((json['active'] as Array<any>).map(SubscriptionInfoFromJSON)),
        'inactive': !exists(json, 'inactive') ? undefined : ((json['inactive'] as Array<any>).map(SubscriptionInfoFromJSON)),
    };
}

export function SubscriptionLoadListResultToJSON(value?: SubscriptionLoadListResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active === undefined ? undefined : ((value.active as Array<any>).map(SubscriptionInfoToJSON)),
        'inactive': value.inactive === undefined ? undefined : ((value.inactive as Array<any>).map(SubscriptionInfoToJSON)),
    };
}

