import { SaveSubscriptionRequest } from '@/integration-api/server-rest-lundapadelApi';
import RestClient from '@/integration/RestClient';

export function saveSubscription(parameters: SaveSubscriptionRequest['parameters']) {
  return RestClient.profileApi.saveSubscription({
    saveSubscriptionRequest: {
      parameters
    }
  })
}

export function getSubscription(uid: string) {
  return RestClient.profileApi.loadSubscription({
    uid
  })
}
