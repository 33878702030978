import styles from './TournamentResultsLineups.module.css';
import Header from "@/components/Header/Header";
import { Fragment, useCallback, useContext } from "react";
import { TournamentResultsNewContext } from "../utils";
import LineupCard from "../../../../components/LineupCard/LineupCard";
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Button, { ButtonVariants } from '@/components/Button/Button';
import { FastGameView, GameRoundStatus, QuickGameView, RoundStatus } from '@/integration-api/server-rest-lundapadelApi';
import { gameRoundSave, saveTournamentRound } from '../service';
import { useNavigate } from 'react-router-dom';
import PuffLoader from 'react-spinners/PuffLoader';
import { FetchErrorMessage } from '@/types/types';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';
import { GameResultsContext } from '../../game/GameResults/utils';
import { getPlatform } from '@/utils/utils';

const TournamentResultsLineups = () => {
  const { loading, roundNumber, round, tournamentType, updateStep, updateActiveLineup, updateRound, setLoading, roundCounter } = useContext(TournamentResultsNewContext);
  const { lockToken } = useContext(GameResultsContext);
  const navigate = useNavigate();
  
  const lineupClickHandler = useCallback((lineup: QuickGameView | FastGameView, index1: number, index2: number) => {
    updateActiveLineup?.({
      lineup,
      indexes: { index1, index2 }
    });
    updateStep?.('score');
  }, [updateActiveLineup, updateStep]);
  
  const handleClick = useCallback((header?: boolean) => {
    if(round?.roundNumber === 0 && round.status === RoundStatus.STARTED && header) {
      navigate(-1);
    } else {
      updateActiveLineup?.(undefined);
      updateRound?.(undefined);
      updateStep?.('rounds');
    }
  }, [navigate, updateActiveLineup, updateRound, updateStep, round?.roundNumber, round?.status]);

  const resultsClickHandler = useCallback(async () => {
    try {
      setLoading(true);
      const request = tournamentType === 'mini' ?
        gameRoundSave({ ...round, gameUid: round?.tournamentUid, status: GameRoundStatus.FINISHED, lockToken, metrics: { system: getPlatform() } }) :
        saveTournamentRound({ ...round, status: RoundStatus.FINISHED })
      const { result } = await request;
      if(result) {
        handleClick();
      }
    } catch(err) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open?.(userErrorMessage ?? errorMessage);
      }
    } finally {
      setLoading(false);
    }
  }, [setLoading, handleClick, tournamentType, lockToken]);

  const renderLineups = () => {
    let actualIndex = 0;
    return round?.lineups?.map((lineup, index) => {
      return lineup.games?.map((game, i) => {
        actualIndex++;
        return (
          <Fragment key={`${index}_${i}`}>
            {!i ? <div className={styles['court-number']} style={{ marginTop: !index ? '0' : '24px' }}>{index + 1} корт</div> : null}
            <LineupCard
              type={!game.score1 && !game.score2 ? 'edit' : 'tournament'}
              stickyBorderTop='-20px'
              index={actualIndex}
              lineup={{ 
                player1: game.player1,
                player2: game.player2,
                player3: game.player3,
                player4: game.player4,
                score1: game.score1,
                score2: game.score2
              }}
              onClick={round.roundNumber! + 1 === roundCounter ? () => lineupClickHandler(game, index, i) : undefined}
            />
          </Fragment>
        );
      })
    });
  }

  return ( 
    <>
      <Header className={styles['header']} handleClick={() => handleClick(true)}>
        Составы команд
      </Header>
      {loading ?
        <PuffLoader color='#4EB857' cssOverride={{margin: 'auto'}} />:
        <>        
          <div className={styles['wrapper']}>
            <div className={styles['round']}>{roundNumber + 1} Раунд</div>
            <div className={styles['lineups-wrapper']}>
              {renderLineups()}
            </div>
          </div>
          {round?.status === RoundStatus.STARTED || round?.roundNumber! + 1 === roundCounter ?
            <OversideWrapper className={styles['button']}>
              <Button 
                disabled={!round?.allGameResultSet || (tournamentType === 'mini' && !lockToken)}
                variant={ButtonVariants.YELLOW}
                onClick={resultsClickHandler}
              >
                Выставить результаты раунда
              </Button>
            </OversideWrapper> : null
          }
        </>
      }
    </>
  );
}
 
export default TournamentResultsLineups;
