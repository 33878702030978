import dayjs from 'dayjs';
import { Calendar, Clock, MapPoint, Сourt } from '../Icon/icons';
import { SubscriptionCardProps } from './SubscriptionCard.interfaces';
import styles from './SubscriptionCard.module.css';
import CountCircle from '../CountCircle/CountCircle';

const SubscriptionCard: React.FC<SubscriptionCardProps> = (props) => {
  const { subscription, cardClassName } = props;

  return (
    <div className={[styles.card, cardClassName].join(' ')}>
      <span><MapPoint className={styles.icon}/>{subscription.title}</span>
      <span><Сourt className={styles.icon}/>{subscription.displayMinGrade}...{subscription.displayMaxGrade}</span>
      <span><Calendar className={styles.icon}/>{subscription.startDate ? dayjs(subscription.startDate).format('DD.MM') : ''} - {subscription.endDate ? dayjs(subscription.endDate).format('DD.MM') : ''}</span>
      <span><Clock className={styles.icon}/>{subscription.startTime?.slice(0, 5)} - {subscription.endTime?.slice(0, 5)}</span>
      {subscription.countTourGame ? 
        <CountCircle 
          children={subscription.countTourGame}
          className={styles['countCircle']}  
        /> 
      : null}
    </div>
  );
}
 
export default SubscriptionCard;
