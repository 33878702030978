import { FC, useCallback, useEffect, useState } from "react";
import styles from './InvitesSection.module.css';
import Headling from "@/components/Headling/Headling";
import CountCircle from "@/components/CountCircle/CountCircle";
import { Link, useNavigate } from "react-router-dom";
import SeeMore from "@/components/SeeMore/SeeMore";
import { InvitationStatus, InviteInfo, InviteLoadListResult, InviteRelatedEntityType } from "@/integration-api/server-rest-lundapadelApi";
import { FetchErrorMessage } from "@/types/types";
import useDocumentVisibility from '@/hooks/useDocumentVisibility';
import { acceptInviteForGame, loadListInvite } from "@/pages/application/game/service";
import GameCardListInvite from "@/components/GameListCard/GameListCardInvite";
import TournamentInviteCard from "@/components/TournamentCard/TournamentInviteCard";
import { acceptInviteForTournament } from "@/pages/application/tournament/service";
import { InviteActionStatus } from "@/pages/application/invites/InvitePage.interfaces";
import { PopupMessage } from "@/components/PopupMessage/PopupMessage";

const InvitesSection = (): JSX.Element => {
  const [invitesInfo, setInvitesInfo] = useState<InviteLoadListResult>();
  const navigate = useNavigate();
  useDocumentVisibility(loadInvitesList)

  const handleAction = useCallback(async (type: InviteRelatedEntityType, actionStatus: InviteActionStatus, invite?: InviteInfo) => {
    try {
      if(type === InviteRelatedEntityType.GAME) {
        await acceptInviteForGame({
          gameUid: invite?.relatedEntityUid,
          playerUid: invite?.player?.uid, 
          accepted: actionStatus === InvitationStatus.ACCEPTED ? true : false
        });
        if (actionStatus === InvitationStatus.ACCEPTED) {
          navigate(`/game/${invite?.relatedEntityUid}`);
        } else if (actionStatus === InvitationStatus.REJECTED) {
          loadInvitesList();
        }
      } else {
        await acceptInviteForTournament({
          accepted: actionStatus === InvitationStatus.ACCEPTED ? true : false,
          tournamentUid: invite?.relatedEntityUid
        });
        if (actionStatus === InvitationStatus.ACCEPTED) {
          navigate(`/tournament/${invite?.relatedEntityUid}`);
        } else if (actionStatus === InvitationStatus.REJECTED) {
          loadInvitesList();
        }
      }       
    } catch(err: unknown) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    }
  }, []);

  const renderInvites = () => {
    return invitesInfo?.infos?.length ? invitesInfo.infos.map(invite => {
      if(invite.relatedEntityType === InviteRelatedEntityType.GAME) {
        return (
          <GameCardListInvite
            key={invite.relatedEntityUid}
            invite={invite}
            acceptInvite={() => handleAction(InviteRelatedEntityType.GAME, InvitationStatus.ACCEPTED, invite)}
            rejectInvite={() => handleAction(InviteRelatedEntityType.GAME, InvitationStatus.REJECTED, invite)}
          />
        );
      } else {
        return (
          <TournamentInviteCard
            key={invite.relatedEntityUid}
            invite={invite}
            acceptInvite={() => handleAction(InviteRelatedEntityType.TOURNAMENT, InvitationStatus.ACCEPTED, invite)}
            rejectInvite={() => handleAction(InviteRelatedEntityType.TOURNAMENT, InvitationStatus.REJECTED, invite)}
          />
        );
      }
    }) : null
  }

  function loadInvitesList() {
    loadListInvite({ limit: 2 })
      .then(({ result }) => {
        setInvitesInfo(result)
      })
      .catch(err => {
        if(err instanceof Promise) {
          err.then(err => {
            const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
            PopupMessage.open(userErrorMessage ?? errorMessage);
          })
        }
      })
  }

  useEffect(() => {
    loadInvitesList()
  }, []);

  return (
    <>
      {invitesInfo && !!invitesInfo?.total &&
        <section className={styles['invites']}>
          <div className={styles['headling']}>
            <div className={styles['info']}>
              <Headling appearence="big-normal" hasExpansion>Приглашения</Headling>
              <CountCircle>{invitesInfo?.total ?? 0}</CountCircle>
            </div>
            <Link to={'/invites'}>
              <SeeMore>Все</SeeMore>
            </Link>
          </div>
          <div className={styles['wrapper']}>
            {renderInvites()}
          </div>
        </section>
      }
    </>
  );
}

export default InvitesSection;
