import { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { ChatInfo, GameView } from "@/integration-api/server-rest-lundapadelApi";
import { useImmer } from 'use-immer';
import { GamePageLoadingState } from './GameStatusContent/GameStatusContent.interfaces';
import { FetchErrorMessage } from '@/types/types';
import { gameLoad, getGameChats } from "./service";
import DBManager from "@/utils/DBManager/DBManager";
import { IGamePageContext } from "./GamePage.interfaces";
import { PopupMessage } from "@/components/PopupMessage/PopupMessage";

const GamePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [loading, setLoading] = useImmer<GamePageLoadingState>({
    initial: false,
    handler: false,
  });
  const [game, setGame] = useState<GameView>({});
  const [gameChats, setGameChats] = useState<ChatInfo[]>([]);

  const goBackHandler = () => {
    if (
      window.location.search.includes('afterCreation') ||
      window.location.search.includes('redirect') ||
      window.location.search.includes('fromChat') ||
      window.history.length === 1
    ) {
      return () => navigate('/play');
    } else if (sessionStorage.getItem('lastPaths')) { // когда страницу игры открывают по пуш уведомлению, кнопка назад не работает
      try {
        const lastPathsLength = (JSON.parse(sessionStorage.getItem('lastPaths') as string) as Array<string>);
        if (lastPathsLength[0].length === 42) return () => navigate('/my-games?tab=planned');
      } catch (err) {
        console.log(err);
      }
    }
  };

  const loadGame = useCallback(() => {
    if(id) {
      if(navigator.onLine) {
        gameLoad({ uid: id })
          .then(({ result }) => {
            if(result) {
              setGame(result);
              DBManager.create('games', result, id);
              setLoading(state => { state.initial = false });
            }
          })
          .catch(err => {
            if(err instanceof Promise) {
              err.then(err => {
                const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
                PopupMessage.open?.(userErrorMessage ?? errorMessage);
              });
            }
          });
        getGameChats(id)
        .then(({ result }) => {
          setGameChats(result?.infos ?? []);
        })
        .catch(e => console.log(e))
      } else {
        DBManager.read('games', id)
          .then(game => {
            setGame(game)
          })
          .catch((err) => console.log(err))
          .finally(() => setLoading(state => { state.initial = false }));
      }
    }
  }, [id])

  useEffect(() => {
    const isLoadNotRequired = pathname.match(/(?:\b|')(join-via-qrcode|result|settings|invitations)(?:\b|')/gm);
    if(!isLoadNotRequired) {
      setLoading(state => { state.initial = true });
      loadGame();
    }
  }, [id, pathname]);

  return (  
    <Outlet context={{
        game,
        initialLoading: loading.initial,
        handlerLoading: loading.handler,
        setHandlerLoading: setLoading,
        updateGame: loadGame,
        goBackHandler,
        gameChats,
      } satisfies IGamePageContext
    }/>
  );
}
 
export default GamePage;
