import { FC, useState } from "react";
import QRCode from "react-qr-code";
import styles from './QrCode.module.css'
import { createPortal } from "react-dom";
import QrCodeIcon from "@/static/images/icons/QrCodeIcon";

export interface QrCodeProps {
  value: string
}
const QrCode: FC<QrCodeProps> = ({ value }) => {
  const [showQrCode, setShowQrCode] = useState<boolean>(false)
  return (
    <div>
      <div onClick={() => setShowQrCode(!showQrCode)}>
        <QrCodeIcon/>
      </div>
      {showQrCode ?
        createPortal(
          <div className={styles.qrModalBg} onClick={() => setShowQrCode(!showQrCode)}>
            <div className={styles.qrModalContent}>
              <QRCode 
                value={value} 
                size={215} 
                className={styles.qrLarge} 
                onClick={(e)=>e.stopPropagation()}

              />
              <div className={styles.text}>
                Отсканируйте qr-код камерой телефона для моментального попадания в игру
              </div>
            </div>
          </div>,
          document.body
        ) : null
      }
    </div>

  );
}

export default QrCode
