/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubscriptionView,
    SubscriptionViewFromJSON,
    SubscriptionViewFromJSONTyped,
    SubscriptionViewToJSON,
} from './SubscriptionView';

/**
 * 
 * @export
 * @interface SaveSubscriptionRequest
 */
export interface SaveSubscriptionRequest {
    /**
     * 
     * @type {SubscriptionView}
     * @memberof SaveSubscriptionRequest
     */
    parameters?: SubscriptionView;
}

export function SaveSubscriptionRequestFromJSON(json: any): SaveSubscriptionRequest {
    return SaveSubscriptionRequestFromJSONTyped(json, false);
}

export function SaveSubscriptionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaveSubscriptionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : SubscriptionViewFromJSON(json['parameters']),
    };
}

export function SaveSubscriptionRequestToJSON(value?: SaveSubscriptionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': SubscriptionViewToJSON(value.parameters),
    };
}

