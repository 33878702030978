import Header from '@/components/Header/Header';
import styles from './GameCreatePage.module.css';
import { useAppSelector } from '@/hooks/hooks';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ClubSelectionPage from '../ClubSelectionPage/ClubSelectionPage';
import { FormikErrors, useFormik } from 'formik';
import { GameFormValues } from './GameCreatePage.interfaces';
import { CalculatedGameStatus, ClubView, CountPlayerMetricView, GameRole, Grade, InvitationStatus, LocalDateTime, MetricScreenView, Role, toLocalDate, toLocalDateTime } from '@/integration-api/server-rest-lundapadelApi';
import { loadClubs } from '../../services';
import HLine from '@/components/HLine/HLine';
import SelectDate from '../components/SelectDate/SelectDate';
import SelectTime from '../components/SelectTime/SelectTime';
import SelectClubSection from '../components/SelectClubSection/SelectClubSection';
import SelectRating from '../components/SelectRating/SelectRating';
import MatchResultSettings from '../components/MatchResultSettings/MatchResultSettings';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Button from '@/components/Button/Button';
import MatchDetails from '../components/MatchDetails/MatchDetails';
import MatchDurationRadio from '../components/MatchDurationRadio/MatchDurationRadio';
import { getPlannedDate } from '../utils';
import { FetchErrorMessage } from '@/types/types';
import { gameBookedListTime, gameSave, gameTimeBooked } from '../service';
import TimeWarningModal from '../components/TimeWarningModal/TimeWarningModal';
import GamePlayersFormItem from '../components/GamePlayersFormItem/GamePlayersFormItem';
import MatchSearchPage from '../../match/MatchSearchPage/MatchSearchPage';
import { gameInitialValues, isWithinDay, loopThourghErrors } from '../utils';
import { createInviteListForGame, gameLoad, ownerEnterGame } from '../../game/service';
import CreateTabs from '../components/CreateTabs/CreateTabs';
import dayjs from 'dayjs';
import { PuffLoader } from 'react-spinners';
import CrossIcon from '@/static/images/icons/CrossIcon';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';
import { getPlatform } from '@/utils/utils';

const GameCreatePage = () => {
  const current = useAppSelector(state => state.auth.currentProfile);
  const navigate = useNavigate();
  const { id } = useParams();

  const [searchPageVisible, setSearchPageVisible] = useState(false);
  const [clubsPageVisible, setClubsPageVisisble] = useState(false);
  const [clubList, setClubList] = useState<Array<ClubView>>();
  const [errorField, setErrorField] = useState<string>();
  const [bookedTimes, setBookedTimes] = useState<Array<LocalDateTime>>([]);
  const [waringModal, setWarningModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [gameDate, setGameDate] = useState<LocalDateTime>();

  const clubRef = useRef<HTMLDivElement>(null);
  const timeRef = useRef<HTMLDivElement>(null);
  const kindRef = useRef<HTMLDivElement>(null);

  const formik = useFormik<GameFormValues>({
    initialValues: gameInitialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validate: values => {
      const errors: FormikErrors<GameFormValues> = {};
      if (!values.plannedDate.time) {
        scrollToError(timeRef)
        errors.plannedDate = {
          time: 'Выберите время!'
        }
        setErrorField('time');
      }
      if(!values.club) {
        scrollToError(clubRef);
        errors.club = 'Выберите клуб!';
        setErrorField('club');
      }
      if(values.playersLimit === 8 && !values.gameTournamentKind) {
        scrollToError(kindRef);
        errors.gameTournamentKind = 'Выберите тип турнира!';
        setErrorField('kind');
      }
      loopThourghErrors(errors);
      return errors;
    },
    onSubmit: handleSubmitForm,
  });

  async function submitForm(values: GameFormValues) {
    const clubView = clubList?.find(club => club.reference?.uid === values.club?.reference?.uid);
    const gameCreationInitiator = sessionStorage.getItem('lastPaths');
    let countPlayers: CountPlayerMetricView | undefined = undefined;
    let screen = MetricScreenView.PLAY_SCREEN;
    if(gameCreationInitiator) {
      if((JSON.parse(gameCreationInitiator) as Array<string>)[0].includes('partners')) {
        countPlayers = window.location.search.includes('single') ? CountPlayerMetricView.SINGLE : CountPlayerMetricView.MULTIPLY;
        screen = MetricScreenView.PARTNER_SCREEN;
      } else if((JSON.parse(gameCreationInitiator) as Array<string>)[0] === '/') {
        screen = MetricScreenView.MAIN_SCREEN;
      }
    }
    try {
      const { result } = await gameSave({
        ...values,
        minGrade: Grade[`_${values.minGrade}` as keyof typeof Grade],
        maxGrade: Grade[`_${values.maxGrade}` as keyof typeof Grade],
        plannedDate: getPlannedDate(formik),
        club: {
          address: clubView?.mapAddress,
          displayName: clubView?.name,
          uid: clubView?.reference?.uid
        },
        duration: Number(values.duration.replace(',', '.')) * 60,
        lineups: [],
        owner: { ...current, uid: current?.identity?.uid },
        status: CalculatedGameStatus.PLANNED,
        permissions: {
          inviteCreate: values.everyoneCanInvite ? [GameRole.PLAYER] : [],
          scoringResult: values.everyoneCanSetScore ? [GameRole.PLAYER] : []
        },
        playersLimit: values.playersLimit ? Number(values.playersLimit) : 4,
        invitations: [],
        members: [],
        metrics: !id ? {
          countPlayers,
          screen,
          system: getPlatform()
        } : undefined
      });
      if(result) {
        const wannaStay = values.isCoachParticipating && values.members.some(member => member.player?.uid === current.identity?.uid);
        if(!wannaStay) {
          await ownerEnterGame({ enter: values.isCoachParticipating, gameUid: result.identity?.uid });
        }
      }
      if(result && values.invitations.length && (result.members?.length! < result.playersLimit!)) {
        await createInviteListForGame({
          gameUid: result.identity?.uid,
          invitations: values.invitations.map(val => ({
            invitationStatus: InvitationStatus.SENT,
            player: val,
            sender: current,
            sendDate: toLocalDateTime(new Date())
          }))
        });
      }
      if(id) {
        navigate(-1);
      } else {
        navigate(`/game/${result?.identity?.uid}/?afterCreation`);
      }
    } catch(err) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    }
  }

  async function handleSubmitForm(values: GameFormValues) {
    gameTimeBooked({
      plannedDate: getPlannedDate(formik)
    })
      .then(({ result }) => {
        if(result?.booked) {
          if(id && (dayjs(gameDate).format('H:mm') === formik.values.plannedDate?.time) && 
            (dayjs(gameDate).format('YYYY-MM-DD') === dayjs(formik.values.plannedDate?.date).format('YYYY-MM-DD'))) {
            submitForm(values);
          } else {
            setWarningModal(true);
          }
        } else {
          submitForm(values);
        }
      })
      .catch(err => {
        if(err instanceof Promise) {
          err.then(err => {
            const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
            PopupMessage.open(userErrorMessage ?? errorMessage);
          })
        }
      });
  }

  const scrollToError = (ref: any) => {
    const centerScreen = ref.current.offsetTop - (window.window.screen.height - ref.current.clientHeight) / 2
    window.scrollTo({
      left: 0,
      top: centerScreen,
      behavior: 'smooth'
    })
  }

  const handleClubSelection = useCallback((uid: string) => {
    const club = clubList?.find(c => c.reference?.uid === uid)
    formik.setFieldValue('club', club);
    if(formik.values.playersLimit === 8 && !club?.lunda) {
      formik.setFieldValue('playersLimit', 7);
    }
    setClubsPageVisisble(false);
  }, [clubList, formik]);

  const scrollToClubSelection = useCallback(() => {
    scrollToError(clubRef);
    setErrorField('club');
    PopupMessage.open('Выберите клуб!')
  }, [formik.values.club, clubRef]);

  const handeleDurationChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if(formik.values.plannedDate.time) {
      if(isWithinDay(formik.values.plannedDate.time, e.target.value)) {
        formik.setFieldValue('duration', e.target.value);
      } else {
        formik.setFieldValue('duration', gameInitialValues.duration)
      }
    } else {
      formik.setFieldValue('duration', e.target.value);
    }
  }, [formik.values.plannedDate.time, isWithinDay])

  useEffect(() => {
    if(!id && current.grades && current.grade) {
      const maxValidGrade = Object.keys(current.grades).length;
      const minGrade = (Number(current.grade.match(/\d/g)?.[0]) - 1) < 1 ? 1 : Number(current.grade.match(/\d/g)?.[0]) - 1;
      const maxGrade = (Number(current.grade.match(/\d/g)?.[0]) + 1) > maxValidGrade ? maxValidGrade : Number(current.grade.match(/\d/g)?.[0]) + 1;
      formik.setFieldValue('minGrade', minGrade);
      formik.setFieldValue('maxGrade', maxGrade);
    }
  }, [current.grades, current.grade, id]);
  
  useEffect(() => {
    if(formik.values.gameTournamentKind && formik.values.playersLimit < 8) {
      formik.setFieldValue('gameTournamentKind', undefined);
    }
  }, [formik.values.gameTournamentKind, formik.values.playersLimit]);

  useEffect(() => {
    if(formik.values.plannedDate?.date) {
      gameBookedListTime({ plannedDate: toLocalDate(formik.values.plannedDate.date) })
        .then(({ result }) => {
          setBookedTimes(result?.times ?? []);
        })
        .catch(err => console.log(err));
    }
  }, [formik.values?.plannedDate?.date]);

  useEffect(() => {
    if(id && current.grades) {
      Promise.all(([ gameLoad({ uid: id }), loadClubs() ]))
      .then(([{ result }, { result: resultClubs }]) => {
        if(result && resultClubs) {
          setClubList(resultClubs.views);
          setGameDate(result.plannedDate);
          const minGrade = Object.entries(current.grades ?? {}).find(entry => `${entry[0]}` === result.minGrade);
          const maxGrade = Object.entries(current.grades ?? {}).find(entry => `${entry[0]}` === result.maxGrade);
          const formValues: GameFormValues = {
            ...gameInitialValues,
            description: result.description ?? '',
            courtBooked: !!result.courtBooked,
            courtNumber: result.courtNumber ?? '',
            duration: `${result.duration! / 60 ?? 2}`.replace('.', ','),
            everyoneCanInvite: !!result.permissions?.inviteCreate?.includes(GameRole.PLAYER),
            isCoachParticipating: !!result.isAuthUserParticipating,
            plannedDate: {
              date: dayjs(result.plannedDate).toDate(),
              time: dayjs(result.plannedDate).format('H:mm'),
            },
            playersLimit: result.playersLimit ?? 4,
            privateGame: !!result.privateGame,
            ranking: !!result.ranking,
            minGrade: Number(minGrade?.[0].split('_')?.[1]),
            maxGrade: Number(maxGrade?.[0].split('_')?.[1]),
            invitations: result.invitations?.filter(inv => inv.sender?.uid !== inv.player?.uid && inv.invitationStatus === InvitationStatus.SENT).map(inv => inv.player ?? {}) ?? [],
            club: resultClubs.views?.find(cl => cl.reference?.uid === result.club?.uid),
            identity: result.identity,
            members: result.members ?? [],
            everyoneCanSetScore: !!result.permissions?.scoringResult?.includes(GameRole.PLAYER),
            gameTournamentKind: result.gameTournamentKind,
          }
          formik.setValues(formValues);
        }
      })
      .catch(err => {
        if(err instanceof Promise) {
          err.then(err => {
            const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
            PopupMessage.open(userErrorMessage ?? errorMessage);
          })
        }
      })
      .finally(() => setLoading(false));
    } else {
      loadClubs()
      .then(({ result }) => {
        const invitationsFromLocalStorage = localStorage.getItem('gameInvitations');
        if (invitationsFromLocalStorage) {
          formik.setFieldValue('invitations', JSON.parse(invitationsFromLocalStorage));
          localStorage.removeItem('gameInvitations');
        }
        setClubList(result?.views ?? []);
      })
      .catch(err => {
        if(err instanceof Promise) {
          err.then(err => {
            const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
            PopupMessage.open(userErrorMessage ?? errorMessage);
          });
        }
      })
      .finally(() => setLoading(false))
    }
  }, [id, current.grades]);

  return ( 
    <>
      <TimeWarningModal<GameFormValues>
        visible={waringModal}
        onClose={() => setWarningModal(false)}
        submitForm={submitForm}
        formValues={formik.values}  
      />
      <div hidden={clubsPageVisible || searchPageVisible}>
        <Header className={styles['header']} handleClick={() => id || window.location.search.includes('fromPartners') ? navigate(-1) : navigate('/play')}>
          {id ? 'Редактирование игры' : 'Создание игры'}
        </Header>
        {!id && (current.roles?.includes(Role.COACH) || current.roles?.includes(Role.ADMIN)) ?        
          <CreateTabs
            activeTab='game'
          /> : null
        }
        {loading ?
          <PuffLoader color='#4EB857' cssOverride={{ margin: 'auto' }} /> :
          <form onSubmit={formik.handleSubmit}>
            <div ref={clubRef}>
              <SelectClubSection
                clubList={clubList}
                selectedClub={formik.getFieldProps('club').value}
                handleClubSelection={handleClubSelection}
                onClick={() => clubList ? setClubsPageVisisble(true) : undefined}
              />
            </div>
            <HLine fieldsErrors={errorField === 'club'} calssName={styles['hline']}/>
            <SelectRating
              title='Выберите рейтинг игроков'
              onChange={(value) => {
                formik.setFieldValue('minGrade',value[0]);
                formik.setFieldValue('maxGrade', value[1]);
              }}
              value={[formik.values.minGrade, formik.values.maxGrade]}
            />
            <HLine calssName={styles['hline']} fieldsErrors={errorField === 'kind'}/>
            <div ref={kindRef}>
              <GamePlayersFormItem
                playersLimit={formik.values.playersLimit}
                evetyoneCanInvite={formik.values.everyoneCanInvite}
                isCoachParticipating={formik.values.isCoachParticipating}
                invitations={formik.values.invitations}
                lundaClub={!!formik.values.club?.lunda}
                tourenamentKind={formik.values.gameTournamentKind}
                inputChange={value => formik.setFieldValue('playersLimit', value)}
                switcherChange={value => formik.setFieldValue(value, !formik.values[value])}
                tournamentChange={kind => formik.setFieldValue('gameTournamentKind', kind)}
                onInviteClick={() => setSearchPageVisible(true)}
              />
            </div>
            <HLine calssName={styles['hline']} fieldsErrors={errorField === 'kind'}/>
            <MatchDetails 
              onChange={formik.handleChange} 
              formikValues={formik.values} 
              clublist={clubList} 
              setFieldValue={formik.setFieldValue}
              scrollToClubSelection={scrollToClubSelection}
            />
            <SelectDate
              onActiveDateChange={date =>  formik.setFieldValue('plannedDate.date', date)}
              initialStartDate={toLocalDateTime(formik.values.plannedDate?.date)}
              setErrorField={(field: string) => setErrorField(field)}
            />
            <MatchDurationRadio 
              durationValue={formik.values.duration}
              booking={!!(formik.values.courtBooked && formik.values.club?.yClientsIntegrated)}
              onChange={handeleDurationChange}
            />
            <HLine fieldsErrors={errorField === 'time'} calssName={styles['hline']}/>
            <div ref={timeRef}>
              <SelectTime
                onChange={formik.handleChange} 
                bookedTimes={bookedTimes} 
                formikValues={formik.values} 
              />
            </div>
            <HLine fieldsErrors={errorField === 'time'} calssName={styles['hline']}/>
            <MatchResultSettings getFieldValue={formik.getFieldProps}/>
            <OversideWrapper>
              <Button className={styles['createMatchBtn']} type='submit'>
                {!id ? <CrossIcon fill='var(--white)'/> : null}
                {id ? 'Сохранить игру' : 'Создать игру'}
              </Button>
            </OversideWrapper>
          </form>
        }
      </div>
      {clubsPageVisible && clubList ?
        <ClubSelectionPage
          selectedClub={formik.getFieldProps('club').value}
          onClose={() => setClubsPageVisisble(false)}
          handleClubSelect={handleClubSelection}
        /> : null
      }
      {searchPageVisible && current.grades ?
        <MatchSearchPage
          onSubmit={(invitedPlayers) => {
            formik.setFieldValue('invitations', invitedPlayers);
            setSearchPageVisible(false);
          }}
          ratingRange={[formik.values.minGrade, formik.values.maxGrade]}
          initialSlectedPlayers={formik.values.invitations}
          onClose={() => setSearchPageVisible(false)}
          excludedUids={[]}
        /> : null
      }
    </>
  );
}
 
export default GameCreatePage;
