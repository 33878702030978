import { ClubView } from '@/integration-api/server-rest-lundapadelApi';
import ClubSelectionPage from '@/pages/application/create/ClubSelectionPage/ClubSelectionPage';
import styles from './ClubsModal.module.css';
import { useEffect } from 'react';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Button from '@/components/Button/Button';

interface ClubsModalProps {
  closeModal: () => void;
  handleClubSelect: (uid: string, club?: ClubView | undefined) => void;
  setSelectedClubs: (clubs: ClubView[]) => void;
  selectedClubs: ClubView[]
}

let prevSelectedClubs: ClubView[] = []

const ClubsModal = (props: ClubsModalProps) => {
  const { closeModal, handleClubSelect, selectedClubs, setSelectedClubs } = props;
  
  const handleBackBtnClick = () => {
    closeModal();
    setSelectedClubs(prevSelectedClubs);
  }
  
  const additionalBtn = (
    <OversideWrapper className={styles.btnWrap}>
      <Button onClick={closeModal}>Сохранить</Button>
    </OversideWrapper>
  )

  useEffect(() => {
    prevSelectedClubs = selectedClubs;
  }, [])

  return (
    <ClubSelectionPage
      onClose={handleBackBtnClick}
      handleClubSelect={handleClubSelect}
      selectVariant='check'
      selectedClubs={selectedClubs?.map((club) => club.reference?.uid!)}
      className={styles.modal}
      additionalBtn={additionalBtn}
    />
  );
}
 
export default ClubsModal;
