import { ClubView } from '@/integration-api/server-rest-lundapadelApi';
import styles from './MultiClubFiled.module.css';
import locationIconActive from '@/static/images/icons/location_active.svg';
import { AllHTMLAttributes } from 'react';
import ArrowIcon from '@/static/images/icons/ArrowIcon';

interface MultiClubFiledProps {
  selectedClub: ClubView[];
  onClick: AllHTMLAttributes<HTMLElement>['onClick']; 
}

const MultiClubFiled: React.FC<MultiClubFiledProps> = (props) => {
  const {selectedClub, onClick} = props;
  
  return (
    <div className={styles['clubs-button']} onClick={onClick}>
      <img className={styles.icon} src={locationIconActive} alt="локация" />

      {selectedClub?.length ? (
        <span className={styles['club-text']}>{selectedClub.map((selectedClub) => (selectedClub.name)).join(', ')}</span>
      ) : (
        <span>Выбрать клуб</span>
      )}

      <ArrowIcon direction='rigth' />
    </div>
  );
}
 
export default MultiClubFiled;
