import { useRef } from 'react';
import type { RatingSectionProps } from './RatingSection.interfaces';
import { useAppSelector } from '@/hooks/hooks';
import { VariableSizeList as List } from 'react-window';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import PlayerRow from './components/PlayerRow';
import { setSwiperBlockerStatus } from '../swiper/utils';
import styles from './RatingSection.module.css';
import { PuffLoader } from 'react-spinners';
import { parseSAValues } from '@/utils/utils';

const rowHeight = 57;

export const playerListBlockerElementID = 'playerList-blocker';

const RatingSection = (props: RatingSectionProps): JSX.Element => {
  const { playersList, playerListWrapRef, onPlayerIntersecting, grades } = props;
  
  const observerRef = useRef<IntersectionObserver | null>(null);
  const currentUser = useAppSelector((state) => state.auth.currentProfile ?? {});
  const scrollTimer = useRef<NodeJS.Timeout>();
  const isUserScroll = useRef(false);
  const isTouchNow = useRef(false);

  const rowHeights = playersList.map((player, index) => (player.displayGrade !== playersList[index - 1]?.displayGrade) ? 90 : rowHeight);

  const listRefCallback = (node: HTMLDivElement | null) => {
    if (node?.parentElement) {
      node.parentElement.addEventListener('touchstart', (e) => {
        isTouchNow.current = true;
        setSwiperBlockerStatus('block'); // устанавливаем блокировку свайпера
      })

      node.parentElement.addEventListener('touchend', (e) => {
        if (!scrollTimer.current) {
          setSwiperBlockerStatus('unblock'); // снимаем блокировку свайпера
        }

        isTouchNow.current = false;
      })

      node.parentElement.addEventListener('scroll', (e) => {
        if (isTouchNow.current) {
          isUserScroll.current = true;
        }

        // ждем окончания скролла для установки активного грейда 
        if (scrollTimer.current) {
          clearTimeout(scrollTimer.current)
        };
        
        // полифил события scrollend
        scrollTimer.current = setTimeout(() => {
          scrollTimer.current = undefined; // очищаем таймер
          
        if (isUserScroll.current) { // если скрол вызван не пользователем - пропускаем
          const playerListElement = Array.from((e.target as HTMLDivElement).firstChild?.childNodes || [])
            .find(el => +(getComputedStyle(el as HTMLElement).top.replace('px', '')) >= (e.target as HTMLDivElement).scrollTop);
          const swiperGradeIndex = Number(grades?.findIndex((grade) => grade.displayGrade === (playerListElement as HTMLDivElement)?.dataset.grade));

          onPlayerIntersecting?.(swiperGradeIndex) // вызываем коллбек

          if (!isTouchNow.current) setSwiperBlockerStatus('unblock'); // снимаем блокировку свайпера
          isUserScroll.current = false;
        }}, 100)
      })
    }
  }

  return (
    grades ? (
      <>
        <div>
          <div
            id={playerListBlockerElementID}
            className={styles.blocker}
            style={{height: `calc(100% - 199px)`}}
          />
        </div>

        <AutoSizer>
          {({width}: Size) => (  
            <List
              overscanCount={50}
              key={playersList.length}
              height={document.documentElement.clientHeight - 270 - parseSAValues()}
              itemCount={playersList.length}
              itemSize={(index: number) => rowHeights[index]}
              width={width}
              className='players-list'
              // @ts-ignore
              ref={playerListWrapRef}
              innerRef={listRefCallback}
            >
              {PlayerRow({ playersList, observerRef, currentUser })}
            </List>
          )}
        </AutoSizer>
      </>
    ) : (
      <PuffLoader color='#4EB857' cssOverride={{ margin: 'auto' }} /> 
    )
  );
};

export default RatingSection;
