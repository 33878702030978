import { useCallback, useEffect } from "react";
import styles from './CalendarModal.module.css';
import '@natscale/react-calendar/dist/main.css';
import { Calendar as RCalendar } from '@natscale/react-calendar';
import Header from '@/components/Header/Header';
import Button from '@/components/Button/Button';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import { Value } from '@natscale/react-calendar/dist/utils/types';
import dayjs from "dayjs";

const monthsLabel = {
	0: 'Январь',
	1: 'Февраль',
	2: 'Март',
	3: 'Апрель',
	4: 'Май',
	5: 'Июнь',
	6: 'Июль',
	7: 'Август',
	8: 'Сентябрь',
	9: 'Октябрь',
	10: 'Ноябрь',
	11: 'Декабрь',
}

const weekdayLabels = {
  1: 'ПН', 2: 'ВТ', 3: 'СР', 4: 'ЧТ', 5: 'ПТ', 6: 'СБ', 0: 'ВС'
}

let prevRange: Date[] | undefined = undefined;

interface CalendarModalProps {
  range: Date[] | undefined;
  closeModal: () => void;
  saveDateRange: (range: Date[] | undefined) => void;
}

function CalendarModal(props: CalendarModalProps) {
  const { range, closeModal, saveDateRange} = props;

  const handleChange = (v: Value) => {
    if (v instanceof Array) {
      saveDateRange(v);
    }
  }

  const handleCloseModal = () => {
    saveDateRange(prevRange);
    closeModal()
  }

  const handleSaveBtnClick = () => {
    if (range) {
      saveDateRange(range);
    }
    closeModal();
  }

  const disabledDates = useCallback((date: Date) => {
    return dayjs().format('YYYY-MM-DD') > dayjs(date).format('YYYY-MM-DD');
  }, []);

  useEffect(() => {
    prevRange = range;
  }, []);

	return (
    <div className={styles.wrap}>
      <Header handleClick={handleCloseModal} >
        Дата
      </Header>
      <RCalendar 
        className={styles.calendar} 
        isRangeSelector 
        value={range} 
        onChange={handleChange} 
        noPadRangeCell 
        monthsLabel={monthsLabel}
        weekDaysLabel={weekdayLabels} 
        useDarkMode
        isDisabled={disabledDates}
        hideAdjacentDates
      />
      <OversideWrapper className={styles.btnWrap}>
        <Button onClick={handleSaveBtnClick}>Сохранить</Button>
      </OversideWrapper>
    </div>
	);
}

export default CalendarModal;
