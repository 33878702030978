import { SubscriptionCardProps } from '../../SubscriptionCard.interfaces';
import SubscriptionCard from '../../SubscriptionCard';
import styles from './ExpiredSubscriptionCard.module.css';

interface ExpiredSubscriptionCardProps extends SubscriptionCardProps {

}

const ExpiredSubscriptionCard: React.FC<ExpiredSubscriptionCardProps> = (props) => {
  const { subscription } = props;
  
  return (
    <SubscriptionCard subscription={subscription} cardClassName={styles.active} />
  );
}
 
export default ExpiredSubscriptionCard;
