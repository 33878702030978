/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClubView,
    ClubViewFromJSON,
    ClubViewFromJSONTyped,
    ClubViewToJSON,
} from './ClubView';
import {
    DayOfWeek,
    DayOfWeekFromJSON,
    DayOfWeekFromJSONTyped,
    DayOfWeekToJSON,
} from './DayOfWeek';
import {
    Grade,
    GradeFromJSON,
    GradeFromJSONTyped,
    GradeToJSON,
} from './Grade';
import {
    LocalDate,
    LocalDateFromJSON,
    LocalDateFromJSONTyped,
    LocalDateToJSON,
} from './LocalDate';
import {
    SubscriptionEntity,
    SubscriptionEntityFromJSON,
    SubscriptionEntityFromJSONTyped,
    SubscriptionEntityToJSON,
} from './SubscriptionEntity';

/**
 * 
 * @export
 * @interface SubscriptionView
 */
export interface SubscriptionView {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionView
     */
    uid?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionView
     */
    active?: boolean;
    /**
     * 
     * @type {LocalDate}
     * @memberof SubscriptionView
     */
    startDate?: LocalDate;
    /**
     * 
     * @type {LocalDate}
     * @memberof SubscriptionView
     */
    endDate?: LocalDate;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionView
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionView
     */
    endTime?: string;
    /**
     * 
     * @type {Grade}
     * @memberof SubscriptionView
     */
    minGrade?: Grade;
    /**
     * 
     * @type {Grade}
     * @memberof SubscriptionView
     */
    maxGrade?: Grade;
    /**
     * 
     * @type {Array<ClubView>}
     * @memberof SubscriptionView
     */
    clubs?: Array<ClubView>;
    /**
     * 
     * @type {Array<DayOfWeek>}
     * @memberof SubscriptionView
     */
    days?: Array<DayOfWeek>;
    /**
     * 
     * @type {Array<SubscriptionEntity>}
     * @memberof SubscriptionView
     */
    subscribeTo?: Array<SubscriptionEntity>;
}

export function SubscriptionViewFromJSON(json: any): SubscriptionView {
    return SubscriptionViewFromJSONTyped(json, false);
}

export function SubscriptionViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'startDate': !exists(json, 'startDate') ? undefined : LocalDateFromJSON(json['startDate']),
        'endDate': !exists(json, 'endDate') ? undefined : LocalDateFromJSON(json['endDate']),
        'startTime': !exists(json, 'startTime') ? undefined : json['startTime'],
        'endTime': !exists(json, 'endTime') ? undefined : json['endTime'],
        'minGrade': !exists(json, 'minGrade') ? undefined : GradeFromJSON(json['minGrade']),
        'maxGrade': !exists(json, 'maxGrade') ? undefined : GradeFromJSON(json['maxGrade']),
        'clubs': !exists(json, 'clubs') ? undefined : ((json['clubs'] as Array<any>).map(ClubViewFromJSON)),
        'days': !exists(json, 'days') ? undefined : ((json['days'] as Array<any>).map(DayOfWeekFromJSON)),
        'subscribeTo': !exists(json, 'subscribeTo') ? undefined : ((json['subscribeTo'] as Array<any>).map(SubscriptionEntityFromJSON)),
    };
}

export function SubscriptionViewToJSON(value?: SubscriptionView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uid': value.uid,
        'active': value.active,
        'startDate': LocalDateToJSON(value.startDate),
        'endDate': LocalDateToJSON(value.endDate),
        'startTime': value.startTime,
        'endTime': value.endTime,
        'minGrade': GradeToJSON(value.minGrade),
        'maxGrade': GradeToJSON(value.maxGrade),
        'clubs': value.clubs === undefined ? undefined : ((value.clubs as Array<any>).map(ClubViewToJSON)),
        'days': value.days === undefined ? undefined : ((value.days as Array<any>).map(DayOfWeekToJSON)),
        'subscribeTo': value.subscribeTo === undefined ? undefined : ((value.subscribeTo as Array<any>).map(SubscriptionEntityToJSON)),
    };
}

