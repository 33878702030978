/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseMetricView,
    BaseMetricViewFromJSON,
    BaseMetricViewFromJSONTyped,
    BaseMetricViewToJSON,
} from './BaseMetricView';

/**
 * 
 * @export
 * @interface ShareQrMetricRequest
 */
export interface ShareQrMetricRequest {
    /**
     * 
     * @type {BaseMetricView}
     * @memberof ShareQrMetricRequest
     */
    parameters?: BaseMetricView;
}

export function ShareQrMetricRequestFromJSON(json: any): ShareQrMetricRequest {
    return ShareQrMetricRequestFromJSONTyped(json, false);
}

export function ShareQrMetricRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShareQrMetricRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : BaseMetricViewFromJSON(json['parameters']),
    };
}

export function ShareQrMetricRequestToJSON(value?: ShareQrMetricRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': BaseMetricViewToJSON(value.parameters),
    };
}

