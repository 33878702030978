import { Navigate, useOutletContext } from "react-router-dom";
import { IGamePageContext } from "../GamePage.interfaces";
import GamePlaceholderPage from "../GamePlaceholderPage/GamePlaceholderPage";
import { gameModifiableStatuses } from "../utils";
import { CalculatedGameStatus } from "@/integration-api/server-rest-lundapadelApi";
import CompletedGamePage from "../CompletedGamePage/CompletedGamePage";
import PlannedGamePage from "../PlannedGamePage/PlannedGamePage";

const GamePageByCondition = () => {
  const { game, initialLoading } = useOutletContext<IGamePageContext>();
  
  if(initialLoading || !game.status) {
    return <GamePlaceholderPage/>;
  }
  
  if(!gameModifiableStatuses.includes(game?.status)) {
    if(game.status === CalculatedGameStatus.CANCELED || game.status === CalculatedGameStatus.SCORE_UNDEFINED) {
      return <Navigate to={`/my-games?tab=completed`} replace={true}/>
    } else {
      return <CompletedGamePage />
    }
  }

  return <PlannedGamePage/>
}
 
export default GamePageByCondition;
