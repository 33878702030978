import React from 'react';
import Icon, { IconProps } from '../Icon';

interface CheckProps extends IconProps {}

const Check: React.FC<CheckProps> = (props) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M7.9585 15.0013L3.2085 10.2513L4.396 9.0638L7.9585 12.6263L15.6043 4.98047L16.7918 6.16797L7.9585 15.0013Z"
          fill="#FFF"
        />
      </svg>
    </Icon>
  );
};

export default Check;
