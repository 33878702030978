interface TrashIconProps {
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const TrashIcon = ({ onClick }: TrashIconProps) => {
  return (  
    <svg onClick={onClick} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.04 2.97341H12.16V2.08139C12.16 1.52937 11.924 0.999961 11.5039 0.609625C11.0838 0.219289 10.5141 0 9.92 0H6.08C5.48592 0 4.91616 0.219289 4.49608 0.609625C4.076 0.999961 3.84 1.52937 3.84 2.08139V2.97341H0.96C0.705392 2.97341 0.461212 3.06739 0.281177 3.23468C0.101143 3.40197 0 3.62886 0 3.86544C0 4.10202 0.101143 4.32891 0.281177 4.49619C0.461212 4.66348 0.705392 4.75746 0.96 4.75746H1.28V14.8671C1.28 15.2614 1.44857 15.6395 1.74863 15.9183C2.04869 16.1971 2.45565 16.3538 2.88 16.3538H13.12C13.5443 16.3538 13.9513 16.1971 14.2514 15.9183C14.5514 15.6395 14.72 15.2614 14.72 14.8671V4.75746H15.04C15.2946 4.75746 15.5388 4.66348 15.7188 4.49619C15.8989 4.32891 16 4.10202 16 3.86544C16 3.62886 15.8989 3.40197 15.7188 3.23468C15.5388 3.06739 15.2946 2.97341 15.04 2.97341ZM5.76 2.08139C5.76 2.00253 5.79371 1.9269 5.85373 1.87114C5.91374 1.81537 5.99513 1.78405 6.08 1.78405H9.92C10.0049 1.78405 10.0863 1.81537 10.1463 1.87114C10.2063 1.9269 10.24 2.00253 10.24 2.08139V2.97341H5.76V2.08139ZM12.8 14.5697H3.2V4.75746H12.8V14.5697ZM7.04 7.13619V11.8937C7.04 12.1302 6.93886 12.3571 6.75882 12.5244C6.57879 12.6917 6.33461 12.7857 6.08 12.7857C5.82539 12.7857 5.58121 12.6917 5.40118 12.5244C5.22114 12.3571 5.12 12.1302 5.12 11.8937V7.13619C5.12 6.89961 5.22114 6.67272 5.40118 6.50543C5.58121 6.33815 5.82539 6.24417 6.08 6.24417C6.33461 6.24417 6.57879 6.33815 6.75882 6.50543C6.93886 6.67272 7.04 6.89961 7.04 7.13619ZM10.88 7.13619V11.8937C10.88 12.1302 10.7789 12.3571 10.5988 12.5244C10.4188 12.6917 10.1746 12.7857 9.92 12.7857C9.66539 12.7857 9.42121 12.6917 9.24118 12.5244C9.06114 12.3571 8.96 12.1302 8.96 11.8937V7.13619C8.96 6.89961 9.06114 6.67272 9.24118 6.50543C9.42121 6.33815 9.66539 6.24417 9.92 6.24417C10.1746 6.24417 10.4188 6.33815 10.5988 6.50543C10.7789 6.67272 10.88 6.89961 10.88 7.13619Z" fill="#CE2020"/>
    </svg>
  );
}
 
export default TrashIcon;
