import { HTMLAttributes } from 'react';
import styles from './FavoriteClubItem.module.css';
import { ClubView } from '@/integration-api/server-rest-lundapadelApi';
import locationIcon from '@/static/images/icons/location.svg';
import locationIconActive from '@/static/images/icons/location_active.svg';
import starIcon from '@/static/images/icons/favorite-club-star-icon.svg';
import starIconOutline from '@/static/images/icons/favorite-club-star-icon-outline.svg';
import { Check } from '@/components/Icon/icons';

export interface FavoriteClubItemProps extends HTMLAttributes<HTMLDivElement> {
  club: ClubView;
  selectedClub?: string;
  isSelectedClub?: boolean;
  selectVariant?: 'fill' | 'check';
  favoriteClubSelection?: boolean;
  favoriteSelectedClubs?: Array<ClubView>;
}

const FavoriteClubItem = (props: FavoriteClubItemProps) => {
  const { club, selectedClub, favoriteClubSelection, favoriteSelectedClubs, isSelectedClub, selectVariant = 'fill', ...restProps } = props;
  
  const isFavoriteSelected = !!favoriteSelectedClubs?.find(c => club.reference?.uid === c.reference?.uid)
  
  const favoriteClubCardClassName = `${styles['wrapper']} ${selectVariant === 'fill' && (selectedClub === club.reference?.uid || isSelectedClub) ? styles['active'] : ''}`;

  return ( 
    <div {...restProps} className={favoriteClubCardClassName}>
      <img src={selectedClub === club.reference?.uid ? locationIconActive : locationIcon} alt="локация" />
      <div className={styles['info']}>
        <div className={styles['top']}>
          <span>{club.name}</span>
          <span className={styles['icon']}>
            {(club.favorite && !favoriteClubSelection) || isFavoriteSelected ? <img  src={starIcon} alt="любимый клуб"/> : favoriteClubSelection ? <img src={starIconOutline} alt='нелюбимый клуб'/> : null}
            {selectVariant === 'check' && <Check className={(selectedClub === club.reference?.uid || isSelectedClub) ? styles.checkIndicator_on : styles.checkIndicator_off} />}
          </span>
        </div>
        <span className={`${styles['bottom']} ${selectedClub === club.reference?.uid ? styles['active-text'] : ''}`}>
          {club.mapAddress}
        </span>
      </div>
    </div>
  );
}
 
export default FavoriteClubItem;
