import { BaseMetricView, CreateLineupParam, DeleteLineupParam, DeviceSystem, ExtendGameLockInfo, GameCancelParam, GameCreateInviteListParam, GameExitParam, GameInviteAcceptParam, GameInviteByParticipantParam, GameInviteCreateParam, GameInviteLoadListParam, GameMatchStatusCheckParam, GamePrivateParam, GameResultReadyParam, GameTournamentStartParam, InviteLoadListParam, LoadAndLockGameParam, LundapadelGameApiGameLoadRequest, LundapadelGameApiLoadGameLineupRequest, OwnerEnterGameParam, PlayerProfileLoadListParameters, SaveLineupParam, UnlockGameParam } from "@/integration-api/server-rest-lundapadelApi";
import { ChatType } from '@/integration-api/server-rest-lundapadelApi';
import RestClient from "@/integration/RestClient";

export function gameLoad(params: LundapadelGameApiGameLoadRequest) {
  return RestClient.gameApi.gameLoad(params)
}

export function gameCancel(parameters: GameCancelParam) {
  return RestClient.gameApi.gameCancel({
    gameCancelRequest: {
      parameters
    }
  })
}

export function ownerEnterGame(parameters: OwnerEnterGameParam) {
  return RestClient.gameApi.ownerEnterGame({ 
    ownerEnterGameRequest: {
      parameters
    }
  });
}

export function exitGame(parameters: GameExitParam){
  return RestClient.gameApi.exitGame({
    exitGameRequest: {
      parameters
    }
  });
}
      
export function setPrivateGame(parameters: GamePrivateParam){
  return RestClient.gameApi.setPrivateGame({
    setPrivateGameRequest: {
      parameters
    }
  })
}

export function gameResultReady(parameters: GameResultReadyParam) {
  return RestClient.gameApi.gameResultReady({
    gameResultReadyRequest: {
      parameters
    }
  })
}

export function loadGameTournamentStatistics(gameUid: string) {
  return RestClient.gameApi.loadGameTournamentStatistics({ gameUid })
}

export function loadGameRoundsPreview(gameUid: string) {
  return RestClient.gameApi.loadGameRoundsPreview({ gameUid })
}

export function startGameTournament(parameters: GameTournamentStartParam) {
  return RestClient.gameApi.startGameTournament({
    startGameTournamentRequest: {
      parameters
    }
  })
}

// invitations handlers
export function createInviteListForGame(parameters: GameCreateInviteListParam) {
  return RestClient.gameApi.createInviteListForGame({
    createInviteListForGameRequest: {
      parameters
    }
  });
}

export function participantCreateGameInvite(parameters: GameInviteByParticipantParam) {
  return RestClient.gameApi.participantCreateGameInvite({
    participantCreateGameInviteRequest: {
      parameters
    }
  });
}

export function loadListGameInvite(parameters: GameInviteLoadListParam) {
  return RestClient.gameApi.loadListGameInvite({
    loadListGameInviteRequest: {
      parameters
    }
  });
}

export function loadListInvite(parameters: InviteLoadListParam) {
  return RestClient.tournamentApi.loadListInvite({
    loadListInviteRequest: {
      parameters
    }
  })
}

export function createInviteForGame(parameters: GameInviteCreateParam) {
  return RestClient.gameApi.createInviteForGame({
    createInviteForGameRequest: {
      parameters
    }
  })
}

export function acceptInviteForGame(parameters: GameInviteAcceptParam) {
  return RestClient.gameApi.acceptInviteForGame({
    acceptInviteForGameRequest: {
      parameters
    }
  });
}

export function cancelGameInvite(gameUid: string) {
  return RestClient.gameApi.cancelGameInvite({ gameUid });
}

export function acceptGameInviteByQr(gameUid: string, system: DeviceSystem) {
  return RestClient.gameApi.acceptGameInviteByQr({ gameUid, system });
}

// lineup handlers
export function loadGameLineup(params: LundapadelGameApiLoadGameLineupRequest) {
  return RestClient.gameApi.loadGameLineup(params)
}

export function createGameLineup(parameters: CreateLineupParam) {
  return RestClient.gameApi.createGameLineup({
    createGameLineupRequest: {
      parameters
    }
  })
}

export function saveGameLineup(parameters: SaveLineupParam) {
  return RestClient.gameApi.saveGameLineup({
    saveGameLineupRequest: {
      parameters
    }
  })
}

export function deleteGameLineup(parameters: DeleteLineupParam) {
  return RestClient.gameApi.deleteGameLineup({
    deleteGameLineupRequest: {
      parameters
    }
  })
}

export function gameMatchStatus(parameters: GameMatchStatusCheckParam) {
  return RestClient.gameApi.gameMatchStatus({
    gameMatchStatusRequest: {
      parameters
    }
  })
}

//for invites
export const playerLoadList = (parameters: PlayerProfileLoadListParameters) => {
  return RestClient.profileApi.playerLoadList({
    playerLoadListRequest: {
      parameters
    }
  })
}

export function getGameChats(relatedEntityUid: string) {
  return RestClient.mainApi.chatLoadList({
    relatedEntityUid,
    type: ChatType.GAME,
  });
}

//lock
export function loadAndLockGame(parameters: LoadAndLockGameParam) {
  return RestClient.gameApi.loadAndLockGame({
    loadAndLockGameRequest: {
      parameters
    }
  })
}

export function extendGameLock(parameters: ExtendGameLockInfo) {
  return RestClient.gameApi.extendGameLock({
    extendGameLockRequest: {
      parameters
    }
  })
}

export function unlockGame(parameters: UnlockGameParam) {
  return RestClient.gameApi.unlockGame({ 
    unlockGameRequest: {
      parameters
    }
  })
}

//metrics
export function shareGameMetric(parameters: BaseMetricView) {
  return RestClient.metricApi.shareGameMetric({
    shareGameMetricRequest: {
      parameters
    }
  })
}
