import { ChangeEventHandler, useEffect } from "react";
import styles from './DaysModal.module.css';
import CheckboxSquare from '@/components/CheckboxSquare/CheckboxSquare';
import Header from '@/components/Header/Header';
import { DayOfWeek } from '@/integration-api/server-rest-lundapadelApi';
import { daysLabes } from './utils';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Button from '@/components/Button/Button';

interface DaysModalProps {
  selectedDays: DayOfWeek[];
  onDaySelect: (day: DayOfWeek) => void;
  closeModal: () => void;
  restoreSelectedDays: (days: DayOfWeek[]) => void;
}

let prevSelectedDays: DayOfWeek[] = [];

function DaysModal(props: DaysModalProps) {
  const { selectedDays, onDaySelect, closeModal, restoreSelectedDays } = props;

	const handler: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
		onDaySelect(target.value as DayOfWeek);
	}

  const handleCloseModal = () => {
    restoreSelectedDays(prevSelectedDays);
    closeModal();
  }

  const handleSaveBtnClick = () => {
    closeModal();
  }

  useEffect(() => {
    prevSelectedDays = selectedDays;
  }, []);

	return (
		<div className={styles.wrap}>
			<Header handleClick={handleCloseModal}>
				Дни недели
			</Header>

			<div className={styles.dayList}>
        {Object.keys(daysLabes).map((dayKey) => (
					<label key={dayKey}>
						<CheckboxSquare className={styles.checkbox} checked={selectedDays.includes(dayKey as DayOfWeek)} value={dayKey} onChange={handler} style={{height: '100%'}} />
						{daysLabes[dayKey as DayOfWeek]}
					</label>
        ))}
			</div>

      <OversideWrapper className={styles.btnWrap}>
        <Button onClick={handleSaveBtnClick}>Сохранить</Button>
      </OversideWrapper>
		</div>
	);
}

export default DaysModal;
