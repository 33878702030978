import RestClient from '@/integration/RestClient';

export function getSubscriptionTG(subscriptionUid: string) {
  return RestClient.profileApi.loadTourGameBySubscription({
    subscriptionUid,
  })
}

export function fetchSubscription(uid: string) {
  return RestClient.profileApi.loadSubscription({
    uid
  })
}
