/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationRelatedEntityType {
    MATCH = 'MATCH',
    GAME = 'GAME',
    TOURNAMENT = 'TOURNAMENT',
    PLAYER_PROFILE = 'PLAYER_PROFILE',
    FEEDBACK = 'FEEDBACK',
    CHAT = 'CHAT',
    SUBSCRIPTION = 'SUBSCRIPTION'
}

export function NotificationRelatedEntityTypeFromJSON(json: any): NotificationRelatedEntityType {
    return NotificationRelatedEntityTypeFromJSONTyped(json, false);
}

export function NotificationRelatedEntityTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationRelatedEntityType {
    return json as NotificationRelatedEntityType;
}

export function NotificationRelatedEntityTypeToJSON(value?: NotificationRelatedEntityType | null): any {
    return value as any;
}

