/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateInviteMetricView,
    CreateInviteMetricViewFromJSON,
    CreateInviteMetricViewFromJSONTyped,
    CreateInviteMetricViewToJSON,
} from './CreateInviteMetricView';
import {
    GameInviteView,
    GameInviteViewFromJSON,
    GameInviteViewFromJSONTyped,
    GameInviteViewToJSON,
} from './GameInviteView';

/**
 * 
 * @export
 * @interface GameCreateInviteListParam
 */
export interface GameCreateInviteListParam {
    /**
     * 
     * @type {string}
     * @memberof GameCreateInviteListParam
     */
    gameUid?: string;
    /**
     * 
     * @type {CreateInviteMetricView}
     * @memberof GameCreateInviteListParam
     */
    metrics?: CreateInviteMetricView;
    /**
     * 
     * @type {Array<GameInviteView>}
     * @memberof GameCreateInviteListParam
     */
    invitations?: Array<GameInviteView>;
}

export function GameCreateInviteListParamFromJSON(json: any): GameCreateInviteListParam {
    return GameCreateInviteListParamFromJSONTyped(json, false);
}

export function GameCreateInviteListParamFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameCreateInviteListParam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gameUid': !exists(json, 'gameUid') ? undefined : json['gameUid'],
        'metrics': !exists(json, 'metrics') ? undefined : CreateInviteMetricViewFromJSON(json['metrics']),
        'invitations': !exists(json, 'invitations') ? undefined : ((json['invitations'] as Array<any>).map(GameInviteViewFromJSON)),
    };
}

export function GameCreateInviteListParamToJSON(value?: GameCreateInviteListParam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gameUid': value.gameUid,
        'metrics': CreateInviteMetricViewToJSON(value.metrics),
        'invitations': value.invitations === undefined ? undefined : ((value.invitations as Array<any>).map(GameInviteViewToJSON)),
    };
}

