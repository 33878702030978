/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DeviceSystem {
    IOS = 'IOS',
    ANDROID = 'ANDROID',
    WEB = 'WEB'
}

export function DeviceSystemFromJSON(json: any): DeviceSystem {
    return DeviceSystemFromJSONTyped(json, false);
}

export function DeviceSystemFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceSystem {
    return json as DeviceSystem;
}

export function DeviceSystemToJSON(value?: DeviceSystem | null): any {
    return value as any;
}

