/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseMetricView,
    BaseMetricViewFromJSON,
    BaseMetricViewFromJSONTyped,
    BaseMetricViewToJSON,
} from './BaseMetricView';
import {
    GameRoundLineupView,
    GameRoundLineupViewFromJSON,
    GameRoundLineupViewFromJSONTyped,
    GameRoundLineupViewToJSON,
} from './GameRoundLineupView';
import {
    GameRoundStatus,
    GameRoundStatusFromJSON,
    GameRoundStatusFromJSONTyped,
    GameRoundStatusToJSON,
} from './GameRoundStatus';

/**
 * 
 * @export
 * @interface GameRoundView
 */
export interface GameRoundView {
    /**
     * 
     * @type {string}
     * @memberof GameRoundView
     */
    gameUid?: string;
    /**
     * 
     * @type {string}
     * @memberof GameRoundView
     */
    lockToken?: string;
    /**
     * 
     * @type {number}
     * @memberof GameRoundView
     */
    roundNumber?: number;
    /**
     * 
     * @type {GameRoundStatus}
     * @memberof GameRoundView
     */
    status?: GameRoundStatus;
    /**
     * 
     * @type {boolean}
     * @memberof GameRoundView
     */
    allGameResultSet?: boolean;
    /**
     * 
     * @type {BaseMetricView}
     * @memberof GameRoundView
     */
    metrics?: BaseMetricView;
    /**
     * 
     * @type {Array<GameRoundLineupView>}
     * @memberof GameRoundView
     */
    lineups?: Array<GameRoundLineupView>;
}

export function GameRoundViewFromJSON(json: any): GameRoundView {
    return GameRoundViewFromJSONTyped(json, false);
}

export function GameRoundViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameRoundView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gameUid': !exists(json, 'gameUid') ? undefined : json['gameUid'],
        'lockToken': !exists(json, 'lockToken') ? undefined : json['lockToken'],
        'roundNumber': !exists(json, 'roundNumber') ? undefined : json['roundNumber'],
        'status': !exists(json, 'status') ? undefined : GameRoundStatusFromJSON(json['status']),
        'allGameResultSet': !exists(json, 'allGameResultSet') ? undefined : json['allGameResultSet'],
        'metrics': !exists(json, 'metrics') ? undefined : BaseMetricViewFromJSON(json['metrics']),
        'lineups': !exists(json, 'lineups') ? undefined : ((json['lineups'] as Array<any>).map(GameRoundLineupViewFromJSON)),
    };
}

export function GameRoundViewToJSON(value?: GameRoundView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gameUid': value.gameUid,
        'lockToken': value.lockToken,
        'roundNumber': value.roundNumber,
        'status': GameRoundStatusToJSON(value.status),
        'allGameResultSet': value.allGameResultSet,
        'metrics': BaseMetricViewToJSON(value.metrics),
        'lineups': value.lineups === undefined ? undefined : ((value.lineups as Array<any>).map(GameRoundLineupViewToJSON)),
    };
}

