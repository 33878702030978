/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeviceSystem,
    DeviceSystemFromJSON,
    DeviceSystemFromJSONTyped,
    DeviceSystemToJSON,
} from './DeviceSystem';

/**
 * 
 * @export
 * @interface BaseMetricView
 */
export interface BaseMetricView {
    /**
     * 
     * @type {DeviceSystem}
     * @memberof BaseMetricView
     */
    system?: DeviceSystem;
}

export function BaseMetricViewFromJSON(json: any): BaseMetricView {
    return BaseMetricViewFromJSONTyped(json, false);
}

export function BaseMetricViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseMetricView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'system': !exists(json, 'system') ? undefined : DeviceSystemFromJSON(json['system']),
    };
}

export function BaseMetricViewToJSON(value?: BaseMetricView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'system': DeviceSystemToJSON(value.system),
    };
}

