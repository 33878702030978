/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AcceptInviteForGameRequest,
    AcceptInviteForGameRequestFromJSON,
    AcceptInviteForGameRequestToJSON,
    CreateGameLineupRequest,
    CreateGameLineupRequestFromJSON,
    CreateGameLineupRequestToJSON,
    CreateGameLineupResponse,
    CreateGameLineupResponseFromJSON,
    CreateGameLineupResponseToJSON,
    CreateInviteForGameRequest,
    CreateInviteForGameRequestFromJSON,
    CreateInviteForGameRequestToJSON,
    CreateInviteListForGameRequest,
    CreateInviteListForGameRequestFromJSON,
    CreateInviteListForGameRequestToJSON,
    DeleteGameLineupRequest,
    DeleteGameLineupRequestFromJSON,
    DeleteGameLineupRequestToJSON,
    DeviceSystem,
    DeviceSystemFromJSON,
    DeviceSystemToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ExitGameRequest,
    ExitGameRequestFromJSON,
    ExitGameRequestToJSON,
    ExtendGameLockRequest,
    ExtendGameLockRequestFromJSON,
    ExtendGameLockRequestToJSON,
    ExtendGameLockResponse,
    ExtendGameLockResponseFromJSON,
    ExtendGameLockResponseToJSON,
    GameBookedListTimeRequest,
    GameBookedListTimeRequestFromJSON,
    GameBookedListTimeRequestToJSON,
    GameBookedListTimeResponse,
    GameBookedListTimeResponseFromJSON,
    GameBookedListTimeResponseToJSON,
    GameCancelRequest,
    GameCancelRequestFromJSON,
    GameCancelRequestToJSON,
    GameLineupView,
    GameLineupViewFromJSON,
    GameLineupViewToJSON,
    GameLoadResponse,
    GameLoadResponseFromJSON,
    GameLoadResponseToJSON,
    GameMatchStatusRequest,
    GameMatchStatusRequestFromJSON,
    GameMatchStatusRequestToJSON,
    GameMatchStatusResponse,
    GameMatchStatusResponseFromJSON,
    GameMatchStatusResponseToJSON,
    GameResultReadyRequest,
    GameResultReadyRequestFromJSON,
    GameResultReadyRequestToJSON,
    GameRoundCreateResponse,
    GameRoundCreateResponseFromJSON,
    GameRoundCreateResponseToJSON,
    GameRoundDeleteRequest,
    GameRoundDeleteRequestFromJSON,
    GameRoundDeleteRequestToJSON,
    GameRoundLoadResponse,
    GameRoundLoadResponseFromJSON,
    GameRoundLoadResponseToJSON,
    GameRoundSaveRequest,
    GameRoundSaveRequestFromJSON,
    GameRoundSaveRequestToJSON,
    GameRoundSaveResponse,
    GameRoundSaveResponseFromJSON,
    GameRoundSaveResponseToJSON,
    GameSaveRequest,
    GameSaveRequestFromJSON,
    GameSaveRequestToJSON,
    GameSaveResponse,
    GameSaveResponseFromJSON,
    GameSaveResponseToJSON,
    GameScoringExistResponse,
    GameScoringExistResponseFromJSON,
    GameScoringExistResponseToJSON,
    GameScoringResponse,
    GameScoringResponseFromJSON,
    GameScoringResponseToJSON,
    GameTimeBookedRequest,
    GameTimeBookedRequestFromJSON,
    GameTimeBookedRequestToJSON,
    GameTimeBookedResponse,
    GameTimeBookedResponseFromJSON,
    GameTimeBookedResponseToJSON,
    GameTournamentFinishRequest,
    GameTournamentFinishRequestFromJSON,
    GameTournamentFinishRequestToJSON,
    LoadAndLockGameRequest,
    LoadAndLockGameRequestFromJSON,
    LoadAndLockGameRequestToJSON,
    LoadAndLockGameResponse,
    LoadAndLockGameResponseFromJSON,
    LoadAndLockGameResponseToJSON,
    LoadGameLineupResponse,
    LoadGameLineupResponseFromJSON,
    LoadGameLineupResponseToJSON,
    LoadGameRoundsPreviewResponse,
    LoadGameRoundsPreviewResponseFromJSON,
    LoadGameRoundsPreviewResponseToJSON,
    LoadGameTournamentStatisticsResponse,
    LoadGameTournamentStatisticsResponseFromJSON,
    LoadGameTournamentStatisticsResponseToJSON,
    LoadGameTournamentTableResponse,
    LoadGameTournamentTableResponseFromJSON,
    LoadGameTournamentTableResponseToJSON,
    LoadListGameInviteRequest,
    LoadListGameInviteRequestFromJSON,
    LoadListGameInviteRequestToJSON,
    LoadListGameInviteResponse,
    LoadListGameInviteResponseFromJSON,
    LoadListGameInviteResponseToJSON,
    LoadListGameLineupResponse,
    LoadListGameLineupResponseFromJSON,
    LoadListGameLineupResponseToJSON,
    LoadListGameRoundResponse,
    LoadListGameRoundResponseFromJSON,
    LoadListGameRoundResponseToJSON,
    LoadTourGameListRequest,
    LoadTourGameListRequestFromJSON,
    LoadTourGameListRequestToJSON,
    LoadTourGameListResponse,
    LoadTourGameListResponseFromJSON,
    LoadTourGameListResponseToJSON,
    MyPastTourGameLoadListResponse,
    MyPastTourGameLoadListResponseFromJSON,
    MyPastTourGameLoadListResponseToJSON,
    MyTourGameLoadListResponse,
    MyTourGameLoadListResponseFromJSON,
    MyTourGameLoadListResponseToJSON,
    OwnerEnterGameRequest,
    OwnerEnterGameRequestFromJSON,
    OwnerEnterGameRequestToJSON,
    ParticipantCreateGameInviteRequest,
    ParticipantCreateGameInviteRequestFromJSON,
    ParticipantCreateGameInviteRequestToJSON,
    PastGameListResponse,
    PastGameListResponseFromJSON,
    PastGameListResponseToJSON,
    RejectInviteForGameRequest,
    RejectInviteForGameRequestFromJSON,
    RejectInviteForGameRequestToJSON,
    SaveGameLineupRequest,
    SaveGameLineupRequestFromJSON,
    SaveGameLineupRequestToJSON,
    SaveGameLineupResponse,
    SaveGameLineupResponseFromJSON,
    SaveGameLineupResponseToJSON,
    SetPrivateGameRequest,
    SetPrivateGameRequestFromJSON,
    SetPrivateGameRequestToJSON,
    SetPrivateGameResponse,
    SetPrivateGameResponseFromJSON,
    SetPrivateGameResponseToJSON,
    StartGameTournamentRequest,
    StartGameTournamentRequestFromJSON,
    StartGameTournamentRequestToJSON,
    UnlockGameRequest,
    UnlockGameRequestFromJSON,
    UnlockGameRequestToJSON,
} from '../models';

export interface LundapadelGameApiAcceptGameInviteByQrRequest {
    gameUid: string;
    system: DeviceSystem;
}

export interface LundapadelGameApiAcceptInviteForGameOperationRequest {
    acceptInviteForGameRequest: AcceptInviteForGameRequest;
}

export interface LundapadelGameApiCancelGameInviteRequest {
    gameUid: string;
}

export interface LundapadelGameApiCreateGameLineupOperationRequest {
    createGameLineupRequest: CreateGameLineupRequest;
}

export interface LundapadelGameApiCreateInviteForGameOperationRequest {
    createInviteForGameRequest: CreateInviteForGameRequest;
}

export interface LundapadelGameApiCreateInviteListForGameOperationRequest {
    createInviteListForGameRequest: CreateInviteListForGameRequest;
}

export interface LundapadelGameApiDeleteGameLineupOperationRequest {
    deleteGameLineupRequest: DeleteGameLineupRequest;
}

export interface LundapadelGameApiExitGameOperationRequest {
    exitGameRequest: ExitGameRequest;
}

export interface LundapadelGameApiExtendGameLockOperationRequest {
    extendGameLockRequest: ExtendGameLockRequest;
}

export interface LundapadelGameApiGameBookedListTimeOperationRequest {
    gameBookedListTimeRequest: GameBookedListTimeRequest;
}

export interface LundapadelGameApiGameCancelOperationRequest {
    gameCancelRequest: GameCancelRequest;
}

export interface LundapadelGameApiGameLoadRequest {
    uid?: string;
    key?: string;
}

export interface LundapadelGameApiGameMatchStatusOperationRequest {
    gameMatchStatusRequest: GameMatchStatusRequest;
}

export interface LundapadelGameApiGameResultReadyOperationRequest {
    gameResultReadyRequest: GameResultReadyRequest;
}

export interface LundapadelGameApiGameRoundCreateRequest {
    gameUid: string;
    lockToken: string;
    system: DeviceSystem;
}

export interface LundapadelGameApiGameRoundDeleteOperationRequest {
    gameRoundDeleteRequest: GameRoundDeleteRequest;
}

export interface LundapadelGameApiGameRoundLoadRequest {
    gameUid: string;
    roundNumber: number;
}

export interface LundapadelGameApiGameRoundSaveOperationRequest {
    gameRoundSaveRequest: GameRoundSaveRequest;
}

export interface LundapadelGameApiGameSaveOperationRequest {
    gameSaveRequest: GameSaveRequest;
}

export interface LundapadelGameApiGameScoringRequest {
    gameUid: string;
    lineupUid: string;
    lockToken: string;
    lineup: GameLineupView;
}

export interface LundapadelGameApiGameTimeBookedOperationRequest {
    gameTimeBookedRequest: GameTimeBookedRequest;
}

export interface LundapadelGameApiGameTournamentFinishOperationRequest {
    gameTournamentFinishRequest: GameTournamentFinishRequest;
}

export interface LundapadelGameApiLoadAndLockGameOperationRequest {
    loadAndLockGameRequest: LoadAndLockGameRequest;
}

export interface LundapadelGameApiLoadGameLineupRequest {
    gameUid: string;
    lineupUid: string;
}

export interface LundapadelGameApiLoadGameRoundsPreviewRequest {
    gameUid: string;
}

export interface LundapadelGameApiLoadGameTournamentStatisticsRequest {
    gameUid: string;
}

export interface LundapadelGameApiLoadGameTournamentTableRequest {
    gameUid: string;
}

export interface LundapadelGameApiLoadListGameInviteOperationRequest {
    loadListGameInviteRequest: LoadListGameInviteRequest;
}

export interface LundapadelGameApiLoadListGameLineupRequest {
    gameUid: string;
}

export interface LundapadelGameApiLoadListGameRoundRequest {
    gameUid: string;
}

export interface LundapadelGameApiLoadTourGameListOperationRequest {
    loadTourGameListRequest: LoadTourGameListRequest;
}

export interface LundapadelGameApiMyPastTourGameLoadListRequest {
    offset: number;
    limit: number;
    ownOnly: boolean;
    showCanceled: boolean;
}

export interface LundapadelGameApiMyTourGameLoadListRequest {
    offset: number;
    limit: number;
    ownOnly: boolean;
}

export interface LundapadelGameApiOwnerEnterGameOperationRequest {
    ownerEnterGameRequest: OwnerEnterGameRequest;
}

export interface LundapadelGameApiParticipantCreateGameInviteOperationRequest {
    participantCreateGameInviteRequest: ParticipantCreateGameInviteRequest;
}

export interface LundapadelGameApiPastGameListRequest {
    playerUid: string;
    offset: number;
    limit: number;
}

export interface LundapadelGameApiRejectInviteForGameOperationRequest {
    rejectInviteForGameRequest: RejectInviteForGameRequest;
}

export interface LundapadelGameApiSaveGameLineupOperationRequest {
    saveGameLineupRequest: SaveGameLineupRequest;
}

export interface LundapadelGameApiSetPrivateGameOperationRequest {
    setPrivateGameRequest: SetPrivateGameRequest;
}

export interface LundapadelGameApiStartGameTournamentOperationRequest {
    startGameTournamentRequest: StartGameTournamentRequest;
}

export interface LundapadelGameApiUnlockGameOperationRequest {
    unlockGameRequest: UnlockGameRequest;
}

/**
 * 
 */
export class LundapadelGameApi extends runtime.BaseAPI {

    /**
     * Accept invite by QR-code
     */
    async acceptGameInviteByQrRaw(requestParameters: LundapadelGameApiAcceptGameInviteByQrRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.gameUid === null || requestParameters.gameUid === undefined) {
            throw new runtime.RequiredError('gameUid','Required parameter requestParameters.gameUid was null or undefined when calling acceptGameInviteByQr.');
        }

        if (requestParameters.system === null || requestParameters.system === undefined) {
            throw new runtime.RequiredError('system','Required parameter requestParameters.system was null or undefined when calling acceptGameInviteByQr.');
        }

        const queryParameters: any = {};

        if (requestParameters.gameUid !== undefined) {
            queryParameters['gameUid'] = requestParameters.gameUid;
        }

        if (requestParameters.system !== undefined) {
            queryParameters['system'] = requestParameters.system;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/invite/game/accept-qr`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Accept invite by QR-code
     */
    async acceptGameInviteByQr(requestParameters: LundapadelGameApiAcceptGameInviteByQrRequest, initOverrides?: RequestInit): Promise<void> {
        await this.acceptGameInviteByQrRaw(requestParameters, initOverrides);
    }

    /**
     * Accept invite
     */
    async acceptInviteForGameRaw(requestParameters: LundapadelGameApiAcceptInviteForGameOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.acceptInviteForGameRequest === null || requestParameters.acceptInviteForGameRequest === undefined) {
            throw new runtime.RequiredError('acceptInviteForGameRequest','Required parameter requestParameters.acceptInviteForGameRequest was null or undefined when calling acceptInviteForGame.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/invite/game/accept`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AcceptInviteForGameRequestToJSON(requestParameters.acceptInviteForGameRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Accept invite
     */
    async acceptInviteForGame(requestParameters: LundapadelGameApiAcceptInviteForGameOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.acceptInviteForGameRaw(requestParameters, initOverrides);
    }

    /**
     * Player cancel invite
     */
    async cancelGameInviteRaw(requestParameters: LundapadelGameApiCancelGameInviteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.gameUid === null || requestParameters.gameUid === undefined) {
            throw new runtime.RequiredError('gameUid','Required parameter requestParameters.gameUid was null or undefined when calling cancelGameInvite.');
        }

        const queryParameters: any = {};

        if (requestParameters.gameUid !== undefined) {
            queryParameters['gameUid'] = requestParameters.gameUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/invite/game/cancel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Player cancel invite
     */
    async cancelGameInvite(requestParameters: LundapadelGameApiCancelGameInviteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.cancelGameInviteRaw(requestParameters, initOverrides);
    }

    /**
     * Create lineup for game
     */
    async createGameLineupRaw(requestParameters: LundapadelGameApiCreateGameLineupOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreateGameLineupResponse>> {
        if (requestParameters.createGameLineupRequest === null || requestParameters.createGameLineupRequest === undefined) {
            throw new runtime.RequiredError('createGameLineupRequest','Required parameter requestParameters.createGameLineupRequest was null or undefined when calling createGameLineup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game/create-lineup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateGameLineupRequestToJSON(requestParameters.createGameLineupRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateGameLineupResponseFromJSON(jsonValue));
    }

    /**
     * Create lineup for game
     */
    async createGameLineup(requestParameters: LundapadelGameApiCreateGameLineupOperationRequest, initOverrides?: RequestInit): Promise<CreateGameLineupResponse> {
        const response = await this.createGameLineupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create invite for game
     */
    async createInviteForGameRaw(requestParameters: LundapadelGameApiCreateInviteForGameOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createInviteForGameRequest === null || requestParameters.createInviteForGameRequest === undefined) {
            throw new runtime.RequiredError('createInviteForGameRequest','Required parameter requestParameters.createInviteForGameRequest was null or undefined when calling createInviteForGame.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/invite/game/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateInviteForGameRequestToJSON(requestParameters.createInviteForGameRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create invite for game
     */
    async createInviteForGame(requestParameters: LundapadelGameApiCreateInviteForGameOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.createInviteForGameRaw(requestParameters, initOverrides);
    }

    /**
     * Load list invite for entity
     */
    async createInviteListForGameRaw(requestParameters: LundapadelGameApiCreateInviteListForGameOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createInviteListForGameRequest === null || requestParameters.createInviteListForGameRequest === undefined) {
            throw new runtime.RequiredError('createInviteListForGameRequest','Required parameter requestParameters.createInviteListForGameRequest was null or undefined when calling createInviteListForGame.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game/create-invite-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateInviteListForGameRequestToJSON(requestParameters.createInviteListForGameRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Load list invite for entity
     */
    async createInviteListForGame(requestParameters: LundapadelGameApiCreateInviteListForGameOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.createInviteListForGameRaw(requestParameters, initOverrides);
    }

    /**
     * Delete lineup
     */
    async deleteGameLineupRaw(requestParameters: LundapadelGameApiDeleteGameLineupOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deleteGameLineupRequest === null || requestParameters.deleteGameLineupRequest === undefined) {
            throw new runtime.RequiredError('deleteGameLineupRequest','Required parameter requestParameters.deleteGameLineupRequest was null or undefined when calling deleteGameLineup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game/delete-lineup`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteGameLineupRequestToJSON(requestParameters.deleteGameLineupRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete lineup
     */
    async deleteGameLineup(requestParameters: LundapadelGameApiDeleteGameLineupOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteGameLineupRaw(requestParameters, initOverrides);
    }

    /**
     * Player leaves the game
     */
    async exitGameRaw(requestParameters: LundapadelGameApiExitGameOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.exitGameRequest === null || requestParameters.exitGameRequest === undefined) {
            throw new runtime.RequiredError('exitGameRequest','Required parameter requestParameters.exitGameRequest was null or undefined when calling exitGame.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game/exit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExitGameRequestToJSON(requestParameters.exitGameRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Player leaves the game
     */
    async exitGame(requestParameters: LundapadelGameApiExitGameOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.exitGameRaw(requestParameters, initOverrides);
    }

    /**
     * Extend lock game
     */
    async extendGameLockRaw(requestParameters: LundapadelGameApiExtendGameLockOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExtendGameLockResponse>> {
        if (requestParameters.extendGameLockRequest === null || requestParameters.extendGameLockRequest === undefined) {
            throw new runtime.RequiredError('extendGameLockRequest','Required parameter requestParameters.extendGameLockRequest was null or undefined when calling extendGameLock.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game/extend-lock`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExtendGameLockRequestToJSON(requestParameters.extendGameLockRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendGameLockResponseFromJSON(jsonValue));
    }

    /**
     * Extend lock game
     */
    async extendGameLock(requestParameters: LundapadelGameApiExtendGameLockOperationRequest, initOverrides?: RequestInit): Promise<ExtendGameLockResponse> {
        const response = await this.extendGameLockRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load list of time booked for player
     */
    async gameBookedListTimeRaw(requestParameters: LundapadelGameApiGameBookedListTimeOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GameBookedListTimeResponse>> {
        if (requestParameters.gameBookedListTimeRequest === null || requestParameters.gameBookedListTimeRequest === undefined) {
            throw new runtime.RequiredError('gameBookedListTimeRequest','Required parameter requestParameters.gameBookedListTimeRequest was null or undefined when calling gameBookedListTime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game/date-booked-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GameBookedListTimeRequestToJSON(requestParameters.gameBookedListTimeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameBookedListTimeResponseFromJSON(jsonValue));
    }

    /**
     * Load list of time booked for player
     */
    async gameBookedListTime(requestParameters: LundapadelGameApiGameBookedListTimeOperationRequest, initOverrides?: RequestInit): Promise<GameBookedListTimeResponse> {
        const response = await this.gameBookedListTimeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancel game
     */
    async gameCancelRaw(requestParameters: LundapadelGameApiGameCancelOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.gameCancelRequest === null || requestParameters.gameCancelRequest === undefined) {
            throw new runtime.RequiredError('gameCancelRequest','Required parameter requestParameters.gameCancelRequest was null or undefined when calling gameCancel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game/cancel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GameCancelRequestToJSON(requestParameters.gameCancelRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancel game
     */
    async gameCancel(requestParameters: LundapadelGameApiGameCancelOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.gameCancelRaw(requestParameters, initOverrides);
    }

    /**
     * Load game
     */
    async gameLoadRaw(requestParameters: LundapadelGameApiGameLoadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GameLoadResponse>> {
        const queryParameters: any = {};

        if (requestParameters.uid !== undefined) {
            queryParameters['uid'] = requestParameters.uid;
        }

        if (requestParameters.key !== undefined) {
            queryParameters['key'] = requestParameters.key;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/game/load`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameLoadResponseFromJSON(jsonValue));
    }

    /**
     * Load game
     */
    async gameLoad(requestParameters: LundapadelGameApiGameLoadRequest = {}, initOverrides?: RequestInit): Promise<GameLoadResponse> {
        const response = await this.gameLoadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check match view belong to the match or not
     */
    async gameMatchStatusRaw(requestParameters: LundapadelGameApiGameMatchStatusOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GameMatchStatusResponse>> {
        if (requestParameters.gameMatchStatusRequest === null || requestParameters.gameMatchStatusRequest === undefined) {
            throw new runtime.RequiredError('gameMatchStatusRequest','Required parameter requestParameters.gameMatchStatusRequest was null or undefined when calling gameMatchStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game/match-status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GameMatchStatusRequestToJSON(requestParameters.gameMatchStatusRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameMatchStatusResponseFromJSON(jsonValue));
    }

    /**
     * Check match view belong to the match or not
     */
    async gameMatchStatus(requestParameters: LundapadelGameApiGameMatchStatusOperationRequest, initOverrides?: RequestInit): Promise<GameMatchStatusResponse> {
        const response = await this.gameMatchStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Player submits the game results
     */
    async gameResultReadyRaw(requestParameters: LundapadelGameApiGameResultReadyOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.gameResultReadyRequest === null || requestParameters.gameResultReadyRequest === undefined) {
            throw new runtime.RequiredError('gameResultReadyRequest','Required parameter requestParameters.gameResultReadyRequest was null or undefined when calling gameResultReady.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game/result-ready`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GameResultReadyRequestToJSON(requestParameters.gameResultReadyRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Player submits the game results
     */
    async gameResultReady(requestParameters: LundapadelGameApiGameResultReadyOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.gameResultReadyRaw(requestParameters, initOverrides);
    }

    /**
     * Create round for game
     */
    async gameRoundCreateRaw(requestParameters: LundapadelGameApiGameRoundCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GameRoundCreateResponse>> {
        if (requestParameters.gameUid === null || requestParameters.gameUid === undefined) {
            throw new runtime.RequiredError('gameUid','Required parameter requestParameters.gameUid was null or undefined when calling gameRoundCreate.');
        }

        if (requestParameters.lockToken === null || requestParameters.lockToken === undefined) {
            throw new runtime.RequiredError('lockToken','Required parameter requestParameters.lockToken was null or undefined when calling gameRoundCreate.');
        }

        if (requestParameters.system === null || requestParameters.system === undefined) {
            throw new runtime.RequiredError('system','Required parameter requestParameters.system was null or undefined when calling gameRoundCreate.');
        }

        const queryParameters: any = {};

        if (requestParameters.gameUid !== undefined) {
            queryParameters['gameUid'] = requestParameters.gameUid;
        }

        if (requestParameters.lockToken !== undefined) {
            queryParameters['lockToken'] = requestParameters.lockToken;
        }

        if (requestParameters.system !== undefined) {
            queryParameters['system'] = requestParameters.system;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/game/round-create`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameRoundCreateResponseFromJSON(jsonValue));
    }

    /**
     * Create round for game
     */
    async gameRoundCreate(requestParameters: LundapadelGameApiGameRoundCreateRequest, initOverrides?: RequestInit): Promise<GameRoundCreateResponse> {
        const response = await this.gameRoundCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove round for game
     */
    async gameRoundDeleteRaw(requestParameters: LundapadelGameApiGameRoundDeleteOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.gameRoundDeleteRequest === null || requestParameters.gameRoundDeleteRequest === undefined) {
            throw new runtime.RequiredError('gameRoundDeleteRequest','Required parameter requestParameters.gameRoundDeleteRequest was null or undefined when calling gameRoundDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game/round-delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GameRoundDeleteRequestToJSON(requestParameters.gameRoundDeleteRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove round for game
     */
    async gameRoundDelete(requestParameters: LundapadelGameApiGameRoundDeleteOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.gameRoundDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Load round for game
     */
    async gameRoundLoadRaw(requestParameters: LundapadelGameApiGameRoundLoadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GameRoundLoadResponse>> {
        if (requestParameters.gameUid === null || requestParameters.gameUid === undefined) {
            throw new runtime.RequiredError('gameUid','Required parameter requestParameters.gameUid was null or undefined when calling gameRoundLoad.');
        }

        if (requestParameters.roundNumber === null || requestParameters.roundNumber === undefined) {
            throw new runtime.RequiredError('roundNumber','Required parameter requestParameters.roundNumber was null or undefined when calling gameRoundLoad.');
        }

        const queryParameters: any = {};

        if (requestParameters.gameUid !== undefined) {
            queryParameters['gameUid'] = requestParameters.gameUid;
        }

        if (requestParameters.roundNumber !== undefined) {
            queryParameters['roundNumber'] = requestParameters.roundNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/game/round-load`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameRoundLoadResponseFromJSON(jsonValue));
    }

    /**
     * Load round for game
     */
    async gameRoundLoad(requestParameters: LundapadelGameApiGameRoundLoadRequest, initOverrides?: RequestInit): Promise<GameRoundLoadResponse> {
        const response = await this.gameRoundLoadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Save round for game
     */
    async gameRoundSaveRaw(requestParameters: LundapadelGameApiGameRoundSaveOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GameRoundSaveResponse>> {
        if (requestParameters.gameRoundSaveRequest === null || requestParameters.gameRoundSaveRequest === undefined) {
            throw new runtime.RequiredError('gameRoundSaveRequest','Required parameter requestParameters.gameRoundSaveRequest was null or undefined when calling gameRoundSave.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game/round-save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GameRoundSaveRequestToJSON(requestParameters.gameRoundSaveRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameRoundSaveResponseFromJSON(jsonValue));
    }

    /**
     * Save round for game
     */
    async gameRoundSave(requestParameters: LundapadelGameApiGameRoundSaveOperationRequest, initOverrides?: RequestInit): Promise<GameRoundSaveResponse> {
        const response = await this.gameRoundSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Saving match
     */
    async gameSaveRaw(requestParameters: LundapadelGameApiGameSaveOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GameSaveResponse>> {
        if (requestParameters.gameSaveRequest === null || requestParameters.gameSaveRequest === undefined) {
            throw new runtime.RequiredError('gameSaveRequest','Required parameter requestParameters.gameSaveRequest was null or undefined when calling gameSave.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GameSaveRequestToJSON(requestParameters.gameSaveRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameSaveResponseFromJSON(jsonValue));
    }

    /**
     * Saving match
     */
    async gameSave(requestParameters: LundapadelGameApiGameSaveOperationRequest, initOverrides?: RequestInit): Promise<GameSaveResponse> {
        const response = await this.gameSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Participant write score in lineup
     */
    async gameScoringRaw(requestParameters: LundapadelGameApiGameScoringRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GameScoringResponse>> {
        if (requestParameters.gameUid === null || requestParameters.gameUid === undefined) {
            throw new runtime.RequiredError('gameUid','Required parameter requestParameters.gameUid was null or undefined when calling gameScoring.');
        }

        if (requestParameters.lineupUid === null || requestParameters.lineupUid === undefined) {
            throw new runtime.RequiredError('lineupUid','Required parameter requestParameters.lineupUid was null or undefined when calling gameScoring.');
        }

        if (requestParameters.lockToken === null || requestParameters.lockToken === undefined) {
            throw new runtime.RequiredError('lockToken','Required parameter requestParameters.lockToken was null or undefined when calling gameScoring.');
        }

        if (requestParameters.lineup === null || requestParameters.lineup === undefined) {
            throw new runtime.RequiredError('lineup','Required parameter requestParameters.lineup was null or undefined when calling gameScoring.');
        }

        const queryParameters: any = {};

        if (requestParameters.gameUid !== undefined) {
            queryParameters['gameUid'] = requestParameters.gameUid;
        }

        if (requestParameters.lineupUid !== undefined) {
            queryParameters['lineupUid'] = requestParameters.lineupUid;
        }

        if (requestParameters.lockToken !== undefined) {
            queryParameters['lockToken'] = requestParameters.lockToken;
        }

        if (requestParameters.lineup !== undefined) {
            queryParameters['lineup'] = requestParameters.lineup;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/game/scoring`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameScoringResponseFromJSON(jsonValue));
    }

    /**
     * Participant write score in lineup
     */
    async gameScoring(requestParameters: LundapadelGameApiGameScoringRequest, initOverrides?: RequestInit): Promise<GameScoringResponse> {
        const response = await this.gameScoringRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check if there are any games which player can post scoring
     */
    async gameScoringExistRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GameScoringExistResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/game/scoring-exist`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameScoringExistResponseFromJSON(jsonValue));
    }

    /**
     * Check if there are any games which player can post scoring
     */
    async gameScoringExist(initOverrides?: RequestInit): Promise<GameScoringExistResponse> {
        const response = await this.gameScoringExistRaw(initOverrides);
        return await response.value();
    }

    /**
     * Time booked for player
     */
    async gameTimeBookedRaw(requestParameters: LundapadelGameApiGameTimeBookedOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GameTimeBookedResponse>> {
        if (requestParameters.gameTimeBookedRequest === null || requestParameters.gameTimeBookedRequest === undefined) {
            throw new runtime.RequiredError('gameTimeBookedRequest','Required parameter requestParameters.gameTimeBookedRequest was null or undefined when calling gameTimeBooked.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game/time-booked`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GameTimeBookedRequestToJSON(requestParameters.gameTimeBookedRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameTimeBookedResponseFromJSON(jsonValue));
    }

    /**
     * Time booked for player
     */
    async gameTimeBooked(requestParameters: LundapadelGameApiGameTimeBookedOperationRequest, initOverrides?: RequestInit): Promise<GameTimeBookedResponse> {
        const response = await this.gameTimeBookedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Finish game-tournament
     */
    async gameTournamentFinishRaw(requestParameters: LundapadelGameApiGameTournamentFinishOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.gameTournamentFinishRequest === null || requestParameters.gameTournamentFinishRequest === undefined) {
            throw new runtime.RequiredError('gameTournamentFinishRequest','Required parameter requestParameters.gameTournamentFinishRequest was null or undefined when calling gameTournamentFinish.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game/tournament-finish`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GameTournamentFinishRequestToJSON(requestParameters.gameTournamentFinishRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Finish game-tournament
     */
    async gameTournamentFinish(requestParameters: LundapadelGameApiGameTournamentFinishOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.gameTournamentFinishRaw(requestParameters, initOverrides);
    }

    /**
     * Load and lock game
     */
    async loadAndLockGameRaw(requestParameters: LundapadelGameApiLoadAndLockGameOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadAndLockGameResponse>> {
        if (requestParameters.loadAndLockGameRequest === null || requestParameters.loadAndLockGameRequest === undefined) {
            throw new runtime.RequiredError('loadAndLockGameRequest','Required parameter requestParameters.loadAndLockGameRequest was null or undefined when calling loadAndLockGame.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game/load-lock`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoadAndLockGameRequestToJSON(requestParameters.loadAndLockGameRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadAndLockGameResponseFromJSON(jsonValue));
    }

    /**
     * Load and lock game
     */
    async loadAndLockGame(requestParameters: LundapadelGameApiLoadAndLockGameOperationRequest, initOverrides?: RequestInit): Promise<LoadAndLockGameResponse> {
        const response = await this.loadAndLockGameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load lineup
     */
    async loadGameLineupRaw(requestParameters: LundapadelGameApiLoadGameLineupRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadGameLineupResponse>> {
        if (requestParameters.gameUid === null || requestParameters.gameUid === undefined) {
            throw new runtime.RequiredError('gameUid','Required parameter requestParameters.gameUid was null or undefined when calling loadGameLineup.');
        }

        if (requestParameters.lineupUid === null || requestParameters.lineupUid === undefined) {
            throw new runtime.RequiredError('lineupUid','Required parameter requestParameters.lineupUid was null or undefined when calling loadGameLineup.');
        }

        const queryParameters: any = {};

        if (requestParameters.gameUid !== undefined) {
            queryParameters['gameUid'] = requestParameters.gameUid;
        }

        if (requestParameters.lineupUid !== undefined) {
            queryParameters['lineupUid'] = requestParameters.lineupUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/game/load-lineup`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadGameLineupResponseFromJSON(jsonValue));
    }

    /**
     * Load lineup
     */
    async loadGameLineup(requestParameters: LundapadelGameApiLoadGameLineupRequest, initOverrides?: RequestInit): Promise<LoadGameLineupResponse> {
        const response = await this.loadGameLineupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load mini-tournament rounds preview
     */
    async loadGameRoundsPreviewRaw(requestParameters: LundapadelGameApiLoadGameRoundsPreviewRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadGameRoundsPreviewResponse>> {
        if (requestParameters.gameUid === null || requestParameters.gameUid === undefined) {
            throw new runtime.RequiredError('gameUid','Required parameter requestParameters.gameUid was null or undefined when calling loadGameRoundsPreview.');
        }

        const queryParameters: any = {};

        if (requestParameters.gameUid !== undefined) {
            queryParameters['gameUid'] = requestParameters.gameUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/game/load-rounds-preview`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadGameRoundsPreviewResponseFromJSON(jsonValue));
    }

    /**
     * Load mini-tournament rounds preview
     */
    async loadGameRoundsPreview(requestParameters: LundapadelGameApiLoadGameRoundsPreviewRequest, initOverrides?: RequestInit): Promise<LoadGameRoundsPreviewResponse> {
        const response = await this.loadGameRoundsPreviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load game-tournament statistics
     */
    async loadGameTournamentStatisticsRaw(requestParameters: LundapadelGameApiLoadGameTournamentStatisticsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadGameTournamentStatisticsResponse>> {
        if (requestParameters.gameUid === null || requestParameters.gameUid === undefined) {
            throw new runtime.RequiredError('gameUid','Required parameter requestParameters.gameUid was null or undefined when calling loadGameTournamentStatistics.');
        }

        const queryParameters: any = {};

        if (requestParameters.gameUid !== undefined) {
            queryParameters['gameUid'] = requestParameters.gameUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/game/tournament-statistics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadGameTournamentStatisticsResponseFromJSON(jsonValue));
    }

    /**
     * Load game-tournament statistics
     */
    async loadGameTournamentStatistics(requestParameters: LundapadelGameApiLoadGameTournamentStatisticsRequest, initOverrides?: RequestInit): Promise<LoadGameTournamentStatisticsResponse> {
        const response = await this.loadGameTournamentStatisticsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load tournament table
     */
    async loadGameTournamentTableRaw(requestParameters: LundapadelGameApiLoadGameTournamentTableRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadGameTournamentTableResponse>> {
        if (requestParameters.gameUid === null || requestParameters.gameUid === undefined) {
            throw new runtime.RequiredError('gameUid','Required parameter requestParameters.gameUid was null or undefined when calling loadGameTournamentTable.');
        }

        const queryParameters: any = {};

        if (requestParameters.gameUid !== undefined) {
            queryParameters['gameUid'] = requestParameters.gameUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/game/load-table`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadGameTournamentTableResponseFromJSON(jsonValue));
    }

    /**
     * Load tournament table
     */
    async loadGameTournamentTable(requestParameters: LundapadelGameApiLoadGameTournamentTableRequest, initOverrides?: RequestInit): Promise<LoadGameTournamentTableResponse> {
        const response = await this.loadGameTournamentTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load list invite for game
     */
    async loadListGameInviteRaw(requestParameters: LundapadelGameApiLoadListGameInviteOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadListGameInviteResponse>> {
        if (requestParameters.loadListGameInviteRequest === null || requestParameters.loadListGameInviteRequest === undefined) {
            throw new runtime.RequiredError('loadListGameInviteRequest','Required parameter requestParameters.loadListGameInviteRequest was null or undefined when calling loadListGameInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/invite/game/load-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoadListGameInviteRequestToJSON(requestParameters.loadListGameInviteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadListGameInviteResponseFromJSON(jsonValue));
    }

    /**
     * Load list invite for game
     */
    async loadListGameInvite(requestParameters: LundapadelGameApiLoadListGameInviteOperationRequest, initOverrides?: RequestInit): Promise<LoadListGameInviteResponse> {
        const response = await this.loadListGameInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load list lineup for game
     */
    async loadListGameLineupRaw(requestParameters: LundapadelGameApiLoadListGameLineupRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadListGameLineupResponse>> {
        if (requestParameters.gameUid === null || requestParameters.gameUid === undefined) {
            throw new runtime.RequiredError('gameUid','Required parameter requestParameters.gameUid was null or undefined when calling loadListGameLineup.');
        }

        const queryParameters: any = {};

        if (requestParameters.gameUid !== undefined) {
            queryParameters['gameUid'] = requestParameters.gameUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/game/lineup-load-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadListGameLineupResponseFromJSON(jsonValue));
    }

    /**
     * Load list lineup for game
     */
    async loadListGameLineup(requestParameters: LundapadelGameApiLoadListGameLineupRequest, initOverrides?: RequestInit): Promise<LoadListGameLineupResponse> {
        const response = await this.loadListGameLineupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load list round for game
     */
    async loadListGameRoundRaw(requestParameters: LundapadelGameApiLoadListGameRoundRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadListGameRoundResponse>> {
        if (requestParameters.gameUid === null || requestParameters.gameUid === undefined) {
            throw new runtime.RequiredError('gameUid','Required parameter requestParameters.gameUid was null or undefined when calling loadListGameRound.');
        }

        const queryParameters: any = {};

        if (requestParameters.gameUid !== undefined) {
            queryParameters['gameUid'] = requestParameters.gameUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/game/round-load-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadListGameRoundResponseFromJSON(jsonValue));
    }

    /**
     * Load list round for game
     */
    async loadListGameRound(requestParameters: LundapadelGameApiLoadListGameRoundRequest, initOverrides?: RequestInit): Promise<LoadListGameRoundResponse> {
        const response = await this.loadListGameRoundRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load game and tournament entity list
     */
    async loadTourGameListRaw(requestParameters: LundapadelGameApiLoadTourGameListOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadTourGameListResponse>> {
        if (requestParameters.loadTourGameListRequest === null || requestParameters.loadTourGameListRequest === undefined) {
            throw new runtime.RequiredError('loadTourGameListRequest','Required parameter requestParameters.loadTourGameListRequest was null or undefined when calling loadTourGameList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tourgame/game-load-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoadTourGameListRequestToJSON(requestParameters.loadTourGameListRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadTourGameListResponseFromJSON(jsonValue));
    }

    /**
     * Load game and tournament entity list
     */
    async loadTourGameList(requestParameters: LundapadelGameApiLoadTourGameListOperationRequest, initOverrides?: RequestInit): Promise<LoadTourGameListResponse> {
        const response = await this.loadTourGameListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load game and tournament entity list
     */
    async myPastTourGameLoadListRaw(requestParameters: LundapadelGameApiMyPastTourGameLoadListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MyPastTourGameLoadListResponse>> {
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling myPastTourGameLoadList.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling myPastTourGameLoadList.');
        }

        if (requestParameters.ownOnly === null || requestParameters.ownOnly === undefined) {
            throw new runtime.RequiredError('ownOnly','Required parameter requestParameters.ownOnly was null or undefined when calling myPastTourGameLoadList.');
        }

        if (requestParameters.showCanceled === null || requestParameters.showCanceled === undefined) {
            throw new runtime.RequiredError('showCanceled','Required parameter requestParameters.showCanceled was null or undefined when calling myPastTourGameLoadList.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.ownOnly !== undefined) {
            queryParameters['ownOnly'] = requestParameters.ownOnly;
        }

        if (requestParameters.showCanceled !== undefined) {
            queryParameters['showCanceled'] = requestParameters.showCanceled;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tourgame/my-past-load-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MyPastTourGameLoadListResponseFromJSON(jsonValue));
    }

    /**
     * Load game and tournament entity list
     */
    async myPastTourGameLoadList(requestParameters: LundapadelGameApiMyPastTourGameLoadListRequest, initOverrides?: RequestInit): Promise<MyPastTourGameLoadListResponse> {
        const response = await this.myPastTourGameLoadListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load game and tournament entity list
     */
    async myTourGameLoadListRaw(requestParameters: LundapadelGameApiMyTourGameLoadListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MyTourGameLoadListResponse>> {
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling myTourGameLoadList.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling myTourGameLoadList.');
        }

        if (requestParameters.ownOnly === null || requestParameters.ownOnly === undefined) {
            throw new runtime.RequiredError('ownOnly','Required parameter requestParameters.ownOnly was null or undefined when calling myTourGameLoadList.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.ownOnly !== undefined) {
            queryParameters['ownOnly'] = requestParameters.ownOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tourgame/my-load-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MyTourGameLoadListResponseFromJSON(jsonValue));
    }

    /**
     * Load game and tournament entity list
     */
    async myTourGameLoadList(requestParameters: LundapadelGameApiMyTourGameLoadListRequest, initOverrides?: RequestInit): Promise<MyTourGameLoadListResponse> {
        const response = await this.myTourGameLoadListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Entering coach in the own game
     */
    async ownerEnterGameRaw(requestParameters: LundapadelGameApiOwnerEnterGameOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.ownerEnterGameRequest === null || requestParameters.ownerEnterGameRequest === undefined) {
            throw new runtime.RequiredError('ownerEnterGameRequest','Required parameter requestParameters.ownerEnterGameRequest was null or undefined when calling ownerEnterGame.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game/owner-enter-game`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OwnerEnterGameRequestToJSON(requestParameters.ownerEnterGameRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Entering coach in the own game
     */
    async ownerEnterGame(requestParameters: LundapadelGameApiOwnerEnterGameOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.ownerEnterGameRaw(requestParameters, initOverrides);
    }

    /**
     * Participant create invite for game
     */
    async participantCreateGameInviteRaw(requestParameters: LundapadelGameApiParticipantCreateGameInviteOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.participantCreateGameInviteRequest === null || requestParameters.participantCreateGameInviteRequest === undefined) {
            throw new runtime.RequiredError('participantCreateGameInviteRequest','Required parameter requestParameters.participantCreateGameInviteRequest was null or undefined when calling participantCreateGameInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/invite/game-invite-by-participant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ParticipantCreateGameInviteRequestToJSON(requestParameters.participantCreateGameInviteRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Participant create invite for game
     */
    async participantCreateGameInvite(requestParameters: LundapadelGameApiParticipantCreateGameInviteOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.participantCreateGameInviteRaw(requestParameters, initOverrides);
    }

    /**
     * Load list past game for profile
     */
    async pastGameListRaw(requestParameters: LundapadelGameApiPastGameListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PastGameListResponse>> {
        if (requestParameters.playerUid === null || requestParameters.playerUid === undefined) {
            throw new runtime.RequiredError('playerUid','Required parameter requestParameters.playerUid was null or undefined when calling pastGameList.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling pastGameList.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling pastGameList.');
        }

        const queryParameters: any = {};

        if (requestParameters.playerUid !== undefined) {
            queryParameters['playerUid'] = requestParameters.playerUid;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/game/past`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PastGameListResponseFromJSON(jsonValue));
    }

    /**
     * Load list past game for profile
     */
    async pastGameList(requestParameters: LundapadelGameApiPastGameListRequest, initOverrides?: RequestInit): Promise<PastGameListResponse> {
        const response = await this.pastGameListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Owner reject his invite
     */
    async rejectInviteForGameRaw(requestParameters: LundapadelGameApiRejectInviteForGameOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.rejectInviteForGameRequest === null || requestParameters.rejectInviteForGameRequest === undefined) {
            throw new runtime.RequiredError('rejectInviteForGameRequest','Required parameter requestParameters.rejectInviteForGameRequest was null or undefined when calling rejectInviteForGame.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/invite/game/reject`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RejectInviteForGameRequestToJSON(requestParameters.rejectInviteForGameRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Owner reject his invite
     */
    async rejectInviteForGame(requestParameters: LundapadelGameApiRejectInviteForGameOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.rejectInviteForGameRaw(requestParameters, initOverrides);
    }

    /**
     * Save lineup
     */
    async saveGameLineupRaw(requestParameters: LundapadelGameApiSaveGameLineupOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SaveGameLineupResponse>> {
        if (requestParameters.saveGameLineupRequest === null || requestParameters.saveGameLineupRequest === undefined) {
            throw new runtime.RequiredError('saveGameLineupRequest','Required parameter requestParameters.saveGameLineupRequest was null or undefined when calling saveGameLineup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game/save-lineup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaveGameLineupRequestToJSON(requestParameters.saveGameLineupRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SaveGameLineupResponseFromJSON(jsonValue));
    }

    /**
     * Save lineup
     */
    async saveGameLineup(requestParameters: LundapadelGameApiSaveGameLineupOperationRequest, initOverrides?: RequestInit): Promise<SaveGameLineupResponse> {
        const response = await this.saveGameLineupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set match private
     */
    async setPrivateGameRaw(requestParameters: LundapadelGameApiSetPrivateGameOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SetPrivateGameResponse>> {
        if (requestParameters.setPrivateGameRequest === null || requestParameters.setPrivateGameRequest === undefined) {
            throw new runtime.RequiredError('setPrivateGameRequest','Required parameter requestParameters.setPrivateGameRequest was null or undefined when calling setPrivateGame.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game/set-private`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPrivateGameRequestToJSON(requestParameters.setPrivateGameRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetPrivateGameResponseFromJSON(jsonValue));
    }

    /**
     * Set match private
     */
    async setPrivateGame(requestParameters: LundapadelGameApiSetPrivateGameOperationRequest, initOverrides?: RequestInit): Promise<SetPrivateGameResponse> {
        const response = await this.setPrivateGameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Start mini-tournament
     */
    async startGameTournamentRaw(requestParameters: LundapadelGameApiStartGameTournamentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.startGameTournamentRequest === null || requestParameters.startGameTournamentRequest === undefined) {
            throw new runtime.RequiredError('startGameTournamentRequest','Required parameter requestParameters.startGameTournamentRequest was null or undefined when calling startGameTournament.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game/tournament-start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StartGameTournamentRequestToJSON(requestParameters.startGameTournamentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Start mini-tournament
     */
    async startGameTournament(requestParameters: LundapadelGameApiStartGameTournamentOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.startGameTournamentRaw(requestParameters, initOverrides);
    }

    /**
     * Extend lock game
     */
    async unlockGameRaw(requestParameters: LundapadelGameApiUnlockGameOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.unlockGameRequest === null || requestParameters.unlockGameRequest === undefined) {
            throw new runtime.RequiredError('unlockGameRequest','Required parameter requestParameters.unlockGameRequest was null or undefined when calling unlockGame.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game/unlock`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnlockGameRequestToJSON(requestParameters.unlockGameRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Extend lock game
     */
    async unlockGame(requestParameters: LundapadelGameApiUnlockGameOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.unlockGameRaw(requestParameters, initOverrides);
    }

}
