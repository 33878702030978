import { FC, useCallback } from "react";
import { NewsCardProps } from "./NewsCard.interfaces";
import wavePart from "@/static/images/dogshit.svg";
import styles from "./NewsCard.module.css";
import { Link, useNavigate } from "react-router-dom";

const NewsCard: FC<NewsCardProps> = (props): JSX.Element => {
  const { news, color, title, index, ...rest } = props;
  const navigate = useNavigate();

  const clickHandler = useCallback(() => {
    sessionStorage.setItem('newsPlacement', `${index}`);
    navigate(`/news/${news.uid}`);
  }, [navigate, index, news.uid]);

  return (
    <div {...rest} className={`${styles['card']} ${styles[color]}`} onClick={clickHandler}>
      <img src={wavePart} alt="new" />
      <span className={styles['text']}>{title}</span>
    </div>
  );
}

export default NewsCard;
