const ListOutlinedIcon = () => {
  return (  
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.5" cy="4.5" r="2.5" fill="white"/>
      <circle cx="12.5" cy="12" r="2.5" fill="white"/>
      <circle cx="12.5" cy="19.5" r="2.5" fill="white"/>
    </svg>
  );
}
 
export default ListOutlinedIcon;
