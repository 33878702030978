import { SaveSubscriptionRequest } from '@/integration-api/server-rest-lundapadelApi';
import RestClient from '@/integration/RestClient';

export function createSubscription(parameters: SaveSubscriptionRequest['parameters']) {
  return RestClient.profileApi.saveSubscription({
    saveSubscriptionRequest: {
      parameters
    }
  })
}
