import styles from './EntityWaitingListModal.module.css';
import { GameInviteView, InvitationStatus, TournamentInviteView, TournamentMemberView } from '@/integration-api/server-rest-lundapadelApi';
import BasePageWrapper from '../BasePageWrapper/BasePageWrapper';
import Header from '../Header/Header';
import { useMemo, useState } from 'react';
import CountCircle from '../CountCircle/CountCircle';
import GameJoiningCard from '../GameJoiningCard/GameJoiningCard';
import { GameActions, HandleGameActionParams } from '@/pages/application/game/GameStatusContent/GameStatusContent.interfaces';
import Headling from '../Headling/Headling';
import PlayerItem from '../PlayerItem/PlayerItem';
import { ItemType } from '../PlayerItem/PlayerItem.interfaces';
import TournamentParticipants from '@/pages/application/tournament/components/TournamentParticipants/TournamentParticipants';
import { useAppSelector } from '@/hooks/hooks';
import EditListIcon from '@/static/images/icons/EditListIcon';

type EntityWaitingListModalProps = {
  visible: boolean;
  owner: boolean;
  onClose: VoidFunction;
  queue?: Array<TournamentMemberView>;
  invitations: Array<GameInviteView> | Array<TournamentInviteView>;
  entity: 'game' | 'tournament';
  handleInviteAction?: (action: Extract<GameActions, 'acceptInvite' | 'removeInvite'>, playerData?: HandleGameActionParams) => Promise<void>;
  deleteFromWaitingList?: (playerData: { displayName?: string, uid?: string }) => void;
  clearInviteFromTournament?: (playerData: { displayName?: string, uid?: string }) => void;
}

const EntityWaitingListModal = ({ visible, onClose, queue, entity, invitations, owner, handleInviteAction, deleteFromWaitingList, clearInviteFromTournament }: EntityWaitingListModalProps) => {
  const current = useAppSelector(state => state.auth.currentProfile);
  const [editMode, setEditMode] = useState(false);

  const renderQueue = useMemo(() => {
    if(entity === 'tournament') {
      const queuePlacement = queue?.findIndex(inv => inv.player?.uid === current.identity?.uid) ?? -1;
      let subHeadling: JSX.Element | null = null;
      if(queuePlacement > -1) {
        subHeadling = <div className={styles['subheadling']}>Перед вами игроков: {queuePlacement}</div>;
      }
      if(queuePlacement === 0) {
        subHeadling = <div className={styles['subheadling']}>Вы следующий, следите за уведомлениями!</div>;
      }
      return queue?.length ? (
        <>        
          <Headling appearence='medium-bold' className={styles['invited-headling']}>
            <span>Лист ожидания: {queue?.length ?? 0}</span>
            {owner ?            
              <span onClick={() => setEditMode(!editMode)}>
                <EditListIcon/>
              </span> : null
            }
          </Headling>
          {subHeadling}
          <TournamentParticipants
            participants={queue ?? []}
            queue
            editMode={editMode}
            setEditMode={state => setEditMode(state)}
            kickPlayer={player => deleteFromWaitingList?.({ uid: player?.uid, displayName: player?.displayName })}
            owner={owner}
          />
        </>
      ) : null;
    }
    return null;
  }, [queue, entity, owner, current.identity?.uid, editMode]);

  const renderRequests = useMemo(() => {
    if(entity === 'game' && owner && invitations.length) {
      const requests = invitations.filter(inv => (inv.sender?.uid === inv.player?.uid) && inv.invitationStatus === InvitationStatus.SENT);
      if(requests.length) {
        return (
          <div className={styles['requests-headling']}>
            <span className={styles['requests-text']}>Заявки:</span>
            <CountCircle>{requests.length}</CountCircle>
          </div>
        )
      }
    }
    return null; 
  }, [invitations, entity, owner]);

  const renderJoiningUsers = useMemo(() => {
    if(owner && invitations.length) {
      const suitableInvites = invitations?.filter(inv => inv.sender?.uid === inv.player?.uid && inv.invitationStatus === InvitationStatus.SENT);
      return suitableInvites?.length ? (
        <div className={styles['joining-players']}>
          {suitableInvites?.map(inv => (
            <GameJoiningCard
              key={inv.player?.uid}
              player={inv.player ?? {}} 
              acceptHandler={playerUid => handleInviteAction?.('acceptInvite', { playerUid })}
              declineHandler={playerUid => handleInviteAction?.('removeInvite', { playerUid })}
            />
          ))}
        </div>
      ) : null;
    } else {
      return null;
    }
  }, [owner, invitations, handleInviteAction]);

  const renderInvitedPlayers = useMemo(() => {
    if(invitations.length) {
      const invitedPlayers = invitations.filter(inv => (inv.player?.uid !== inv.sender?.uid) && inv.invitationStatus === InvitationStatus.SENT)
      if(invitedPlayers.length) {
        return (
          <>
            <Headling appearence='medium-bold' className={styles['invited-headling']}>
              <span>Приглашенные: {invitedPlayers.length}</span>
              {owner ? 
                <span onClick={() => setEditMode(!editMode)}>
                  <EditListIcon/>
                </span> : null
              }
            </Headling>
            <div className={styles['invited-players']}>
              {invitedPlayers.map((inv, i) => (
                <PlayerItem
                  key={inv.player?.uid}
                  player={inv.player ?? {}}
                  type={ItemType.WAITING_LIST}
                  withTopBorder={!!i}
                  editMode={editMode}
                  deleteHandler={playerData => entity === 'game' ? deleteFromWaitingList?.(playerData) : clearInviteFromTournament?.(playerData)}
                />
              ))}
            </div>
          </>
        )
      }
    }
    return null;
  }, [entity, invitations, editMode]);

  return visible ? (  
    <BasePageWrapper>
      <Header handleClick={onClose} className={styles['header']}>
        {entity === 'game' ? 'Ожидают подтверждения' : 'Лист ожидания'}
      </Header>
      <div className={styles['wrapper']}>
        {renderQueue}
        {renderRequests}
        {renderJoiningUsers}
        {renderInvitedPlayers}
      </div>
    </BasePageWrapper>
  ) : null;
}
 
export default EntityWaitingListModal;
