import { useParams } from 'react-router-dom';
import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import Header from '@/components/Header/Header';
import Headling from '@/components/Headling/Headling';
import styles from './NewsPage.module.css';
import { useCallback, useEffect, useState } from 'react';
import { NewsItemView, NewsMetricClickView } from '@/integration-api/server-rest-lundapadelApi';
import { newsItemLoad } from './service';
import PageBackground from '@/components/PageBackground/PageBackground';
import SanitizeHTML from '@/components/SanitizeHTML/SanitizeHTML';
import { cachedImage } from './utils/utils';
import DBManager from '@/utils/DBManager/DBManager';
import { getPlatform } from '@/utils/utils';

function NewsPage() {
  const { id } = useParams();

  const [news, setNews] = useState<NewsItemView>();
  const [image, setImage] = useState<string>();
  const [content, setContent] = useState<string>();

  const loadNews = useCallback(async (id: string) => {
    try {
      let number = undefined;
      const newsPlacement = sessionStorage.getItem('newsPlacement');
      if(newsPlacement) {
        number = Number(newsPlacement) > 1 ? NewsMetricClickView.OTHER : NewsMetricClickView.FIRST_TWO;
      }
      const { result: newsEntry } = await newsItemLoad(id || '', { system: getPlatform(), number });
      setImage(cachedImage(newsEntry?.uid, newsEntry?.imagesUids?.[0]));
      setContent(cachedImage(newsEntry?.uid, newsEntry?.imagesUids?.[1]));
      setNews(newsEntry);
      DBManager.create('news',newsEntry, id)
    } catch (err: unknown) {
      if (err instanceof Error) {
        console.log(err);
      }
    }
  }, []);

  useEffect(() => {
    if(navigator.onLine){
      if (id) {
        loadNews(id);
      } else {
        setNews(undefined);
        setImage('');
      }
    }else{
      DBManager.read('news', id as string).then(news => setNews(news)).catch((err) => console.log(err))
    }
    return () => sessionStorage.removeItem('newsPlacement');
  }, []);

  return (
    <>
      <BasePageWrapper showNavBar>
        {!!image && <PageBackground imgSrc={image} />}
        <Header>Новости</Header>
        <div className={styles['main-wrapper']}>
          <div className={styles['news-wrapper']}>
            <Headling appearence="medium-normal" className={styles['headling']}>
              {news?.title || '-'}
            </Headling>
            <article className={styles['article']}>
              <SanitizeHTML html={news?.content || ''} />
            </article>
          </div>
          {content ? <img src={content} alt='изображение новости' className={styles.contentImg}/> : null}
        </div>  
      </BasePageWrapper>
    </>
  );
}

export default NewsPage;
