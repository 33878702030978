import styles from '../GamePage.module.css';
import Header from "@/components/Header/Header";
import { useState } from "react";
import CompletedGameContent from '../GameStatusContent/CompletedGameContent';
import { CompletedGameSubPage } from '../GameStatusContent/GameStatusContent.interfaces';
import bgImage from '@/static/images/match-page-background.jpeg';
import PageBackground from '@/components/PageBackground/PageBackground';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { IGamePageContext } from '../GamePage.interfaces';
import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import ChatButton from '@/components/ChatButton/ChatButton';

const CompletedGamePage = () => {
  const { gameChats, goBackHandler } = useOutletContext<IGamePageContext>();
  const { id } = useParams();
  const navigate = useNavigate();
  
  const [subPage, setSubPage] = useState<CompletedGameSubPage>('game');
  const [activeLineup, setActiveLineup] = useState<string>();

  const handleBackBtnClick = () => {
    const lastPaths = sessionStorage.getItem('lastPaths'); 
    
    if (lastPaths && (JSON.parse(lastPaths) as Array<string>)[0].includes('chat')) {
      return () => { navigate('/my-games?tab=completed') }
    }
    return subPage === 'game' ? goBackHandler() : () => { setActiveLineup(undefined); setSubPage('game'); }
  }

  return (  
    <BasePageWrapper>
      <Header
        className={styles['header']}
        handleClick={handleBackBtnClick()}
        additional={
          (subPage === 'game' && id) ? 
            <div className={styles['header-buttons']}>
              <ChatButton
                enitity='game'
                chats={gameChats}
                id={id}
              />
            </div> : undefined
        }
      >
        {subPage === 'game' ? 'Результаты игры' : 'Детали матча'}
      </Header>
      {subPage === 'lineup' ? <PageBackground imgSrc={bgImage}/> : null}
      <CompletedGameContent 
        subPage={subPage} 
        activeLineup={activeLineup}
        setSubPage={setSubPage}
        setActiveLineup={setActiveLineup}  
      />
    </BasePageWrapper>
  );
}
 
export default CompletedGamePage;
