import {
  Select as BaseSelect,
  SelectListboxSlotProps,
  SelectProps,
  SelectRootSlotProps,
} from "@mui/base/Select";
import { Option as BaseOption } from "@mui/base/Option";
import { styled } from "@mui/system";
import { CssTransition } from "@mui/base/Transitions";
import { PopupContext } from "@mui/base/Unstable_Popup";
import { ForwardedRef, forwardRef, useContext } from "react";
import { DetailedPageOptionExpantionProps } from "./DetailedPageOptionExpantion.interfaces";
import ListOutlinedIcon from "@/static/images/icons/ListOutlinedIcon";

export default function DetailedPageOptionExpantion({ options }: DetailedPageOptionExpantionProps) {
  return (
    <Select>
      {options.map(opt => (
        <Option value={opt.value} key={opt.value}>{opt.label}</Option>
      ))}
    </Select>
  );
}

const Select = forwardRef(function CustomSelect<TValue extends {}, Multiple extends boolean>(
  props: SelectProps<TValue, Multiple>,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const slots = {
    root: Button,
    listbox: AnimatedListbox,
    popup: Popup,
    ...props.slots,
  };

  return <BaseSelect {...props} ref={ref} slots={slots} />;
});

const Button = forwardRef(function Button<
  TValue extends {},
  Multiple extends boolean
>(
  props: SelectRootSlotProps<TValue, Multiple>,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const { ownerState, ...other } = props;
  return (
    <button
      type="button"
      {...other}
      ref={ref}
      style={{ border: "none", background: "transparent", padding: 0 }}
    >
      <ListOutlinedIcon/>
    </button>
  );
});

const Listbox = styled("ul")(
  () => `
    box-sizing: border-box;
    padding: 0;
    margin: 12px 0;
    min-width: 250px;
    border-radius: 12px;
    overflow: auto;
    outline: 0;
    border: none;
    background: var(--white);
    border-bottom: 0.5px solid rgba(128, 128, 128, 0.55);
    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.2);
    
    .closed & {
      opacity: 0;
      transform: scale(0.95, 0.8);
      transition: opacity 200ms ease-in, transform 200ms ease-in;
    }
    
    .open & {
      opacity: 1;
      transform: scale(1, 1);
      transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
    }

    .placement-top & {
      transform-origin: bottom;
    }

    .placement-bottom & {
      transform-origin: top;
    }
  `
);

const AnimatedListbox = forwardRef(function AnimatedListbox<Value extends {},Multiple extends boolean>(
  props: SelectListboxSlotProps<Value, Multiple>,
  ref: ForwardedRef<HTMLUListElement>
) {
  const { ownerState, ...other } = props;
  const popupContext = useContext(PopupContext);

  if (popupContext == null) {
    throw new Error(
      "The `AnimatedListbox` component cannot be rendered outside a `Popup` component"
    );
  }

  const verticalPlacement = popupContext.placement.split("-")[0];

  return (
    <CssTransition
      className={`placement-${verticalPlacement}`}
      enterClassName="open"
      exitClassName="closed"
    >
      <Listbox {...other} ref={ref} />
    </CssTransition>
  );
});

const Option = styled(BaseOption)(
  () => `
    list-style: none;
    border-bottom: 0.5px solid rgba(128, 128, 128, 0.55);
    display: flex;
    justify-content: space-between;

    &:last-child {
      border-bottom: none;
    }
  `
);

const Popup = styled("div")`
  z-index: 1;
`;

