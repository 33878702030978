import Header from "@/components/Header/Header";
import { InvitationStatus, InviteInfo, InviteRelatedEntityType } from "@/integration-api/server-rest-lundapadelApi";
import { useCallback, useEffect, useState } from "react";
import BasePageWrapper from "@/components/BasePageWrapper/BasePageWrapper";
import styles from './InvitesPage.module.css';
import { useNavigate } from "react-router-dom";
import { FetchErrorMessage } from "@/types/types";
import { acceptInviteForGame, loadListInvite } from "../game/service";
import GameCardListInvite from "@/components/GameListCard/GameListCardInvite";
import TournamentInviteCard from "@/components/TournamentCard/TournamentInviteCard";
import { acceptInviteForTournament } from "../tournament/service";
import { InviteActionStatus } from "./InvitePage.interfaces";
import { PopupMessage } from "@/components/PopupMessage/PopupMessage";

const InvitesPage = (): JSX.Element => {
  const [inviteList, setInviteList] = useState<Array<InviteInfo>>([]);
  const navigate = useNavigate();

  const handleAction = useCallback(async (type: InviteRelatedEntityType, actionStatus: InviteActionStatus, invite?: InviteInfo) => {
    try {         
      if(type === InviteRelatedEntityType.GAME) {
        await acceptInviteForGame({
          gameUid: invite?.relatedEntityUid,
          playerUid: invite?.player?.uid, 
          accepted: actionStatus === InvitationStatus.ACCEPTED ? true : false
        });
        if (actionStatus === InvitationStatus.ACCEPTED) {
          navigate(`/game/${invite?.relatedEntityUid}`);
        } else if (actionStatus === InvitationStatus.REJECTED) {
          loadInvitesList();
        }
      } else {
        await acceptInviteForTournament({
          accepted: actionStatus === InvitationStatus.ACCEPTED ? true : false,
          tournamentUid: invite?.relatedEntityUid
        });
        if (actionStatus === InvitationStatus.ACCEPTED) {
          navigate(`/tournament/${invite?.relatedEntityUid}`);
        } else if (actionStatus === InvitationStatus.REJECTED) {
          loadInvitesList();
        }
      }       
    } catch(err: unknown) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open?.(userErrorMessage ?? errorMessage);
      }
    }
  }, []);

  const renderInvites = () => {
    return inviteList?.length ? inviteList?.map(invite => {
      if(invite.relatedEntityType === InviteRelatedEntityType.GAME) {
        return (
          <GameCardListInvite
            key={invite.relatedEntityUid}
            invite={invite}
            acceptInvite={() => handleAction(InviteRelatedEntityType.GAME, InvitationStatus.ACCEPTED, invite)}
            rejectInvite={() => handleAction(InviteRelatedEntityType.GAME, InvitationStatus.REJECTED, invite)}
          />
        );
      } else {
        return (
          <TournamentInviteCard
            key={invite.relatedEntityUid}
            invite={invite}
            acceptInvite={() => handleAction(InviteRelatedEntityType.TOURNAMENT, InvitationStatus.ACCEPTED, invite)}
            rejectInvite={() => handleAction(InviteRelatedEntityType.TOURNAMENT, InvitationStatus.REJECTED, invite)}
          />
        );
      }
    }) : null
  }

  function loadInvitesList() {
    loadListInvite({ limit: 2 })
      .then(({ result }) => {
        setInviteList(result?.infos ?? []);
      })
      .catch(err => {
        if(err instanceof Promise) {
          err.then(err => {
            const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
            PopupMessage.open?.(userErrorMessage ?? errorMessage);
          })
        }
      })
  }

  useEffect(() => {
    loadInvitesList()
  }, []);

  return (
    <BasePageWrapper>
      <main>
        <Header>Мои приглашения</Header>
        <section className={styles['invites-wrapper']}>
          {renderInvites()}
        </section>
      </main>
    </BasePageWrapper>
  );
}

export default InvitesPage;
