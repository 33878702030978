import ClockIcon from "@/static/images/icons/ClockIcon";
import { GameListCardInviteProps } from "./GameListCard.interfaces";
import styles from "./GameListCard.module.css";
import formatter from "@/utils/utils";
import { calculateEndTime } from "@/pages/application/create/components/MatchResultSettings/utils";
import { TIME_FORMAT_HH_MM } from "@/utils/constants";
import LockIcon from "@/static/images/icons/LockIcon";
import LocationIcon from "@/static/images/icons/LocationIcon";
import CommnentIcon from "@/static/images/icons/CommentIcon";
import { useNavigate } from "react-router-dom";
import Button, { ButtonVariants } from "../Button/Button";
import ProfileCard from "../ProfileCard/ProfileCard";
import GameCountBage from "../Badge/GameCountBage/GameCountBage";
import PlaceholderCard from "../PlaceholderCard/PlaceholderCard";
import CoachWhistleFilled from "@/static/images/icons/CoachWhistleFilled";
import RatingBage from "../Badge/RatingBage/RatingBage";
import { Role } from "@/integration-api/server-rest-lundapadelApi";

const GameCardListInvite = ({ invite, acceptInvite, rejectInvite, ...props }: GameListCardInviteProps) => {
  const navigate = useNavigate();

  const renderPlayers = () => {
    const playersArray: Array<JSX.Element> = [];
    for(let i = 0; i < 4; i++) {
      if(invite.players?.[i]) {
        if(i !== 3 && invite.playersLimit! - invite.players.length!) {
          playersArray.push((
            <ProfileCard
              key={i}
              player={invite.players[i]}
              fullName
              bage={[
                {
                  element: i === 3 && invite.playersLimit! - 4 > 0 ? <></> : <RatingBage rating={invite.players[i].displayRating ?? '1.0'}/>,
                  position: 'top-right'
                },
                {
                  element: invite.players[i].roles?.includes(Role.COACH) ? <CoachWhistleFilled /> : <></>,
                  position: 'top-left'
                },
                {
                  element: i === 3 && invite.playersLimit! - 4 > 0 ? <GameCountBage playersLimit={invite.playersLimit!}/> : <></>,
                  position: 'middle-center' 
                }
              ]}
            />
          ));
        } else {
          playersArray.push((
            <PlaceholderCard
              key={i}
              type='enter'
              bage={{
                element: i === 3 && invite.playersLimit! - 4 > 0 ? <GameCountBage playersLimit={invite.playersLimit!}/> : <></>,
                position: 'middle-center' 
              }}
            />
          ));
        }
      } else {
        playersArray.push((
          <PlaceholderCard
            key={i}
            type='enter'
            bage={{
              element: i === 3 && invite.playersLimit! - 4 > 0 ? <GameCountBage playersLimit={invite.playersLimit!}/> : <></>,
              position: 'middle-center' 
            }}
          />
        ))
      }
    }
    return playersArray;
  }

  return (  
    <div {...props} className={styles['card-wrapper-invite']}>
      <div className={styles['card']} onClick={() => navigate(`/game/${invite.relatedEntityUid}`)}>
        <div className={styles['invitation']}>Вас пригласили в игру!</div>
        <div className={styles['card-field']}>
          <div className={styles['icon']}>
            <ClockIcon/>
          </div>
          <span>
            {formatter.formatDateToDayDateMonth(invite.plannedDate)} | {formatter.formatDate(invite.plannedDate!, TIME_FORMAT_HH_MM)} 
            {calculateEndTime(formatter.formatDate(invite.plannedDate!, TIME_FORMAT_HH_MM), 0)}
          </span>
        </div>
        <div className={styles['rating-invite']}>
          {invite.privateEntity ?
            <div className={styles['rating-item']}>
              <LockIcon/>
            </div> : null
          }
          <div className={styles['rating-item']}>
            {invite.displayMinGrade === invite.displayMaxGrade ?
              <span>{invite.displayMinGrade}</span> :
              <span>{invite.displayMinGrade}...{invite.displayMaxGrade}</span>
            }
          </div>
        </div>
        <div className={styles['card-field']}>
          <div className={styles['icon']}>
            <LocationIcon />
          </div>
          <span>{invite.clubName}</span>
        </div>
        {invite.description ?
          <div className={styles['card-field']}>
            <div>
              <CommnentIcon />
            </div>
            <div className={styles['comment']}>{invite.description}</div>
          </div>
          : null
        }
        <div className={styles['players']}>
          {renderPlayers()}
        </div>
      </div>
      <div className={styles['invite-actions']}>
        <Button
          variant={ButtonVariants.PRIMARY}
          onClick={acceptInvite}
        >
          Принять
        </Button>
        <Button
          bordered
          variant={ButtonVariants.CANCEL}
          onClick={rejectInvite}
        >
          Отклонить
        </Button>
      </div>
    </div>
  );
}
 
export default GameCardListInvite;
