import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper';
import styles from './DeletableSubscriptionCard.module.css';
import TrashIcon from '@/static/images/icons/TrashIcon';
import { useState } from 'react';

interface DeletableSubscriptionCardProps extends React.PropsWithChildren {
  onDelete: () => void;
}

const DeletableSubscriptionCard = (props: DeletableSubscriptionCardProps) => {
  const { children, onDelete } = props;
  
  const [swiper, setSwiper] = useState<SwiperType | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div>
      <Swiper
        className={`${styles['swiper']} ${activeIndex === 1 ? styles['trash-visible'] : ''}`}
        slidesPerView={2}
        onSlideChange={(swiper: SwiperType) => setActiveIndex(swiper.activeIndex)}
        onSwiper={(swiper: SwiperType) => setSwiper(swiper)}
        freeMode={true}
      >
        <SwiperSlide className={styles['slide']}>
          {children}
        </SwiperSlide>
        <SwiperSlide className={styles['trash']} onClick={onDelete}>
          <TrashIcon/>
        </SwiperSlide>
        <SwiperSlide/>
      </Swiper>
    </div>
  );
}
 
export default DeletableSubscriptionCard;
