import React from 'react';
import styles from './Button.module.css';

export enum ButtonVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  CANCEL = 'cancel',
  HALF_OPACITY = 'half-opacity',
  UNIQUE = 'unique',
  DISABLED = 'disabled',
  QUEUE = 'queue',
  BLUE = 'blue',
  YELLOW = 'yellow',
  YELLOW_OUTLINED = 'yellow-outline',
  CYAN_OUTLINED = 'cyan-outline',
  GOLD = 'gold',
  PRIMARY_OUTLINED = 'primary-outlined'
}

interface ButtonInterface extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  bordered?: boolean,
  isBgEmpty?:boolean,
  variant?: ButtonVariants;
}

function Button({children, className, bordered, isBgEmpty, variant = ButtonVariants.PRIMARY, ...buttonProps }: ButtonInterface) {

  const btnClassName = [
    styles.button,
    bordered && styles.bordered,
    isBgEmpty && styles.buttonEmpty,
    variant && styles[variant],
    className
  ].filter(e => e).join(' ');

  return (
    <button className={btnClassName} {...buttonProps}>
      {children}
    </button>
  );
}

export default Button;
