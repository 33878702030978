import React from 'react';
import Icon, { IconProps } from '../Icon';

interface CalendarProps extends IconProps {}

const Calendar: React.FC<CalendarProps> = (props) => {
  return (
    <Icon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 11.25H13.8889V15H10V11.25ZM15.4444 4.5H14.6667V3H13.1111V4.5H6.88889V3H5.33333V4.5H4.55556C3.7 4.5 3 5.175 3 6V16.5C3 17.325 3.7 18 4.55556 18H15.4444C16.3 18 17 17.325 17 16.5V6C17 5.175 16.3 4.5 15.4444 4.5ZM15.4444 6V7.5H4.55556V6H15.4444ZM4.55556 16.5V9H15.4444V16.5H4.55556Z"
          fill="#fff"
        />
      </svg>
    </Icon>
  );
};

export default Calendar;
