/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseMetricView,
    BaseMetricViewFromJSON,
    BaseMetricViewFromJSONTyped,
    BaseMetricViewToJSON,
} from './BaseMetricView';
import {
    DeviceSystem,
    DeviceSystemFromJSON,
    DeviceSystemFromJSONTyped,
    DeviceSystemToJSON,
} from './DeviceSystem';
import {
    NewsMetricClickView,
    NewsMetricClickViewFromJSON,
    NewsMetricClickViewFromJSONTyped,
    NewsMetricClickViewToJSON,
} from './NewsMetricClickView';
import {
    NotificationMetricViewAllOf,
    NotificationMetricViewAllOfFromJSON,
    NotificationMetricViewAllOfFromJSONTyped,
    NotificationMetricViewAllOfToJSON,
} from './NotificationMetricViewAllOf';

/**
 * 
 * @export
 * @interface NotificationMetricView
 */
export interface NotificationMetricView {
    /**
     * 
     * @type {DeviceSystem}
     * @memberof NotificationMetricView
     */
    system?: DeviceSystem;
    /**
     * 
     * @type {NewsMetricClickView}
     * @memberof NotificationMetricView
     */
    number?: NewsMetricClickView;
}

export function NotificationMetricViewFromJSON(json: any): NotificationMetricView {
    return NotificationMetricViewFromJSONTyped(json, false);
}

export function NotificationMetricViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationMetricView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'system': !exists(json, 'system') ? undefined : DeviceSystemFromJSON(json['system']),
        'number': !exists(json, 'number') ? undefined : NewsMetricClickViewFromJSON(json['number']),
    };
}

export function NotificationMetricViewToJSON(value?: NotificationMetricView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'system': DeviceSystemToJSON(value.system),
        'number': NewsMetricClickViewToJSON(value.number),
    };
}

