import { useCallback } from 'react';
import styles from './AdsBanner.module.css';
import RestClient from '@/integration/RestClient';
import { AdvertisementMetricClickView, MetricScreenView } from '@/integration-api/server-rest-lundapadelApi';
import { getPlatform } from '@/utils/utils';

interface AdsBannerProps {
  bannerSrc: string;
  redirectLink?: string;
  index?: number;
  type: 'play' | 'home';
}

const AdsBanner: React.FC<AdsBannerProps> = (props) => {
  const { bannerSrc, redirectLink, index, type } = props;

  const clickHandler = useCallback(() => {
    RestClient.metricApi.advertisementMetric({
      advertisementMetricRequest: {
        parameters: {
          click: index !== undefined ? index === 0 ? AdvertisementMetricClickView.FIRST : AdvertisementMetricClickView.OTHER : undefined,
          screen: type === 'play' ? MetricScreenView.PLAY_SCREEN : MetricScreenView.MAIN_SCREEN,
          system: getPlatform()
        }
      }
    })
    .catch(err => console.log(err));
  }, [index, type]);

  return (
    <a href={redirectLink} className={styles.wrap} target='_blank' onClick={clickHandler}>
      <img className={styles.banner} src={bannerSrc} alt="ads" />
    </a>
  );
}
 
export default AdsBanner;
