import { PlayerProfileInfo } from '@/integration-api/server-rest-lundapadelApi';
import styles from './GameJoiningMultipleList.module.css';
import UserAvatar from '../UserAvatar/UserAvatar';
import { createImgSrc } from '@/utils/utils';
import { useNavigate } from 'react-router-dom';

type GameJoiningMultipleListProps = {
  pool: Array<PlayerProfileInfo>;
  id?: string;
}

const GameJoiningMultipleList = ({ pool, id }: GameJoiningMultipleListProps) => {
  const navigate = useNavigate();

  return (  
    <div className={styles['wrapper']} onClick={() => navigate(`/game/${id}`)}>
      <div className={styles['players']}>
        {pool.map(player => (
          <div               
            className={styles['player']}
            key={player.uid}
          >
            <UserAvatar
              className={styles[player.avatarUid ? 'avatar' : 'noavatar']}
              avatarUid={createImgSrc.playerProfile(player.uid, player.avatarUid)}
              nameInitials={player.nameInitials}
            />
          </div>
        ))}
      </div>
      <span className={styles['text']}>Хотят присоединиться к вашей игре!</span>
    </div>
  );
}
 
export default GameJoiningMultipleList;
