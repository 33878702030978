import { LocalDate } from "@/integration-api/server-rest-lundapadelApi";
import dayjs from "dayjs";
import { daysEnum } from "./components/DaysModal/utils";

type CreateValidDaysArgs = {
  startDateState?: LocalDate;
  endDateState?: LocalDate;
}

export function createValidDays({ startDateState, endDateState }: CreateValidDaysArgs) {
  if(startDateState && endDateState) {
    let startDate = dayjs(startDateState);
    const endDate = dayjs(endDateState);
    const days: Set<number> = new Set();
    while((startDate.format('YYYY-MM-DD') <= endDate.format('YYYY-MM-DD') && days.size < 7)) {
      days.add(startDate.day());
      startDate = startDate.add(1, 'day');
    }
    return Array.from(days).map(day => {
      day === 0 ? day = 6 : day--;
      return daysEnum[day];
    });
  } else {
    return daysEnum;
  }
}
