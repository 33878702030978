/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubscriptionView,
    SubscriptionViewFromJSON,
    SubscriptionViewFromJSONTyped,
    SubscriptionViewToJSON,
} from './SubscriptionView';

/**
 * 
 * @export
 * @interface SaveSubscriptionResponse
 */
export interface SaveSubscriptionResponse {
    /**
     * 
     * @type {SubscriptionView}
     * @memberof SaveSubscriptionResponse
     */
    result?: SubscriptionView;
    /**
     * 
     * @type {Array<object>}
     * @memberof SaveSubscriptionResponse
     */
    messages?: Array<object>;
}

export function SaveSubscriptionResponseFromJSON(json: any): SaveSubscriptionResponse {
    return SaveSubscriptionResponseFromJSONTyped(json, false);
}

export function SaveSubscriptionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaveSubscriptionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : SubscriptionViewFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function SaveSubscriptionResponseToJSON(value?: SaveSubscriptionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': SubscriptionViewToJSON(value.result),
        'messages': value.messages,
    };
}

