import React from 'react';
import style from './Icon.module.css';

export interface IconProps extends React.PropsWithChildren, React.AllHTMLAttributes<HTMLSpanElement> {
  iconSize?: 'small' | 'middle' | 'large' | number;
}

const Icon: React.FC<IconProps> = (props) => {
  const { children, iconSize, className, ...spanAttributes } = props;
  
  // По-умолчанию размер sm
  let iconSizeClassName = style.sizeSm;
  if (iconSize === 'middle') {
    iconSizeClassName = style.sizeMd;
  } else if (iconSize  === 'large') {
    iconSizeClassName = style.sizeLg;
  }

  return (
    <span className={[className, style['icon'], iconSizeClassName].join(' ')} {...spanAttributes}>
      {children}
    </span>
  );
}

export default Icon;
