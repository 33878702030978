import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { acceptGameInviteByQr } from "../service";
import BasePageWrapper from "@/components/BasePageWrapper/BasePageWrapper";
import { getPlatform } from "@/utils/utils";

const GameJoinViaQrCode = () => {
  const { id } = useParams();
  const navigate = useNavigate();
 
  useEffect(() => {
    if(id) {
      acceptGameInviteByQr(id, getPlatform())
        .then(() => {
          navigate(`/game/${id}`, { replace: true });
        })
        .catch(() => {
          navigate('/', { replace: true });
        });
    }
  }, [id]);

  return (  
    <BasePageWrapper/>
  );
}
 
export default GameJoinViaQrCode;
