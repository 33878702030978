import styles from './GameLineupsPage.module.css';
import Header from "@/components/Header/Header";
import PlayersPool from "../components/PlayersPool/PlayersPool";
import Button, { ButtonVariants } from '@/components/Button/Button';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import CrossIcon from '@/static/images/icons/CrossIcon';
import { useCallback, useContext, useEffect, useState } from 'react';
import { GameLineupsContext, GameResultsContext, areLineupsFull } from '../GameResults/utils';
import DraggablePlayersPool from '../components/PlayersPool/DraggablePlayersPool';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import LineupEditable from '../components/LineupLIst/LineupListEditable';
import LineupListEditableItemPreview from '../components/LineupListEditableItem/LineupListEditablePreview';
import withScrolling from 'react-dnd-scrolling';
import { createGameLineup, deleteGameLineup, gameResultReady, saveGameLineup } from '../service';
import { FetchErrorMessage } from '@/types/types';
import { useNavigate, useParams } from 'react-router-dom';
import Lineups from '../components/LineupLIst/LineupList';
import { CalculatedGameStatus, GameLineupView } from '@/integration-api/server-rest-lundapadelApi';
import { EdidatbleLineupsActions, EdidatbleLineupsParams } from '../GameResults/GameResultsPage.interfaces';
import formatter, { getPlatform } from '@/utils/utils';
import { TIME_FORMAT_HH_MM } from '@/utils/constants';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

const ScrollingComponent = withScrolling('div');

const GameLineupsPage = () => {
  const { id } = useParams();
  const { playersPool, lineups, plannedDate, lockToken, updateGame, game } = useContext(GameResultsContext);
  const navigate = useNavigate();

  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editableLineups, setEditableLineups] = useState<Array<GameLineupView>>([]);

  const handleEditableLineups = useCallback((action: EdidatbleLineupsActions, params?: EdidatbleLineupsParams) => {
    switch(action) {
      case 'edit': {
        const newEditableLineups: Array<GameLineupView> = [...editableLineups.slice(0, params?.index!), { ...editableLineups[params?.index!], ...params?.players }, ...editableLineups.slice(params?.index! + 1)]
        setEditableLineups(newEditableLineups);
        break;
      }
      case 'add': {
        setEditableLineups(editableLineups.concat([{}]));
        break;
      }
      case 'delete': {
        const newEditableLineups: Array<GameLineupView> = editableLineups.filter((_, index) => index !== params?.index);
        setEditableLineups(newEditableLineups);
        break;
      }
      default: {
        const _: never = action;
        throw new Error('not every case is handled');
      }
    }
  }, [editableLineups]);

  const handleGoBack = () => {
    if(editMode) {
      if(editableLineups.length === 1 && !areLineupsFull(editableLineups)) {
        setEditableLineups([]);
      }
      setEditMode(false);
    } else {
      navigate(-1);
    }
  }

  const saveLineups = async () => {
    try {
      if(!areLineupsFull(editableLineups)) {
        PopupMessage.open?.('Заполните или удалите пустые составы');
        return;
      }
      setLoading(true);
      const requests: Array<Promise<any>> = [];
      lineups.forEach((lineup, i) => {
        const lineupForDeletion = editableLineups.find(l => l.lineupUid === lineup.lineupUid);
        if(!lineupForDeletion) {
          requests.push(deleteGameLineup({ gameUid: id, lineupUid: lineup.lineupUid, lockToken, metrics: { system: getPlatform() } }));
        }
      })
      editableLineups.forEach((lineup, i) => {
        if(lineup?.lineupUid) {
          requests.push(saveGameLineup({ ...lineup, gameUid: id, lockToken, metrics: { system: getPlatform() } }));
        } else {
          requests.push(createGameLineup({ ...lineup, gameUid: id, lockToken, metrics: { system: getPlatform() } }));
        }
      });
      await Promise.all(requests);
      await updateGame?.();
      setEditMode(false);
      setLoading(false);
    } catch(err) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open?.(userErrorMessage ?? errorMessage);
      }
    } finally {
      setLoading(false)
    }
  }

  const handleSubmitResults = async () => {
    try {
      setLoading(true);
      await gameResultReady({ 
        gameUid: id, 
        lockToken, 
        metrics: { system: getPlatform() } 
      });
      navigate(-1);
    } catch(err) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open?.(userErrorMessage ?? errorMessage);
      }
    } finally {
      setLoading(false);
    }
  }

  const renderButton = () => {
    if(editMode) {
      return (
        <Button 
          variant={areLineupsFull(editableLineups) ? ButtonVariants.YELLOW : ButtonVariants.DISABLED} 
          className={styles['button']}
          onClick={saveLineups}
          disabled={loading || !lockToken}
        >
          Сохранить составы
        </Button>
      );
    } else {
      if(!lineups.length) {
        return (
          <Button 
            variant={ButtonVariants.YELLOW} 
            className={styles['button']}
            onClick={() => {
              setEditableLineups([{}]);
              setEditMode(true);
            }}
            disabled={!lockToken}
          >
            <CrossIcon/>
            Добавить состав
          </Button>
        );
      } else {
        return (
          <Button
            variant={ButtonVariants.PRIMARY}
            onClick={handleSubmitResults}
            disabled={!lockToken || (editableLineups.every(lineup => !lineup.gameSets?.length && !lineup.matches?.length))}
          >
            {game.status === CalculatedGameStatus.AFTER_GAME ? 'Сохранить игру' : 'Завершить игру'}
          </Button>
        )
      }
    }
  }

  useEffect(() => {
    if(editMode && !lineups.length) {
      setEditableLineups([{}]);
    }  else {
      setEditableLineups(lineups.slice());
    }
  }, [lineups, editMode]);

  return (
    <>
      <Header
        className={styles['header']}
        handleClick={handleGoBack}
      >
        {editMode ? 'Составы' : `Игра ${formatter.dateWithDots(plannedDate)} ${formatter.formatDate(plannedDate!, TIME_FORMAT_HH_MM)}`} 
        </Header>
      <DndProvider backend={TouchBackend}>
        <ScrollingComponent className={styles['wrapper']}>
          {editMode ?
            <>
              <GameLineupsContext.Provider value={{ editableLineups, handleEditableLineups }}>
                <DraggablePlayersPool playersPool={playersPool}/>
                  {editableLineups?.map((lineup, index) => (
                    <LineupEditable key={index} lineup={lineup} index={index}/>
                  ))}
              </GameLineupsContext.Provider>
              <div className={styles['add-lineup']} onClick={() => lockToken ? handleEditableLineups('add') : undefined}>
                <CrossIcon fill='var(--green-yellow)'/>
                <span>Добавить состав</span> 
              </div>
            </> :
            <>
              <PlayersPool playersPool={playersPool} />
              {playersPool.length === 4 && editableLineups.length === 3 ? 
                <span className={styles['info']}>
                  Вам предложены всевозможные составы команд. Составы без выставленного счета на результат игры влиять не будут.
                </span> : null
              }
              {editableLineups.length ?
                <>
                  {playersPool.length > 4 || (editableLineups.length !== 3 && playersPool.length === 4) ?                    
                    <Button
                      variant={ButtonVariants.CYAN_OUTLINED}
                      onClick={() => setEditMode(true)}
                      className={styles['add-lineup-middle']}
                      disabled={!lockToken}
                    >
                      Добавить/удалить составы
                    </Button> : null
                  }
                  <Lineups />
                </> :
                <div className={styles['placeholder-text']}>Здесь будут отображаться собранные составы игроков и их результаты игр</div>
              }
            </>
          }
          <LineupListEditableItemPreview/>
        </ScrollingComponent>
      </DndProvider>
      <OversideWrapper className={styles['button-wrapper']}>
        {renderButton()}
      </OversideWrapper>
    </>
  );
}
 
export default GameLineupsPage;
