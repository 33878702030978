import BasePageWrapper from "@/components/BasePageWrapper/BasePageWrapper";
import Header from "@/components/Header/Header";
import { useOutletContext } from "react-router-dom";
import { IGamePageContext } from "../GamePage.interfaces";
import { PuffLoader } from "react-spinners";

const GamePlaceholderPage = () => {
  const { goBackHandler } = useOutletContext<IGamePageContext>();

  return (  
    <BasePageWrapper showNavBar>
      <Header handleClick={goBackHandler}>
        Игра
      </Header>
      <PuffLoader color='#4EB857' cssOverride={{ margin: 'auto' }} />
    </BasePageWrapper>
  );
}
 
export default GamePlaceholderPage;
