import { PlayerProfileInfo, Role } from '@/integration-api/server-rest-lundapadelApi';
import styles from './GamePlayerCarousel.module.css';
import Headling from '@/components/Headling/Headling';
import InnerPadding from '@/components/InnerPadding/InnerPadding';
import ProfileCard from '@/components/ProfileCard/ProfileCard';
import RatingBage from '@/components/Badge/RatingBage/RatingBage';
import CoachWhistleFilled from '@/static/images/icons/CoachWhistleFilled';
import { useAppSelector } from '@/hooks/hooks';
import { useNavigate } from 'react-router-dom';
import { Fragment, useState } from 'react';
import ArrowIcon from '@/static/images/icons/ArrowIcon';
import PlaceholderCard from '@/components/PlaceholderCard/PlaceholderCard';
import TrashBage from '@/components/Badge/TrashBage/TrashBage';
import PlusBage from '@/components/Badge/PlusBage/PlusBage';
import RacketBage from '@/components/Badge/RacketBage/RacketBage';
import EditListIcon from '@/static/images/icons/EditListIcon';

type GamePlayerCarouselProps = {
  players: Array<PlayerProfileInfo & { inviteType?: 'onwer' | 'player'}>;
  playersLimit?: number;
  type: 'participants' | 'invintations' | 'results';
  collapsable?: boolean;
  editMode?: boolean;
  setEditMode?: (state: boolean) => void; 
  playerHandler?: (uid?: string, displayName?: string) => void;
  openInvitesPage?: () => void;
  owner?: boolean;
  ownerUid?: string;
  canInvite?: boolean;
  createForm?: boolean;
}

type ProfileClickHandlerArgs = {
  uid?: string;
  displayName?: string;
  active?: boolean
};

const GamePlayerCarousel = ({ players, playersLimit, type, collapsable, editMode, owner, ownerUid, canInvite, createForm, setEditMode, playerHandler, openInvitesPage }: GamePlayerCarouselProps) => {
  const current = useAppSelector(state => state.auth.currentProfile);
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);

  const profileClickHandler = ({ uid, displayName, active }: ProfileClickHandlerArgs) => {
    if(createForm || type === 'results') {
      return;
    }
    if(editMode) {
      if(uid && uid !== ownerUid) {
        playerHandler?.(uid, displayName);
      }
    } else {
      if(uid) {
        if(current?.identity?.uid !== uid && active) {
          navigate(`/profile/${uid}`);
        }
      } else {
        if(canInvite) {
          openInvitesPage?.();
        }
      }
    }
  }

  return (  
    <div className={`${styles['wrapper']} ${type === 'results' ? styles['results'] : ''}`.trim()}>
      {type !== 'results' ?        
        <InnerPadding>
          <Headling appearence='medium-bold'>
            <div className={styles['headling-content']}>
              <div className={styles['headling-text']}>
                  {type === 'participants' ?
                    <span>Игроки ({playersLimit ?? 0})</span> :
                    createForm ? 
                    <span>Приглашенные игроки</span> :
                    <span>Ожидают подтверждения</span>
                  }
                {collapsable ?
                  <div className={styles[open ? 'icon-opened' : 'icon-closed']} onClick={() => setOpen(!open)}>
                    <ArrowIcon/>
                  </div> : null
                }
              </div>
              {!editMode && owner ?
                <span className={styles['headling-action']} onClick={() => setEditMode?.(true)}>
                  <EditListIcon/>
                </span> : null
              }
            </div>
          </Headling>
        </InnerPadding> : null
      }
      {open ?
        <div className={styles['carousel']}>
          {players?.map?.((player, index) => player?.uid ? (
            <div key={player?.uid + String(index)}>
              <ProfileCard
                player={player}
                onClick={() => profileClickHandler({ uid: player?.uid, displayName: player?.displayName, active: player.active })}
                fullName
                bage={[
                  {
                    element: editMode && ownerUid !== player.uid ? <TrashBage/> : <RatingBage rating={player?.displayRating ?? '1.0'}/>,
                    position: 'top-right'
                  },
                  {
                    element: player?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
                    position: 'top-left'
                  },
                  {
                    element: (type === 'invintations' && player?.inviteType) ? player.inviteType === 'onwer' ? <PlusBage/> : <RacketBage/> : <></>,
                    position: 'middle-center' 
                  }
                ]}
              />
            </div>
          ) : (
            <Fragment key={player?.uid + String(index)}>
              {canInvite  ?              
                <PlaceholderCard
                  key={index}
                  type='invite'
                  onClick={() => profileClickHandler({})}
                /> : 
                <PlaceholderCard
                  key={index}
                  type='enter'
                />
              }            
            </Fragment>
          ))}
        </div> : null
      }
    </div>
  );
}
 
export default GamePlayerCarousel;
